import { useState, useEffect } from "react";
import { CirclePicker } from "react-color";
import { useAppDispatch } from "../../app/store";
import {
  NotificationDetails,
  UpdateXNewsAlertParams,
  XHandle,
  XNewsAlert,
} from "../../types/alertTypes";
import {
  addXNewsAlert,
  patchExistingXNewsAlert,
} from "../../features/alerts/alertsSlice";
import { CreateXNewsAlertParams } from "../../types/alertTypes";
import NotificationDetailsEditor, {
  defaultNotificationDetails,
} from "./components/NotificationDetails";
import XHandlePicker from "./components/HandlePicker";
import KeywordSetPicker from "./components/KeywordPicker";
import Tooltip from "./components/FormElements";
import { FaInfoCircle } from "react-icons/fa";
import { convertUTCTimeToLocal } from "../../utils/timeUtils";
import AnimatedModalContainer from "./components/AnimatedModalContainer";
import ModalTitle from "./components/ModalTitle";

const XModal = ({
  isOpen,
  onClose,
  alert,
}: {
  isOpen: any;
  onClose: any;
  alert?: XNewsAlert | null;
}) => {
  const dispatch = useAppDispatch();
  const [selectedKeywordSets, setSelectedKeywordSets] = useState<string[][]>(
    []
  );
  const [liveFeed, setLiveFeed] = useState(true);
  const [sendingTime, setSendingTime] = useState("");
  const [selectedHandles, setSelectedHandles] = useState<string[]>([]);
  const [notificationDetails, setNotificationDetails] =
    useState<NotificationDetails>(defaultNotificationDetails);
  const [color, setColor] = useState("#FFFFFF");

  // Converts a local time string to a UTC time string
  function convertLocalTimeToUTC(localTimeStr: string) {
    if (!localTimeStr) return "";

    const now = new Date();
    const today = now.toISOString().split("T")[0]; // Get current date as YYYY-MM-DD
    const fullLocalTimeStr = `${today}T${localTimeStr}`; // Create a full local datetime string

    const localDate = new Date(fullLocalTimeStr);
    const utcHours = localDate.getUTCHours();
    const utcMinutes = localDate.getUTCMinutes();

    const utcHoursPadded = utcHours.toString().padStart(2, "0");
    const utcMinutesPadded = utcMinutes.toString().padStart(2, "0");

    return `${utcHoursPadded}:${utcMinutesPadded}`;
  }

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  // Set defaults when editing
  useEffect(() => {
    if (alert) {
      console.error(alert);
      // Populate the form fields with the existing listing alert data
      if ("x_handles" in alert) {
        setSelectedHandles(
          alert.x_handles.map((handle: XHandle) => handle.handle)
        );
      }
      if ("keyword" in alert && alert.keyword)
        setSelectedKeywordSets(
          alert.keyword
            .split("; ")
            .map((set) =>
              set.split(", ").map((keyword) => keyword.replace(/^"|"$/g, ""))
            )
        );
      setLiveFeed(alert.live_feed);
      console.warn(alert.sending_time);
      if ("sending_time" in alert && alert.sending_time)
        setSendingTime(convertUTCTimeToLocal(alert.sending_time));
      setNotificationDetails(alert.notification_details);
      setColor(alert.color);
    } else {
      // Clear all fields
      setSelectedHandles([]);
      setSelectedKeywordSets([]);
      setLiveFeed(true);
      setSendingTime("");
      setNotificationDetails(defaultNotificationDetails);
      setColor("");
    }
  }, [alert]);

  const handleCreateAlert = () => {
    const handles = selectedHandles.map((value: any) => ({
      handle: value,
    }));

    const alertData: CreateXNewsAlertParams = {
      keyword: selectedKeywordSets
        .map((set) =>
          set
            .map((keyword) => (/\s/.test(keyword) ? `"${keyword}"` : keyword))
            .join(", ")
        )
        .join("; "),
      x_handles: handles,
      notification_details: notificationDetails,
      live_feed: liveFeed,
      sending_time: liveFeed ? null : convertLocalTimeToUTC(sendingTime),
      active: true,
      color: color,
    };

    if (alert)
      dispatch(
        patchExistingXNewsAlert({
          id: alert.id,
          payload: alertData as UpdateXNewsAlertParams,
        })
      );
    else dispatch(addXNewsAlert(alertData as CreateXNewsAlertParams));
    onClose();
  };

  const colors = [
    "#C65C5C",
    "#D1A355",
    "#7FBF7F",
    "#1A9B97",
    "#5A8E8E",
    "#1A76D1",
    "#DB7093",
    "#D93C00",
    "#8465B9",
    "#2E9E71",
    "#D4AF37",
    "#A75373",
    "#3E7CA6",
    "#B86123",
    "#763FB2",
    "#355CA8",
    "#A11A1A",
    "#8B1D1D",
  ];

  if (!isOpen) return null;

  return (
    <AnimatedModalContainer>
      <ModalTitle alert={alert} title="X News Alert" />
      <form className="space-y-4">
        <XHandlePicker
          selectedHandles={selectedHandles}
          setSelectedHandles={setSelectedHandles}
        />
        <KeywordSetPicker
          selectedKeywordSets={selectedKeywordSets}
          setSelectedKeywordSets={setSelectedKeywordSets}
        />
        <div className="flex items-center py-2">
          <span
            className={`mr-3 ${liveFeed ? "text-gray-600" : "text-gray-200"}`}
          >
            AI Summary
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <div className="relative">
              <input
                type="checkbox"
                id="anotherNotification"
                className="sr-only"
                checked={liveFeed}
                onChange={(e) => {
                  setLiveFeed(e.target.checked);
                  if (e.target.checked) setSendingTime("17:00");
                }}
              />
              <div
                className={`w-14 h-8 rounded-full flex items-center justify-center transition-transform duration-300 ease-in-out ${
                  liveFeed
                    ? "bg-gradient-to-r from-blue-600 via-pink-500 to-purple-400"
                    : "bg-gradient-to-r from-purple-400 via-pink-500 to-blue-600"
                }`}
              >
                <div
                  className={`bg-white w-6 h-6 mr-5 rounded-full transition-transform duration-300 ease-in-out ${
                    liveFeed ? "translate-x-6" : "translate-x-0"
                  }`}
                ></div>
              </div>
            </div>
            <div className="relative flex items-center gap-2">
              {" "}
              <span
                className={`ml-3 ${
                  liveFeed ? "text-gray-200" : "text-gray-600"
                }`}
              >
                Live Feed
              </span>
              <Tooltip
                message={`Live mode: \nGet notifications as soon as system finds data.\n\nAI Summary:\nGet a ChatGPT generated summary of all found information at set time.`}
              >
                <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
              </Tooltip>
            </div>
          </label>
        </div>
        {!liveFeed && (
          <label className="block">
            <span className="text-gray-400">Set Summary Time</span>
            <input
              type="time"
              value={sendingTime}
              onChange={(e) => setSendingTime(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </label>
        )}
        <label className="block">
          <div className="relative flex items-center gap-2">
            <span className="text-gray-400 mb-1">Alert Color</span>
            <Tooltip
              message={`Choose a fitting color to easier recognise your listing alert`}
            >
              <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
            </Tooltip>
          </div>
          <CirclePicker
            color={color}
            onChangeComplete={(color: any) => setColor(color.hex)}
            colors={colors}
          />
        </label>
        <label className="block">
          <div className="relative flex items-center gap-2">
            <span className="text-gray-400">Notification Details</span>{" "}
            <Tooltip
              message={`Channels where you want to recieve notifications.`}
            >
              <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
            </Tooltip>
          </div>
          <p></p>
          <a href="/settings" className="text-gray-500 text-sm underline">
            (you can set default values in settings)
          </a>
          <NotificationDetailsEditor
            notificationDetails={notificationDetails}
            setNotificationDetails={setNotificationDetails}
          />
        </label>
        <div className="flex items-center justify-between mt-4">
          <button
            type="button"
            onClick={onClose}
            className="inline-flex items-center px-4 py-2 bg-gray-700 border border-transparent rounded-md font-semibold text-xs text-gray-300 uppercase tracking-widest hover:bg-gray-600 active:bg-gray-800 focus:outline-none focus:border-gray-400 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleCreateAlert}
            className="inline-flex items-center px-4 py-2 bg-blue-500 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-blue-600 active:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring ring-blue-300 disabled:opacity-25 transition ease-in-out duration-150"
          >
            {alert ? "Update Alert" : "Create Alert"}
          </button>
        </div>
      </form>
    </AnimatedModalContainer>
  );
};

export default XModal;
