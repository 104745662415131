import React, { useState, useEffect } from "react";
import {
  RiCalendarEventFill,
  RiCloudFill,
  RiDeleteBin6Line,
  RiFootballFill,
  RiInformationLine,
  RiListUnordered,
  RiNewspaperLine,
  RiPriceTag3Line,
  RiTwitterXFill,
} from "react-icons/ri";
import {
  GeneralAlert,
  Source,
  NotificationDetails,
  XHandle,
  UpdateWeatherAlertParams,
  AlertType,
} from "../types/alertTypes";
import {
  FaGlobe,
  FaBell,
  FaEnvelope,
  FaSms,
  FaWhatsapp,
  FaTelegram,
  FaSlack,
  FaFacebookMessenger,
  FaSignal,
  FaViber,
  FaWeixin,
  FaSkype,
  FaLine,
  FaLinkedin,
  FaTwitter,
  FaInstagram,
  FaDiscord,
  FaMicrosoft,
} from "react-icons/fa";
import { useAppDispatch } from "../app/store";
import { motion } from "framer-motion";
import { BiDetail, BiEdit, BiPowerOff } from "react-icons/bi";
import {
  fetchAllAlerts,
  removeCryptoPriceChangeAlert,
  removeNewsAlert,
  removeNewListingAlert,
  removeWeatherAlert,
  patchExistingCryptoPriceChangeAlert,
  patchExistingNewsAlert,
  patchExistingNewListingAlert,
  patchExistingWeatherAlert,
  patchExistingSportsNewsAlert,
  removeSportsNewsAlert,
  patchExistingXNewsAlert,
  removeXNewsAlert,
  patchExistingEventAlert,
  removeEventAlert,
} from "../features/alerts/alertsSlice";
import LatestData from "./alert/LatestData";
import { convertUTCTimeToLocal } from "../utils/timeUtils";

type OptionType =
  | "News by Keyword"
  | "News by Source"
  | "Price Alerts"
  | "New Listings"
  | "Weather"
  | "Sport"
  | "X News"
  | "Event";

// Alert item component with detailed view
const AlertItem = ({
  alert,
  onEdit,
}: {
  alert: GeneralAlert;
  onEdit: (option: OptionType, alert: GeneralAlert) => void;
}) => {
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   console.log(alert);
  // }, []);

  const toggleActiveStatus = (alert: GeneralAlert) => {
    if (!alert.id) return; // Ensure the alert has an ID

    const updatedAlert = { ...alert, active: !alert.active };

    switch (alert.type) {
      case "crypto_price":
        dispatch(
          patchExistingCryptoPriceChangeAlert({
            id: alert.id,
            payload: updatedAlert,
          })
        );
        break;
      case "sport_news":
        dispatch(
          patchExistingSportsNewsAlert({ id: alert.id, payload: updatedAlert })
        );
        break;
      case "x_news":
        dispatch(
          patchExistingXNewsAlert({ id: alert.id, payload: updatedAlert })
        );
        break;
      case "events":
        dispatch(
          patchExistingEventAlert({ id: alert.id, payload: updatedAlert })
        );
        break;
      case "weather":
        dispatch(
          patchExistingWeatherAlert({
            id: alert.id,
            payload: updatedAlert as UpdateWeatherAlertParams,
          })
        );
        break;
      case "news":
        dispatch(
          patchExistingNewsAlert({ id: alert.id, payload: updatedAlert })
        );
        break;
      case "listings":
        dispatch(
          patchExistingNewListingAlert({ id: alert.id, payload: updatedAlert })
        );
        break;
      default:
        console.error("Unknown alert type:", alert.type);
    }
  };

  const handleDeleteAlert = (alert: any) => {
    if (!alert.id) return; // Ensure the alert has an ID

    switch (alert.type) {
      case "crypto_price":
        dispatch(removeCryptoPriceChangeAlert(alert.id));
        break;
      case "sport_news":
        dispatch(removeSportsNewsAlert(alert.id));
        break;
      case "x_news":
        dispatch(removeXNewsAlert(alert.id));
        break;
      case "events":
        dispatch(removeEventAlert(alert.id));
        break;
      case "weather":
        dispatch(removeWeatherAlert(alert.id));
        break;
      case "news":
        dispatch(removeNewsAlert(alert.id));
        break;
      case "listings":
        dispatch(removeNewListingAlert(alert.id));
        break;
      default:
        console.error("Unknown alert type:", alert.type);
    }
  };

  const handleEditAlert = (alert: any) => {
    if (!alert.id) return; // Ensure the alert has an ID

    switch (alert.type) {
      case "crypto_price":
        onEdit("Price Alerts", alert);
        break;
      case "sport_news":
        onEdit("Sport", alert);
        break;
      case "x_news":
        onEdit("X News", alert);
        break;
      case "events":
        onEdit("Event", alert);
        break;
      case "weather":
        onEdit("Weather", alert);
        break;
      case "news":
        if ("keyword" in alert) onEdit("News by Keyword", alert);
        else onEdit("News by Source", alert);
        break;
      case "listings":
        onEdit("New Listings", alert);
        break;
      default:
        console.error("Unknown alert type:", alert.type);
    }
  };
  const renderNotificationDetails = (
    details: NotificationDetails | undefined
  ) => {
    if (!details) return null;

    const iconsMap = {
      browser: (
        <FaGlobe
          title="Browser notifications enabled"
          className="text-white text-xl"
        />
      ),
      push_notifications: (
        <FaBell
          title="Push notifications enabled"
          className="text-white text-xl"
        />
      ),
      email: (
        <FaEnvelope
          title="Email notifications enabled"
          className="text-red-500 text-xl"
        />
      ),
      sms: (
        <FaSms
          title="SMS notifications enabled"
          className="text-green-500 text-xl"
        />
      ),
      whatsapp: (
        <FaWhatsapp
          title="WhatsApp notifications enabled"
          className="text-teal-500 text-xl"
        />
      ),
      telegram: (
        <FaTelegram
          title="Telegram notifications enabled"
          className="text-blue-500 text-xl"
        />
      ),
      slack: (
        <FaSlack
          title="Slack notifications enabled"
          className="text-purple-500 text-xl"
        />
      ),
      facebook_messenger: (
        <FaFacebookMessenger
          title="Facebook Messenger notifications enabled"
          className="text-blue-600 text-xl"
        />
      ),
      signal: (
        <FaSignal
          title="Signal notifications enabled"
          className="text-blue-500 text-xl"
        />
      ),
      viber: (
        <FaViber
          title="Viber notifications enabled"
          className="text-purple-500 text-xl"
        />
      ),
      wechat: (
        <FaWeixin
          title="WeChat notifications enabled"
          className="text-green-500 text-xl"
        />
      ),
      skype: (
        <FaSkype
          title="Skype notifications enabled"
          className="text-blue-500 text-xl"
        />
      ),
      line: (
        <FaLine
          title="Line notifications enabled"
          className="text-green-500 text-xl"
        />
      ),
      linkedin: (
        <FaLinkedin
          title="LinkedIn notifications enabled"
          className="text-blue-600 text-xl"
        />
      ),
      twitter: (
        <FaTwitter
          title="Twitter notifications enabled"
          className="text-blue-400 text-xl"
        />
      ),
      instagram: (
        <FaInstagram
          title="Instagram notifications enabled"
          className="text-pink-500 text-xl"
        />
      ),
      discord_webhook_url: (
        <FaDiscord
          title="Discord notifications enabled"
          className="text-indigo-500 text-xl"
        />
      ),
      teams_webhook_url: (
        <FaMicrosoft
          title="Microsoft Teams notifications enabled"
          className="text-blue-500 text-xl"
        />
      ),
    };

    const detailsAsMap = details as unknown as { [key: string]: boolean };
    return Object.keys(detailsAsMap).map((key) =>
      detailsAsMap[key] && key !== "id" ? (
        <span key={key} className="inline-block mr-2">
          <p className="flex items-center space-x-1">
            {iconsMap[key as keyof NotificationDetails]}
            <span className="text-white text-xs">{detailsAsMap[key]}</span>
          </p>
        </span>
      ) : null
    );
  };

  // Function to convert hex to RGB
  const hexToRgb = (hex: any) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };

  // Function to lighten an RGB color
  const lightenColor = (_color: any, percent: any) => {
    const color = hexToRgb(_color);
    const r = Math.min(255, Math.floor(color.r + (255 - color.r) * percent));
    const g = Math.min(255, Math.floor(color.g + (255 - color.g) * percent));
    const b = Math.min(255, Math.floor(color.b + (255 - color.b) * percent));
    return `rgb(${r}, ${g}, ${b})`;
  };

  const [showDetails, setShowDetails] = useState(false);

  const getAlertTitle = (): string => {
    switch (alert.type) {
      case "crypto_price":
        if ("pair" in alert) {
          return `Monitoring <span style="color:${lightenColor(
            alert.color,
            0.3
          )}; text-shadow: 0 0 20px rgba(0, 0, 0, 1);">${
            alert.pair
          }</span> price, waiting for <span style="color:${lightenColor(
            alert.color,
            0.3
          )}; text-shadow: 0 0 20px rgba(0, 0, 0, 1);">${
            alert.target_price_up
              ? alert.target_price_up
              : alert.target_price_down
          }${
            alert.target_price_up && alert.target_price_down
              ? "or " + alert.target_price_down
              : ""
          }</span>`;
        }
      case "x_news":
        if ("x_handles" in alert)
          return `Forwarding X posts from: <span style="color:${lightenColor(
            alert.color,
            0.3
          )}; text-shadow: 0 0 20px rgba(0, 0, 0, 1);">@${alert.x_handles
            .map((handle: XHandle) => handle.handle)
            .join(", @")}</span>${
            alert.keyword
              ? `, that contain keywords: (${alert.keyword
                  .replaceAll(";", ") or (")
                  .replaceAll(",", " and ")})`
              : ""
          }`;
      case "news":
        if ("sources" in alert && "keyword" in alert)
          return `Notifying about new articles from: <span style="color:${lightenColor(
            alert.color,
            0.4
          )}; text-shadow: 0 0 20px rgba(0, 0, 0, 1);">${alert.sources.map(
            (source: any) => source.name
          )}</span>${
            alert.keyword
              ? ` fitting keywords: (${alert.keyword
                  .replaceAll(";", ") or (")
                  .replaceAll(",", " and ")})`
              : ""
          }`;
      case "sport_news":
        if ("sport_sources" in alert && "keyword" in alert)
          return `Notifying about sport news from: <span style="color:${lightenColor(
            alert.color,
            0.4
          )}; text-shadow: 0 0 20px rgba(0, 0, 0, 1);">${alert.sport_sources.map(
            (source: any) => source.name
          )}</span>${
            alert.keyword
              ? `, that fit keywords: (${alert.keyword
                  .replaceAll(";", ") or (")
                  .replaceAll(",", " and ")})`
              : ""
          }`;
      case "events":
        if ("keyword" in alert && "location" in alert)
          return `Searching for new events in <span style="color:${lightenColor(
            alert.color,
            0.4
          )}; text-shadow: 0 0 20px rgba(0, 0, 0, 1);">${
            alert.location
          }</span> on Eventbrite${
            alert.keyword
              ? `, that fit keywords: (${alert.keyword
                  .replaceAll(";", ") or (")
                  .replaceAll(",", " and ")})`
              : ""
          }`;
      case "weather":
        if ("location" in alert)
          return `Reporting on weather in <span style="color:${lightenColor(
            alert.color,
            0.3
          )}; text-shadow: 0 0 20px rgba(0, 0, 0, 1);">${
            alert.location
          }</span> area`;
      case "listings":
        if ("sources" in alert)
          return `Notifying about new listings on <span style="color:${lightenColor(
            alert.color,
            0.3
          )}; text-shadow: 0 0 20px rgba(0, 0, 0, 1);">${
            alert.sources[0].name
          }</span>`;
      default:
        return "";
    }
  };

  const colorKeywordsInParentheses = (text: string) => {
    return text.replaceAll(
      /(?<!rgb)(?<!rgba)\(([^)]+)\)/g,
      (match, p1) =>
        `<span class="text-purple-600 text-shadow" style="text-shadow: 0 0 20px rgba(0, 0, 0, 1)">${p1}</span>`
    );
  };

  const icons: { [key in AlertType]?: JSX.Element } = {
    news: <RiNewspaperLine size={30} className="min-w-7" />,
    crypto_price: <RiPriceTag3Line size={30} className="min-w-7" />,
    listings: <RiInformationLine size={30} className="min-w-7" />,
    weather: <RiCloudFill size={30} className="min-w-7" />,
    sport_news: <RiFootballFill size={30} className="min-w-7" />,
    x_news: <RiTwitterXFill size={30} className="min-w-7" />,
    events: <RiCalendarEventFill size={30} className="min-w-7" />,
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="bg-black p-2 rounded-lg shadow mb-4"
      style={{
        backgroundColor: alert.color,
        color: lightenColor(alert.color, 0.65),
      }}
    >
      <div className="flex flex-col space-y-4 bg-gray-900 rounded-lg p-3">
        <div className="flex space-x-2 flex-col md:flex-row">
          <div
            className="mx-auto md:mx-0"
            style={{
              color: lightenColor(alert.color, 0.8),
            }}
          >
            {icons[alert.type]}
          </div>
          <div
            className="text-lg font-semibold"
            style={{ whiteSpace: "pre-wrap" }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: colorKeywordsInParentheses(getAlertTitle()),
              }}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-between items-start">
          {/* Left Section */}
          <div className="flex flex-col space-y-2">
            <div className="text-sm">
              <span
                dangerouslySetInnerHTML={{
                  __html: `Status: ${
                    alert.active
                      ? "<span class='text-green-600 font-bold'>Active</span>"
                      : "Inactive"
                  }`,
                }}
              ></span>
              <p className="text-gray-500"> Notification channels:</p>
            </div>
            <div className="flex flex-wrap space-x-2">
              {renderNotificationDetails(alert.notification_details)}
            </div>
          </div>

          {/* Right Section */}
          <div className="flex flex-col space-y-2 mt-auto md:mt-0">
            <div className="flex text-white">
              <button
                onClick={() => toggleActiveStatus(alert)}
                className="hover:bg-steel-blue p-2 rounded-full text-xl"
                title="Toggle Active Status"
              >
                <BiPowerOff />
              </button>
              <button
                onClick={() => handleEditAlert(alert)}
                className="hover:bg-goldenrod p-2 rounded-full text-xl"
                title="Edit Alert"
              >
                <BiEdit />
              </button>
              <button
                onClick={() => handleDeleteAlert(alert)}
                className="hover:bg-firebrick p-2 rounded-full text-xl"
                title="Delete Alert"
              >
                <RiDeleteBin6Line />
              </button>
              <button
                onClick={() => setShowDetails(!showDetails)}
                className="hover:bg-medium-sea-green p-2 rounded-full text-xl"
                title="View Details"
              >
                <BiDetail />
              </button>
            </div>
          </div>
        </div>
      </div>

      {showDetails && (
        <div className="mt-2 pl-4 text-sm bg-gray-900 rounded-lg p-3">
          <p>
            <strong>Details:</strong>
          </p>
          <p>
            Alert created: {new Date(alert.created_at || "").toLocaleString()}
          </p>
          {alert.type === "crypto_price" && "pair" in alert && (
            <>
              <p>Pair: {alert.pair}</p>
              <p>Target Price UP: {alert.target_price_up}</p>
              <p>Target Price DOWN: {alert.target_price_down}</p>
              <p>
                Last Check:{" "}
                {alert.last_check
                  ? new Date(alert.last_check).toLocaleString()
                  : "N/A"}
              </p>
              {/* Placeholder for historic price chart */}
              <LatestData
                sources={[]}
                type="crypto_price"
                limitOrPair={alert.pair}
              />
            </>
          )}
          {alert.type === "x_news" &&
            "x_handles" in alert &&
            !("sport_sources" in alert) && (
              <>
                <p>Keywords: {alert.keyword}</p>
                <p>Live Feed: {alert.live_feed ? "Enabled" : "Disabled"}</p>
                <p>
                  Sending Time:{" "}
                  {convertUTCTimeToLocal(alert.sending_time as string)}
                </p>
                <p>
                  Last Check:{" "}
                  {alert.last_check
                    ? new Date(alert.last_check).toLocaleString()
                    : "N/A"}
                </p>
                <LatestData
                  sources={alert.x_handles.map(
                    (handle: XHandle) => handle.handle
                  )}
                  type="x_posts"
                  limitOrPair={5}
                />
              </>
            )}
          {alert.type === "news" &&
            "keyword" in alert &&
            !("sport_sources" in alert) &&
            !("x_handles" in alert) &&
            !("location" in alert) && (
              <>
                <p>Keywords: {alert.keyword}</p>
                <p>Live Feed: {alert.live_feed ? "Enabled" : "Disabled"}</p>
                <p>
                  Sending Time:{" "}
                  {convertUTCTimeToLocal(alert.sending_time as string)}
                </p>
                <p>
                  Last Check:{" "}
                  {alert.last_check
                    ? new Date(alert.last_check).toLocaleString()
                    : "N/A"}
                </p>
                <LatestData
                  sources={alert.sources.map((source: Source) => source.name)}
                  type={"news"}
                  limitOrPair={5}
                />
              </>
            )}
          {alert.type === "sport_news" && "sport_sources" in alert && (
            <>
              <p>Keywords: {alert.keyword}</p>
              <p>Live Feed: {alert.live_feed ? "Enabled" : "Disabled"}</p>
              <p>
                Sending Time:{" "}
                {convertUTCTimeToLocal(alert.sending_time as string)}
              </p>
              <p>
                Last Check:{" "}
                {alert.last_check
                  ? new Date(alert.last_check).toLocaleString()
                  : "N/A"}
              </p>
              <LatestData
                sources={alert.sport_sources.map(
                  (source: Source) => source.name
                )}
                type={"news"}
                limitOrPair={5}
              />
            </>
          )}
          {alert.type === "listings" && "sources" in alert && (
            <>
              <p>
                Sources:{" "}
                {alert.sources.map((source: Source) => source.name).join(", ")}
              </p>
              <p>
                Last Check:{" "}
                {alert.last_check
                  ? new Date(alert.last_check).toLocaleString()
                  : "N/A"}
              </p>
              <p>{alert.sources.map((source: Source) => source.name)}</p>
              <LatestData
                sources={alert.sources.map((source: Source) => source.name)}
                type={"listings"}
                limitOrPair={5}
              />
            </>
          )}
          {alert.type === "events" &&
            "location" in alert &&
            "keyword" in alert && (
              <>
                <p>Event location : {alert.location}</p>
                <p>Keywords: {alert.keyword}</p>
                <p>
                  Last Check:{" "}
                  {alert.last_check
                    ? new Date(alert.last_check).toLocaleString()
                    : "N/A"}
                </p>
                {/* Placeholder for historic price chart */}
                <LatestData
                  sources={[alert.location]}
                  type="events"
                  limitOrPair={5}
                />
              </>
            )}
          {alert.type === "weather" &&
            "location" in alert &&
            "live_feed" in alert && (
              <>
                <p>Weather forecast : {alert.location}</p>
                <p>
                  Type :{" "}
                  {alert.live_feed
                    ? "Notifying when forecast changes"
                    : `Sending daily notification at ${convertUTCTimeToLocal(
                        alert.sending_time as string
                      )}`}
                </p>
                <p>
                  Last Check:{" "}
                  {alert.last_check
                    ? new Date(alert.last_check).toLocaleString()
                    : "N/A"}
                </p>
                {/* Placeholder for historic price chart */}
                {/* <p>[Latest weather data placeholder]</p> */}
              </>
            )}
        </div>
      )}
    </motion.div>
  );
};

export default AlertItem;
