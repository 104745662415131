import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";

type Tab = "calendar" | "new-action" | "action-list";

interface SidebarProps {
  onTabSelect: (tab: Tab) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onTabSelect }) => {
  const [activeTab, setActiveTab] = useState<Tab>("calendar");
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const tabs = [
    { id: "calendar", label: "Action schedule", icon: "📅" },
    { id: "new-action", label: "New Action", icon: "✏️" },
    { id: "action-list", label: "List my actions", icon: "📋" },
  ];

  const handleTabClick = (tab: Tab) => {
    setActiveTab(tab);
    onTabSelect(tab);
    if (window.innerWidth <= 768) {
      setIsOpen(false); // Close sidebar on mobile once a tab is clicked
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);

  return (
    <>
      <div
        ref={sidebarRef}
        className={classNames(
          "fixed top-0 left-0 h-screen transform transition-transform duration-300 bg-[#15061a] text-white w-[20%] z-20",
          {
            "-translate-x-full": !isOpen,
            "translate-x-0": isOpen,
          }
        )}
      >
        <h2 className="text-3xl font-semibold mb-6 p-4 ml-5 mt-20">Actions</h2>
        <ul className="space-y-2 px-4">
          {tabs.map((tab) => (
            <li
              key={tab.id}
              onClick={() => handleTabClick(tab.id as Tab)}
              className={`flex text-lg items-center p-2 rounded cursor-pointer ${
                activeTab === tab.id ? "bg-gray-700" : "hover:bg-gray-700"
              }`}
            >
              <span className="mr-2">{tab.icon}</span>
              <span>{tab.label}</span>
            </li>
          ))}
        </ul>
      </div>

      {/* Toggle Button */}
      {!isOpen && (
        <button
          onClick={toggleSidebar}
          className="fixed top-1/2 -left-12 transform flex items-center justify-center w-16 h-16 bg-gray-800 bg-opacity-80 rounded-full z-30"
          style={{
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.4)",
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="text-white"
          >
            <path
              d="M8 5L16 12L8 19"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
    </>
  );
};

export default Sidebar;