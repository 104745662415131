import axios from './axios';
import {
  NewsAndListingsResponse,
  EventsAndXPostsResponse,
  CryptoPriceTicksResponse
} from '../types/scraperDataTypes';

export const fetchNewsAndListings = async (sources: string[], limit: number = 5): Promise<NewsAndListingsResponse> => {
    try {
      // console.error(`aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa${sources}`)
      const response = await axios.get<NewsAndListingsResponse>(`/scraper/news-and-listings/`, { params:{ sources:sources, limit }});
      return response.data;
    } catch (error) {
      console.error('Error fetching news and listings:', error);
      throw error;
    }
  };

  export const fetchEventsAndXPosts = async (location?: string, handles: string[] = [], eventLimit: number = 5, postLimit: number = 5): Promise<EventsAndXPostsResponse> => {
    try {
        const params: Record<string, any> = { event_limit: eventLimit, post_limit: postLimit };
        if (location) params.location = location;
        if (handles.length) params.handles = handles;

        const response = await axios.get<EventsAndXPostsResponse>(`/scraper/events-xposts/`, { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching events and X posts:', error);
        throw error;
    }
};

export const fetchCryptoPriceTicks = async (pair: string): Promise<CryptoPriceTicksResponse> => {
    try {
        const response = await axios.get<CryptoPriceTicksResponse>(`/scraper/crypto-price-ticks/`, { params: { pair } });
        return response.data;
    } catch (error) {
        console.error('Error fetching crypto price ticks:', error);
        throw error;
    }
};