import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/store";
import { getVapidKey } from "../utils/getVapidKey";
import { StandardPushSubscription } from "../types/pushSubscriptionTypes";
import { subPushNotifications } from "../api/alertService";
import {
  RiTelegramLine,
  RiWhatsappLine,
  RiMicrosoftFill,
  RiSmartphoneLine,
  RiMailLine,
  RiDiscordLine,
  RiSlackLine,
  RiFacebookCircleLine,
  RiSettings4Line,
  RiChat3Line,
  RiPhoneLine,
  RiWechatLine,
  RiSkypeLine,
  RiLineLine,
  RiLinkedinBoxFill,
  RiTwitterXLine,
  RiInstagramLine,
} from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import {
  updatePreferencesThunk,
  updateNotificationDetailsThunk,
  selectNotificationDetails,
} from "../features/user/userSlice";

const platforms = [
  {
    name: "Email",
    id: "email",
    icon: <RiMailLine className="text-red-500 text-2xl" />,
    placeholder: "Your email address",
    description: "Get email notifications for alerts and updates.",
    available: true,
    limit: 1000,
  },
  {
    name: "Slack",
    id: "slack",
    icon: <RiSlackLine className="text-purple-500 text-2xl" />,
    placeholder: "Slack webhook url",
    description: "Integrate notifications into your Slack channels. How to set up: <a target='_blank' href='https://api.slack.com/messaging/webhooks'>tutorial</a>",
    available: true,
    limit: 1000,
  },
  {
    name: "X",
    id: "twitter",
    icon: <RiTwitterXLine className="text-blue-500 text-2xl" />,
    placeholder: "X handle",
    description: "Receive X DM alerts.",
    available: false,
    limit: 1000,
  },
  {
    name: "Instagram",
    id: "instagram",
    icon: <RiInstagramLine className="text-pink-600 text-2xl" />,
    placeholder: "Instagram username",
    description: "Alerts via Instagram DMs.",
    available: false,
    limit: 1000,
  },
  {
    name: "SMS",
    id: "sms",
    icon: <RiSmartphoneLine className="text-yellow-500 text-2xl" />,
    placeholder: "Mobile number with country code",
    description: "Receive SMS alerts on your phone.",
    available: true,
    limit: 10,
  },
  {
    name: "LinkedIn",
    id: "linkedin",
    icon: <RiLinkedinBoxFill className="text-blue-700 text-2xl" />,
    placeholder: "LinkedIn profile URL",
    description: "Professional alerts through LinkedIn.",
    available: false,
    limit: 1000,
  },
  {
    name: "Teams",
    id: "teams_webhook_url",
    icon: <RiMicrosoftFill className="text-blue-600 text-2xl" />,
    placeholder: "Teams webhook url",
    description: "Connect with your Microsoft Teams workspace.",
    available: false,
    limit: 1000,
  },
  {
    name: "Facebook Messenger",
    id: "facebook_messenger",
    icon: <RiFacebookCircleLine className="text-blue-600 text-2xl" />,
    placeholder: "Messenger ID or linked phone number",
    description: "Connect for instant updates on Facebook Messenger.",
    available: false,
    limit: 1000,
  },
  {
    name: "Telegram",
    id: "telegram",
    icon: <RiTelegramLine className="text-blue-400 text-2xl" />,
    placeholder: "Telegram bot token",
    description: "Get notifications directly in your Telegram chat. How to set up: <a target='_blank' href='https://www.siteguarding.com/en/how-to-get-telegram-bot-api-token'>tutorial</a>",
    available: true,
    limit: 1000,
  },
  {
    name: "Signal",
    id: "signal",
    icon: <RiChat3Line className="text-red-600 text-2xl" />,
    placeholder: "Signal number or identifier",
    description: "Secure messages with Signal notifications.",
    available: false,
    limit: 1000,
  },
  {
    name: "Skype",
    id: "skype",
    icon: <RiSkypeLine className="text-blue-300 text-2xl" />,
    placeholder: "Skype username or email",
    description: "Skype calls or messages for updates.",
    available: false,
    limit: 1000,
  },
  {
    name: "Viber",
    id: "viber",
    icon: <RiPhoneLine className="text-purple-600 text-2xl" />,
    placeholder: "Viber contact number",
    description: "Get Viber messages for alerts.",
    available: false,
    limit: 1000,
  },
  {
    name: "Discord",
    id: "discord_webhook_url",
    icon: <RiDiscordLine className="text-indigo-500 text-2xl" />,
    placeholder: "Discord user ID or channel",
    description: "Join our Discord server for real-time notifications. How to set up: <a target='_blank' href='https://support.discord.com/hc/en-us/articles/228383668-Intro-to-Webhooks'>tutorial</a>",
    available: true,
    limit: 1000,
  },
  {
    name: "WhatsApp",
    id: "whatsapp",
    icon: <RiWhatsappLine className="text-green-500 text-2xl" />,
    placeholder: "WhatsApp number with country code",
    description: "Receive alert messages on your WhatsApp.",
    available: false,
    limit: 1000,
  },
  {
    name: "WeChat",
    id: "wechat",
    icon: <RiWechatLine className="text-green-600 text-2xl" />,
    placeholder: "WeChat ID or phone number",
    description: "Receive notifications via WeChat.",
    available: false,
    limit: 1000,
  },
  {
    name: "Line",
    id: "line",
    icon: <RiLineLine className="text-green-700 text-2xl" />,
    placeholder: "Line user ID",
    description: "Connect on Line for instant messages.",
    available: false,
    limit: 1000,
  },
];

const SettingsPage = () => {
  const [activePlatform, setActivePlatform] = useState<string | null>(null);
  const [activePlatformName, setActivePlatformName] = useState<string | null>(null);
  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const dispatch = useAppDispatch();
  const notificationDetails = useAppSelector(selectNotificationDetails);
  //
  const [browserNotifications, setBrowserNotifications] = useState(false);
  const [pushNotifications, setPushNotifications] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [pushSubscription, setPushSubscription] = useState<StandardPushSubscription | null>(null);

  useEffect(() => {
      async function subscribeUser() {
          if ("serviceWorker" in navigator && "PushManager" in window) {
              const registration = await navigator.serviceWorker.register("/service-worker.js");
              const subscription = await registration.pushManager.subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: getVapidKey()
              });

              const { endpoint, keys } = subscription.toJSON();
              if(endpoint && keys){setPushSubscription({ endpoint, p256dh: keys.p256dh, auth: keys.auth });}
          } else {
              console.warn("Push notifications are not supported by your browser.");
          }
      }

      subscribeUser().catch((error) => {
        console.error('Failed to subscribe to push notifications:', error);
      });
  }, []);

  async function handleSubmitSubscribe() {
      if (pushSubscription) {
          try {
              const response = await subPushNotifications(JSON.stringify(pushSubscription));

              if (response.ok) {
                  console.log("Subscribed successfully!");
              } else {
                  console.error("Failed to subscribe.");
              }
          } catch (err) {
              console.error("Error subscribing:", err);
          }
      }
  }

  // Function to ask for notification permission
  const askNotificationPermission = () => {
    if (!('Notification' in window)) {
        console.log('This browser does not support desktop notifications');
    } else {
        Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
                console.log('Notification permission granted.');
            }
        });
    }
  };

  useEffect(()=>{
    console.log(formData);
  }, [formData])

  const handleInputChange = (
    platform: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, [platform]: event.target.value });
  };

  const handleUpdateNotificationDetails = () => {
    if (activePlatform) {
      const updatedDetails = {
        ...notificationDetails,
        [activePlatform]: formData[activePlatform],
      };
      // Dispatch the thunk to update notification details
      dispatch(updateNotificationDetailsThunk(updatedDetails));
    }
  };

  const toggleBrowserNotifications = () => {
    if(!browserNotifications){
      askNotificationPermission();
    }
    const updatedDetails = {
      ...notificationDetails,
      ['browser']: !browserNotifications,
    };
    // Dispatch the thunk to update notification details
    dispatch(updateNotificationDetailsThunk(updatedDetails));
    setBrowserNotifications(!browserNotifications);
  };

  const togglePushNotifications = async () => {
    if(!pushNotifications){
      await handleSubmitSubscribe();
    }
    const updatedDetails = {
      ...notificationDetails,
      ['push_notifications']: !pushNotifications,
    };
    // Dispatch the thunk to update notification details
    dispatch(updateNotificationDetailsThunk(updatedDetails));
    setPushNotifications(!pushNotifications);
  };

  const handleChangePasswordClick = () => {
    setShowPasswordModal(true);
  };

  const handlePasswordChange = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordData({ ...passwordData, [field]: event.target.value });
  };

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const handleUpdatePassword = () => {
    if (passwordData.newPassword !== passwordData.confirmPassword) {
      alert("New passwords do not match!");
      return;
    }
    console.log("Password updated to:", passwordData.newPassword);
    setShowPasswordModal(false);
  };

  const handleToggleTheme = () => {
    dispatch(updatePreferencesThunk({ darkMode: !isDarkMode }));
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    if (activePlatform && notificationDetails) {
      setFormData({
        ...formData,
        [activePlatform]: notificationDetails[activePlatform] || "",
      });
    }
  }, [activePlatform, notificationDetails]);

  return (
    <div
      className={`flex flex-col h-screen ${
        isDarkMode ? "bg-gray-900" : "bg-gray-100"
      } text-${isDarkMode ? "white" : "gray-900"} p-4`}
    >
      <div className="flex justify-between items-center mb-6">
        <h1
          className={`text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-blue-600`}
        >
          Notification Settings
        </h1>
        <RiSettings4Line
          size={30}
          className={`text-${isDarkMode ? "blue-300" : "blue-600"}`}
        />
      </div>

      <div
        className={`bg-${
          isDarkMode ? "gray-800" : "white"
        } p-4 rounded-md shadow-md flex flex-col space-y-4 overflow-y-auto`}
      >
        <h2 className="text-xl font-semibold mb-0">Set default notification channels</h2>
        <p style={{marginBlockStart: '0'}}className="text-sm text-gray-400">( You can set different values for each alert when creating alert, these are just default values)</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {platforms.map((platform) => (
            <motion.div
            key={platform.id}
            className={`p-4 rounded-md shadow cursor-pointer ${
              activePlatform === platform.id
                ? "bg-gray-700"
                : platform.available ? "bg-gray-900 hover:bg-gray-700" : "bg-gray-900 hover:bg-gray-900"
            } ${!isDarkMode && "bg-gray-300 hover:bg-gray-200"}`}
            onClick={() => { if(platform.available){setActivePlatform(platform.id); setActivePlatformName(platform.name)}}}
            whileHover={platform.available ? { scale: 1.05 } : { scale: 1.0 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center space-x-2">
                {platform.icon}
                <span className="font-semibold flex-grow">{platform.name}</span>
              </div>
              {!platform.available && <div className="flex items-center space-x-2 m-auto text-sm text-red-500">
                  Available soon!
              </div>}
              {platform.available && <div className="flex items-center space-x-2 m-auto text-sm text-blue-500">
                  Limit {platform.limit}/month
              </div>}
              <span
                className={`ml-auto block h-4 w-4 rounded-full ${
                  notificationDetails[platform.id] ? 'bg-green-500' : 'bg-gray-400'
                }`}
              />
            </div>
          </motion.div>
          ))}
        </div>

        {activePlatformName && activePlatform && (
          <motion.div
            className={`bg-${
              isDarkMode ? "gray-700" : "gray-200"
            } p-4 rounded-md shadow mt-4`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-lg font-semibold mb-2">
              Configure {activePlatformName} Settings
            </h3>
            <p
              className={`text-sm text-${
                isDarkMode ? "gray-400" : "gray-600"
              } mb-4`}
            >
            <p
              className={`text-sm text-${isDarkMode ? "gray-400" : "gray-600"} mb-4`}
              dangerouslySetInnerHTML={{ 
                __html: platforms.find((p) => p.name === activePlatformName)?.description || '' 
              }}
            />
            </p>
            <input
              type="text"
              placeholder={
                platforms.find((p) => p.name === activePlatformName)?.placeholder
              }
              value={formData[activePlatform] || ""}
              onChange={(e) => handleInputChange(activePlatform, e)}
              className={`w-full p-3 rounded-md ${
                isDarkMode ? "bg-gray-800" : "bg-white"
              } border ${
                isDarkMode ? "border-gray-600" : "border-gray-300"
              } focus:border-blue-500 focus:outline-none`}
            />
            <div className="flex mt-4 justify-end">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={handleUpdateNotificationDetails}
              >
                Update
              </button>
            </div>
          </motion.div>
        )}

        <div className="mt-8 grid grid-cols-2 gap-4">
          <div>
            <h2 className="text-xl font-semibold mb-4">
              Browser Notifications
            </h2>
            <label className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  id="browserNotifications"
                  className="sr-only"
                  checked={browserNotifications}
                  onChange={toggleBrowserNotifications}
                />
                <div
                  className={`w-14 h-8 rounded-full flex items-center justify-center transition-transform duration-300 ease-in-out ${
                    browserNotifications
                      ? "bg-gradient-to-r from-purple-400 via-pink-500 to-blue-600"
                      : "bg-gray-700"
                  }`}
                >
                  <div
                    className={`bg-white w-6 h-6 mr-5 rounded-full transition-transform duration-300 ease-in-out ${
                      browserNotifications ? "translate-x-6" : "translate-x-0"
                    }`}
                  ></div>
                </div>
              </div>
              <div className="ml-3 text-sm font-medium text-gray-300">
                {browserNotifications ? "Enabled" : "Disabled"}
              </div>
            </label>
          </div>

          <div>
            {/* Duplicate the entire toggle element here */}
            <h2 className="text-xl font-semibold mb-4">Push Notifications</h2>
            <label className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  id="anotherNotification"
                  className="sr-only"
                  checked={pushNotifications}
                  onChange={togglePushNotifications}
                />
                <div
                  className={`w-14 h-8 rounded-full flex items-center justify-center transition-transform duration-300 ease-in-out ${
                    pushNotifications
                      ? "bg-gradient-to-r from-purple-400 via-pink-500 to-blue-600"
                      : "bg-gray-700"
                  }`}
                >
                  <div
                    className={`bg-white w-6 h-6 mr-5 rounded-full transition-transform duration-300 ease-in-out ${
                      pushNotifications ? "translate-x-6" : "translate-x-0"
                    }`}
                  ></div>
                </div>
              </div>
              <div className="ml-3 text-sm font-medium text-gray-300">
                {pushNotifications ? "Enabled" : "Disabled"}
              </div>
            </label>
          </div>
        </div>
        <hr className="mt-4 border-t border-gray-300" />
        <div className="mt-8 grid grid-cols-2 gap-4">
          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4">Dark Mode</h2>
            <label className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  checked={isDarkMode}
                  onChange={handleToggleTheme}
                  className="sr-only"
                />
                <div
                  className={`w-14 h-8 rounded-full flex items-center justify-center transition-transform duration-300 ease-in-out ${
                    isDarkMode
                      ? "bg-gradient-to-r from-purple-400 via-pink-500 to-blue-600"
                      : "bg-gray-300"
                  }`}
                >
                  <div
                    className={`bg-white w-6 h-6 mr-5 rounded-full transition-transform duration-300 ease-in-out ${
                      isDarkMode ? "translate-x-6" : "translate-x-0"
                    }`}
                  ></div>
                </div>
              </div>
            </label>
          </div>

          <div className="mt-8">
            <h2 className="text-xl font-semibold mb-4">Change Password</h2>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={handleChangePasswordClick}
            >
              Change Password
            </button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {showPasswordModal && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm flex justify-center items-center p-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className={`bg-${
                isDarkMode ? "gray-800" : "white"
              } rounded-lg shadow-lg w-full max-w-md`}
              initial={{ scale: 0.9, y: 50 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 50 }}
              transition={{ type: "spring", stiffness: 500 }}
            >
              <div className="p-6">
                <h3 className="text-lg font-semibold mb-4">
                  Change Your Password
                </h3>
                <label className="block mb-4">
                  <span
                    className={`text-sm font-medium text-${
                      isDarkMode ? "gray-300" : "gray-700"
                    }`}
                  >
                    Old Password
                  </span>
                  <input
                    type="password"
                    value={passwordData.oldPassword}
                    onChange={(e) => handlePasswordChange("oldPassword", e)}
                    className={`mt-1 block w-full p-2.5 bg-${
                      isDarkMode ? "gray-700" : "gray-200"
                    } border ${
                      isDarkMode ? "border-gray-600" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:border-blue-500`}
                  />
                </label>
                <label className="block mb-4">
                  <span
                    className={`text-sm font-medium text-${
                      isDarkMode ? "gray-300" : "gray-700"
                    }`}
                  >
                    New Password
                  </span>
                  <input
                    type="password"
                    value={passwordData.newPassword}
                    onChange={(e) => handlePasswordChange("newPassword", e)}
                    className={`mt-1 block w-full p-2.5 bg-${
                      isDarkMode ? "gray-700" : "gray-200"
                    } border ${
                      isDarkMode ? "border-gray-600" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:border-blue-500`}
                  />
                </label>
                <label className="block mb-4">
                  <span
                    className={`text-sm font-medium text-${
                      isDarkMode ? "gray-300" : "gray-700"
                    }`}
                  >
                    Confirm New Password
                  </span>
                  <input
                    type="password"
                    value={passwordData.confirmPassword}
                    onChange={(e) => handlePasswordChange("confirmPassword", e)}
                    className={`mt-1 block w-full p-2.5 bg-${
                      isDarkMode ? "gray-700" : "gray-200"
                    } border ${
                      isDarkMode ? "border-gray-600" : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:border-blue-500`}
                  />
                </label>
                <div className="flex justify-end mt-4">
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                    onClick={handleClosePasswordModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleUpdatePassword}
                  >
                    Update Password
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SettingsPage;
