import React, { useRef, useState, useEffect } from 'react';

interface EyeProps {
  cx: number;
  cy: number;
  baseRadius: number;
}

const Eye: React.FC<EyeProps> = ({ cx, cy, baseRadius }) => {
  const [pupilX, setPupilX] = useState(cx);
  const [pupilY, setPupilY] = useState(cy);
  const [pupilSize, setPupilSize] = useState(baseRadius / 2);
  const [eyeSize, setEyeSize] = useState(baseRadius);
  const eyeRef = useRef<SVGCircleElement | null>(null);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const eye = eyeRef.current;
      if (eye) {
        const { left, top, width, height } = eye.getBoundingClientRect();
        const eyeCenterX = left + width / 2;
        const eyeCenterY = top + height / 2;

        const diffX = event.clientX - eyeCenterX;
        const diffY = event.clientY - eyeCenterY;
        const distance = Math.sqrt(diffX * diffX + diffY * diffY);
        const maxEyeMove = baseRadius / 2;

        const angle = Math.atan2(diffY, diffX);
        const moveDistance = Math.min(maxEyeMove, distance / 5);
        const pupilOffsetX = moveDistance * Math.cos(angle);
        const pupilOffsetY = moveDistance * Math.sin(angle);

        const proximityEffect = Math.max(0, (100 - distance) / 100);
        const scaredFactor = 1 + proximityEffect * 1.5;

        setPupilX(cx + pupilOffsetX);
        setPupilY(cy + pupilOffsetY);
        setPupilSize((baseRadius / 2 - proximityEffect * 5) * scaredFactor);
        setEyeSize(baseRadius);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, [cx, cy, baseRadius]);

  return (
    <g ref={eyeRef}>
      {/* Outer Glow */}
      <radialGradient id={`eyeGlow${cx}${cy}`} cx="50%" cy="50%" r="50%">
        <stop stopColor="#ffffff" offset="0%" />
        <stop stopColor="#2d2d2d" offset="100%" />
      </radialGradient>
      <circle cx={cx} cy={cy} r={eyeSize + 5} fill={`url(#eyeGlow${cx}${cy})`} />

      {/* White of the Eye */}
      <radialGradient id={`eyeWhite${cx}${cy}`} cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
        <stop stopColor="#ffffff" offset="40%" />
        <stop stopColor="#f0f0f0" offset="100%" />
      </radialGradient>
      <circle cx={cx} cy={cy} r={eyeSize} fill={`url(#eyeWhite${cx}${cy})`} />

      {/* Pupil */}
      <radialGradient id={`pupilGradient${cx}${cy}`} cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
        <stop stopColor="#333333" offset="0%" />
        <stop stopColor="#000000" offset="100%" />
      </radialGradient>
      <circle cx={pupilX} cy={pupilY} r={pupilSize} fill={`url(#pupilGradient${cx}${cy})`} />
    </g>
  );
};

export default Eye;