import React from "react";
import { AlertType, GeneralAlert } from "../../../types/alertTypes";
import {
  RiCalendarEventFill,
  RiCloudFill,
  RiFootballFill,
  RiInformationLine,
  RiNewspaperLine,
  RiPriceTag3Line,
  RiTwitterXFill,
} from "react-icons/ri";

interface Props {
  alert?: GeneralAlert | null;
  title: string;
}

const ModalTitle: React.FC<Props> = ({ alert, title }) => {
  const icons: { [key in AlertType]?: JSX.Element } = {
    news: <RiNewspaperLine size={30} className="min-w-7" />,
    crypto_price: <RiPriceTag3Line size={30} className="min-w-7" />,
    listings: <RiInformationLine size={30} className="min-w-7" />,
    weather: <RiCloudFill size={30} className="min-w-7" />,
    sport_news: <RiFootballFill size={30} className="min-w-7" />,
    x_news: <RiTwitterXFill size={30} className="min-w-7" />,
    events: <RiCalendarEventFill size={30} className="min-w-7" />,
  };

  // Function to convert hex to RGB
  const hexToRgb = (hex: any) => {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };

  // Function to lighten an RGB color
  const lightenColor = (_color: any, percent: any) => {
    const color = hexToRgb(_color);
    const r = Math.min(255, Math.floor(color.r + (255 - color.r) * percent));
    const g = Math.min(255, Math.floor(color.g + (255 - color.g) * percent));
    const b = Math.min(255, Math.floor(color.b + (255 - color.b) * percent));
    return `rgb(${r}, ${g}, ${b})`;
  };

  const backgroundStyle =
    alert && alert.color
      ? {
          backgroundImage: `linear-gradient(to right,${lightenColor(
            alert.color,
            0.5
          )}, ${lightenColor(alert.color, 0.3)})`,
        }
      : {};

  return (
    <div className="mx-auto">
      <div className="flex justify-center items-center space-x-2">
        {alert && (
          <div
            className="mb-6 "
            style={{
              color: lightenColor(alert.color, 0.8),
            }}
          >
            {icons[alert.type]}
          </div>
        )}
        <h2
          className="text-3xl font-bold mb-6 text-transparent bg-clip-text bg-lighter-gradient text-center"
          style={backgroundStyle}
        >
          {alert ? "Edit" : "Create"} {title}
        </h2>
      </div>
    </div>
  );
};

export default ModalTitle;
