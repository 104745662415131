import React, { useState, useEffect } from "react";
import { CirclePicker } from "react-color";
import {
  addNewListingAlert,
  patchExistingNewListingAlert,
  selectLoading,
} from "../../features/alerts/alertsSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  NewListingAlert,
  CreateNewListingAlertParams,
  NotificationDetails,
  UpdateNewListingAlertParams,
} from "../../types/alertTypes";
import { findMostDistinctColor } from "./components/ColorGenerator";
import NotificationDetailsEditor, {
  defaultNotificationDetails,
} from "./components/NotificationDetails";
import Tooltip from "./components/FormElements";
import { FaInfoCircle } from "react-icons/fa";
import AnimatedModalContainer from "./components/AnimatedModalContainer";
import ModalTitle from "./components/ModalTitle";

const sources = [
  { value: "BN", label: "Binance" },
  { value: "HB", label: "Huobi" },
  { value: "KU", label: "KuCoin" },
];

// Convert a RGB color array to a hex string
function rgbToHex(rgb: any) {
  return "#" + rgb.map((x: any) => x.toString(16).padStart(2, "0")).join("");
}

interface ListingsModalProps {
  isOpen: boolean;
  onClose: () => void;
  alert?: NewListingAlert | null;
}

const ListingsModal: React.FC<ListingsModalProps> = ({
  isOpen,
  onClose,
  alert,
}) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  // const [selectedKeywordSets, setSelectedKeywordSets] = useState<string[][]>([]);
  const [source, setSource] = useState<string>();
  const [notificationDetails, setNotificationDetails] =
    useState<NotificationDetails>(defaultNotificationDetails);
  const [color, setColor] = useState("");

  // Prepare initial existing colors (these could be fetched or calculated)
  const existingColors = [
    [34, 139, 34], // ForestGreen
    [70, 130, 180], // SteelBlue
    [220, 20, 60], // Crimson
  ];

  useEffect(() => {
    if (alert) {
      // console.error(alert);
      // Populate the form fields with the existing listing alert data
      if ("sources" in alert) {
        // console.warn(alert.source);
        setSource(alert.sources[0].code);
      }
      // if ("keyword" in alert && alert.keyword)
      //   setSelectedKeywordSets(
      //     alert.keyword
      //       .split("; ")
      //       .map((set) =>
      //         set.split(", ").map((keyword) => keyword.replace(/^"|"$/g, ""))
      //       )
      //   );
      setNotificationDetails(alert.notification_details);
      setColor(alert.color);
    } else {
      // Clear all fields
      setSource("");
      // setSelectedKeywordSets([]); // Uncomment if you use keyword sets
      setNotificationDetails(defaultNotificationDetails);
      setColor("");
    }
  }, [alert]);

  // Compute a distinct color on initial render
  useEffect(() => {
    const newColor = findMostDistinctColor(existingColors);
    setColor(rgbToHex(newColor));
  }, []);

  const handleCreateAlert = () => {
    const alertData: CreateNewListingAlertParams = {
      sources: sources
        .filter((s) => s.value === source)
        .map((s) => ({
          code: s.value,
          name: s.label,
        })),
      notification_details: notificationDetails,
      active: true,
      color: color,
    };

    if (alert)
      dispatch(
        patchExistingNewListingAlert({
          id: alert.id,
          payload: alertData as UpdateNewListingAlertParams,
        })
      );
    else dispatch(addNewListingAlert(alertData as CreateNewListingAlertParams));

    onClose();
  };

  const colors = [
    "#C65C5C", // Toned Down Indian Red
    "#D1A355", // Toned Down GoldenRod
    "#7FBF7F", // Toned Down Dark Sea Green
    "#1A9B97", // Toned Down Light Sea Green
    "#5A8E8E", // Toned Down Cadet Blue
    "#1A76D1", // Toned Down Dodger Blue
    "#DB7093", // Toned Down Hot Pink
    "#D93C00", // Toned Down OrangeRed
    "#8465B9", // Toned Down Medium Purple
    "#2E9E71", // Toned Down Medium Sea Green
    "#D4AF37", // Toned Down Gold
    "#A75373", // Toned Down Medium Violet Red
    "#3E7CA6", // Toned Down Steel Blue
    "#B86123", // Toned Down Chocolate
    "#763FB2", // Toned Down Blue Violet
    "#355CA8", // Toned Down Royal Blue
    "#A11A1A", // Toned Down Firebrick
    "#8B1D1D", // Toned Down Brown
  ];

  if (!isOpen) return null;

  return (
    <AnimatedModalContainer>
      <ModalTitle alert={alert} title="New Listings Alert" />
      <form className="space-y-4">
        <label className="block">
          <div className="relative flex items-center gap-2">
            <span className="text-gray-400">Sources</span>
            <Tooltip
              message={`Choose the source you want to monitor for new listings.`}
            >
              <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
            </Tooltip>
          </div>
          <select
            value={source}
            onChange={(e) => setSource(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
          >
            <option value="">Choose a Source</option>
            {sources.map((s) => (
              <option key={s.value} value={s.value}>
                {s.label}
              </option>
            ))}
          </select>
        </label>
        {/* <label className="block">
          <KeywordSetPicker
              selectedKeywordSets={selectedKeywordSets}
              setSelectedKeywordSets={setSelectedKeywordSets}
            />
          </label> */}
        <label className="block">
          <div className="relative flex items-center gap-2">
            <span className="text-gray-400 mb-1">Alert Color</span>
            <Tooltip
              message={`Choose a fitting color to easier recognise your listing alert`}
            >
              <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
            </Tooltip>
          </div>
          <CirclePicker
            color={color}
            onChangeComplete={(color: any) => setColor(color.hex)}
            colors={colors}
          />
        </label>
        <label className="block">
          <div className="relative flex items-center gap-2">
            <span className="text-gray-400">Notification Details</span>{" "}
            <Tooltip
              message={`Channels where you want to recieve notifications.`}
            >
              <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
            </Tooltip>
          </div>
          <p></p>
          <a href="/settings" className="text-gray-500 text-sm underline">
            (you can set default values in settings)
          </a>
          <NotificationDetailsEditor
            notificationDetails={notificationDetails}
            setNotificationDetails={setNotificationDetails}
          />
        </label>
        <div className="flex items-center justify-between mt-4">
          <button
            type="button"
            onClick={onClose}
            className="inline-flex items-center px-4 py-2 bg-gray-700 border border-transparent rounded-md font-semibold text-xs text-gray-300 uppercase tracking-widest hover:bg-gray-600 active:bg-gray-800 focus:outline-none focus:border-gray-400 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleCreateAlert}
            className="inline-flex items-center px-4 py-2 bg-blue-500 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-blue-600 active:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring ring-blue-300 disabled:opacity-25 transition ease-in-out duration-150"
          >
            {alert ? "Update Alert" : "Create Alert"}
          </button>
        </div>
      </form>
    </AnimatedModalContainer>
  );
};

export default ListingsModal;
