import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GeneralAlert,
  AlertsState,
  CreateCryptoPriceChangeAlertParams,
  CreateNewsAlertParams,
  CreateNewListingAlertParams,
  GeneralAlertWithType,
  CreateWeatherAlertParams,
  UpdateWeatherAlertParams,
  CreateSportsNewsAlertParams,
  UpdateSportsNewsAlertParams,
  CreateXNewsAlertParams,
  UpdateXNewsAlertParams,
  UpdateEventAlertParams,
  CreateEventAlertParams,
  AlertType,
  UpdateNewListingAlertParams,
  UpdateNewsAlertParams,
  UpdateCryptoPriceChangeAlertParams,
} from "../../types/alertTypes";
import { RootState } from "../../app/store";
import {
  getAllAlerts,
  createCryptoPriceChangeAlert,
  updateCryptoPriceChangeAlert,
  patchCryptoPriceChangeAlert,
  deleteCryptoPriceChangeAlert,
  createNewsAlert,
  updateNewsAlert,
  patchNewsAlert,
  deleteNewsAlert,
  createNewListingAlert,
  updateNewListingAlert,
  patchNewListingAlert,
  deleteNewListingAlert,
  createWeatherAlert,
  updateWeatherAlert,
  patchWeatherAlert,
  deleteWeatherAlert,
  createSportsNewsAlert,
  updateSportsNewsAlert,
  patchSportsNewsAlert,
  deleteSportsNewsAlert,
  createXNewsAlert,
  updateXNewsAlert,
  patchXNewsAlert,
  deleteXNewsAlert,
  createEventAlert,
  updateEventAlert,
  patchEventAlert,
  deleteEventAlert,
} from "../../api/alertService";

// Async thunks for CRUD operations

// Fetch all alerts
export const fetchAllAlerts = createAsyncThunk(
  "alerts/fetchAllAlerts",
  async (): Promise<GeneralAlertWithType[]> => {
    return await getAllAlerts();
  }
);

// Add Crypto Price Change Alert
export const addCryptoPriceChangeAlert = createAsyncThunk(
  "alerts/addCryptoPriceChangeAlert",
  async (params: CreateCryptoPriceChangeAlertParams) => {
    return await createCryptoPriceChangeAlert(params);
  }
);

// Add News Alert
export const addNewsAlert = createAsyncThunk(
  "alerts/addNewsAlert",
  async (params: CreateNewsAlertParams) => {
    return await createNewsAlert(params);
  }
);

// Add New Listing Alert
export const addNewListingAlert = createAsyncThunk(
  "alerts/addNewListingAlert",
  async (params: CreateNewListingAlertParams) => {
    return await createNewListingAlert(params);
  }
);

// Update Crypto Price Change Alert
export const updateExistingCryptoPriceChangeAlert = createAsyncThunk(
  "alerts/updateCryptoPriceChangeAlert",
  async ({ id, alert }: { id: number; alert: GeneralAlert }) => {
    return await updateCryptoPriceChangeAlert(id, alert);
  }
);

// Update News Alert
export const updateExistingNewsAlert = createAsyncThunk(
  "alerts/updateNewsAlert",
  async ({ id, alert }: { id: number; alert: GeneralAlert }) => {
    return await updateNewsAlert(id, alert);
  }
);

// Update New Listing Alert
export const updateExistingNewListingAlert = createAsyncThunk(
  "alerts/updateNewListingAlert",
  async ({ id, alert }: { id: number; alert: GeneralAlert }) => {
    return await updateNewListingAlert(id, alert);
  }
);

// Patch Crypto Price Change Alert
export const patchExistingCryptoPriceChangeAlert = createAsyncThunk(
  "alerts/patchCryptoPriceChangeAlert",
  async ({
    id,
    payload,
  }: {
    id: number;
    payload: Partial<UpdateCryptoPriceChangeAlertParams>;
  }) => {
    return await patchCryptoPriceChangeAlert(id, payload);
  }
);

// Patch News Alert
export const patchExistingNewsAlert = createAsyncThunk(
  "alerts/patchNewsAlert",
  async ({
    id,
    payload,
  }: {
    id: number;
    payload: Partial<UpdateNewsAlertParams>;
  }) => {
    return await patchNewsAlert(id, payload);
  }
);

// Patch New Listing Alert
export const patchExistingNewListingAlert = createAsyncThunk(
  "alerts/patchNewListingAlert",
  async ({
    id,
    payload,
  }: {
    id: number;
    payload: Partial<UpdateNewListingAlertParams>;
  }) => {
    return await patchNewListingAlert(id, payload);
  }
);

// Remove Crypto Price Change Alert
export const removeCryptoPriceChangeAlert = createAsyncThunk(
  "alerts/removeCryptoPriceChangeAlert",
  async (id: number) => {
    await deleteCryptoPriceChangeAlert(id);
    return id;
  }
);

// Remove News Alert
export const removeNewsAlert = createAsyncThunk(
  "alerts/removeNewsAlert",
  async (id: number) => {
    await deleteNewsAlert(id);
    return id;
  }
);

// Remove New Listing Alert
export const removeNewListingAlert = createAsyncThunk(
  "alerts/removeNewListingAlert",
  async (id: number) => {
    await deleteNewListingAlert(id);
    return id;
  }
);

// Add Weather Alert
export const addWeatherAlert = createAsyncThunk(
  "alerts/addWeatherAlert",
  async (params: CreateWeatherAlertParams) => {
    return await createWeatherAlert(params);
  }
);

// Update Weather Alert
export const updateExistingWeatherAlert = createAsyncThunk(
  "alerts/updateWeatherAlert",
  async ({ id, alert }: { id: number; alert: UpdateWeatherAlertParams }) => {
    return await updateWeatherAlert(id, alert);
  }
);

// Patch Weather Alert
export const patchExistingWeatherAlert = createAsyncThunk(
  "alerts/patchWeatherAlert",
  async ({
    id,
    payload,
  }: {
    id: number;
    payload: Partial<UpdateWeatherAlertParams>;
  }) => {
    return await patchWeatherAlert(id, payload);
  }
);

// Remove Weather Alert
export const removeWeatherAlert = createAsyncThunk(
  "alerts/removeWeatherAlert",
  async (id: number) => {
    await deleteWeatherAlert(id);
    return id;
  }
);

// Add SportsNews Alert
export const addSportsNewsAlert = createAsyncThunk(
  "alerts/addSportsNewsAlert",
  async (params: CreateSportsNewsAlertParams) => {
    return await createSportsNewsAlert(params);
  }
);

// Update SportsNews Alert
export const updateExistingSportsNewsAlert = createAsyncThunk(
  "alerts/updateSportsNewsAlert",
  async ({ id, alert }: { id: number; alert: UpdateSportsNewsAlertParams }) => {
    return await updateSportsNewsAlert(id, alert);
  }
);

// Patch SportsNews Alert
export const patchExistingSportsNewsAlert = createAsyncThunk(
  "alerts/patchSportsNewsAlert",
  async ({
    id,
    payload,
  }: {
    id: number;
    payload: Partial<UpdateSportsNewsAlertParams>;
  }) => {
    return await patchSportsNewsAlert(id, payload);
  }
);

// Remove SportsNews Alert
export const removeSportsNewsAlert = createAsyncThunk(
  "alerts/removeSportsNewsAlert",
  async (id: number) => {
    await deleteSportsNewsAlert(id);
    return id;
  }
);

// Add XNews Alert
export const addXNewsAlert = createAsyncThunk(
  "alerts/addXNewsAlert",
  async (params: CreateXNewsAlertParams) => {
    return await createXNewsAlert(params);
  }
);

// Update XNews Alert
export const updateExistingXNewsAlert = createAsyncThunk(
  "alerts/updateXNewsAlert",
  async ({ id, alert }: { id: number; alert: UpdateXNewsAlertParams }) => {
    return await updateXNewsAlert(id, alert);
  }
);

// Patch XNews Alert
export const patchExistingXNewsAlert = createAsyncThunk(
  "alerts/patchXNewsAlert",
  async ({
    id,
    payload,
  }: {
    id: number;
    payload: Partial<UpdateXNewsAlertParams>;
  }) => {
    return await patchXNewsAlert(id, payload);
  }
);

// Remove XNews Alert
export const removeXNewsAlert = createAsyncThunk(
  "alerts/removeXNewsAlert",
  async (id: number) => {
    await deleteXNewsAlert(id);
    return id;
  }
);

// Async thunks for Event Alert operations
export const addEventAlert = createAsyncThunk(
  "alerts/addEventAlert",
  async (params: CreateEventAlertParams) => {
    return await createEventAlert(params);
  }
);

export const updateExistingEventAlert = createAsyncThunk(
  "alerts/updateEventAlert",
  async ({ id, alert }: { id: number; alert: UpdateEventAlertParams }) => {
    return await updateEventAlert(id, alert);
  }
);

export const patchExistingEventAlert = createAsyncThunk(
  "alerts/patchEventAlert",
  async ({
    id,
    payload,
  }: {
    id: number;
    payload: Partial<UpdateEventAlertParams>;
  }) => {
    return await patchEventAlert(id, payload);
  }
);

export const removeEventAlert = createAsyncThunk(
  "alerts/removeEventAlert",
  async (id: number) => {
    await deleteEventAlert(id);
    return id;
  }
);

const initialState: AlertsState = {
  alerts: [],
  loading: false,
  error: null,
};

// Create the slice
const alertsSlice = createSlice({
  name: "alerts",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch All Alerts
      .addCase(fetchAllAlerts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllAlerts.fulfilled, (state, action) => {
        state.loading = false;
        state.alerts = action.payload.map((alert: any) => ({
          ...alert.data,
          type: alert.alert_type as AlertType,
        }));
      })
      .addCase(fetchAllAlerts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Failed to fetch alerts";
      })
      // Add Crypto Price Change Alert
      .addCase(addCryptoPriceChangeAlert.fulfilled, (state, action) => {
        state.alerts.push({ ...action.payload, type: "crypto_price" });
      })
      // Add News Alert
      .addCase(addNewsAlert.fulfilled, (state, action) => {
        state.alerts.push({ ...action.payload, type: "news" });
      })
      // Add New Listing Alert
      .addCase(addNewListingAlert.fulfilled, (state, action) => {
        state.alerts.push({ ...action.payload, type: "listings" });
      })
      // Add Weather Alert
      .addCase(addWeatherAlert.fulfilled, (state, action) => {
        state.alerts.push({ ...action.payload, type: "weather" });
      })
      // Add SportsNews Alert
      .addCase(addSportsNewsAlert.fulfilled, (state, action) => {
        state.alerts.push({ ...action.payload, type: "sport_news" });
      })
      // Add XNews Alert
      .addCase(addXNewsAlert.fulfilled, (state, action) => {
        state.alerts.push({ ...action.payload, type: "x_news" });
      })
      // Add Event Alert
      .addCase(addEventAlert.fulfilled, (state, action) => {
        state.alerts.push({ ...action.payload, type: "events" });
      })
      // Update Crypto Price Change Alert
      .addCase(
        updateExistingCryptoPriceChangeAlert.fulfilled,
        (state, action) => {
          const index = state.alerts.findIndex(
            (alert) => alert.id === action.payload.id
          );
          if (index !== -1) {
            state.alerts[index] = { ...action.payload, type: "crypto_price" };
          }
        }
      )
      // Update News Alert
      .addCase(updateExistingNewsAlert.fulfilled, (state, action) => {
        const index = state.alerts.findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.alerts[index] = { ...action.payload, type: "news" };
        }
      })
      // Update New Listing Alert
      .addCase(updateExistingNewListingAlert.fulfilled, (state, action) => {
        const index = state.alerts.findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.alerts[index] = { ...action.payload, type: "listings" };
        }
      })
      // Update Weather Alert
      .addCase(updateExistingWeatherAlert.fulfilled, (state, action) => {
        const index = state.alerts.findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.alerts[index] = { ...action.payload, type: "weather" };
        }
      })
      // Update SportsNews Alert
      .addCase(updateExistingSportsNewsAlert.fulfilled, (state, action) => {
        const index = state.alerts.findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.alerts[index] = { ...action.payload, type: "sport_news" };
        }
      })
      // Update XNews Alert
      .addCase(updateExistingXNewsAlert.fulfilled, (state, action) => {
        const index = state.alerts.findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.alerts[index] = { ...action.payload, type: "x_news" };
        }
      })
      // Update Event Alert
      .addCase(updateExistingEventAlert.fulfilled, (state, action) => {
        const index = state.alerts.findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.alerts[index] = { ...action.payload, type: "events" };
        }
      })
      // Patch Crypto Price Change Alert
      .addCase(
        patchExistingCryptoPriceChangeAlert.fulfilled,
        (state, action) => {
          const index = state.alerts.findIndex(
            (alert) => alert.id === action.payload.id
          );
          if (index !== -1) {
            state.alerts[index] = {
              ...state.alerts[index],
              ...action.payload,
              type: "crypto_price",
            };
          }
        }
      )
      // Patch News Alert
      .addCase(patchExistingNewsAlert.fulfilled, (state, action) => {
        const index = state.alerts.findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.alerts[index] = {
            ...state.alerts[index],
            ...action.payload,
            type: "news",
          };
        }
      })
      // Patch New Listing Alert
      .addCase(patchExistingNewListingAlert.fulfilled, (state, action) => {
        const index = state.alerts.findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.alerts[index] = {
            ...state.alerts[index],
            ...action.payload,
            type: "listings",
          };
        }
      })
      // Patch Weather Alert
      .addCase(patchExistingWeatherAlert.fulfilled, (state, action) => {
        const index = state.alerts.findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.alerts[index] = {
            ...state.alerts[index],
            ...action.payload,
            type: "weather",
          };
        }
      })
      // Patch SportsNews Alert
      .addCase(patchExistingSportsNewsAlert.fulfilled, (state, action) => {
        const index = state.alerts.findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.alerts[index] = {
            ...state.alerts[index],
            ...action.payload,
            type: "sport_news",
          };
        }
      })
      // Patch XNews Alert
      .addCase(patchExistingXNewsAlert.fulfilled, (state, action) => {
        const index = state.alerts.findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.alerts[index] = {
            ...state.alerts[index],
            ...action.payload,
            type: "x_news",
          };
        }
      })
      // Patch Event Alert
      .addCase(patchExistingEventAlert.fulfilled, (state, action) => {
        const index = state.alerts.findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.alerts[index] = {
            ...state.alerts[index],
            ...action.payload,
            type: "events",
          };
        }
      })
      // Remove Crypto Price Change Alert
      .addCase(removeCryptoPriceChangeAlert.fulfilled, (state, action) => {
        state.alerts = state.alerts.filter(
          (alert) => alert.id !== action.payload
        );
      })
      // Remove News Alert
      .addCase(removeNewsAlert.fulfilled, (state, action) => {
        state.alerts = state.alerts.filter(
          (alert) => alert.id !== action.payload
        );
      })
      // Remove New Listing Alert
      .addCase(removeNewListingAlert.fulfilled, (state, action) => {
        state.alerts = state.alerts.filter(
          (alert) => alert.id !== action.payload
        );
      })
      // Remove Weather Alert
      .addCase(removeWeatherAlert.fulfilled, (state, action) => {
        state.alerts = state.alerts.filter(
          (alert) => alert.id !== action.payload
        );
      })
      // Remove SportsNews Alert
      .addCase(removeSportsNewsAlert.fulfilled, (state, action) => {
        state.alerts = state.alerts.filter(
          (alert) => alert.id !== action.payload
        );
      })
      // Remove XNews Alert
      .addCase(removeXNewsAlert.fulfilled, (state, action) => {
        state.alerts = state.alerts.filter(
          (alert) => alert.id !== action.payload
        );
      })
      // Remove Event Alert
      .addCase(removeEventAlert.fulfilled, (state, action) => {
        state.alerts = state.alerts.filter(
          (alert) => alert.id !== action.payload
        );
      });
  },
});

// Selectors
export const selectAllAlerts = (state: RootState) => state.alerts.alerts;
export const selectLoading = (state: RootState) => state.alerts.loading;
export const selectError = (state: RootState) => state.alerts.error;

export default alertsSlice.reducer;
