import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { UserType } from '../../types/userTypes';
import { NotificationDetails as NotificationDetailsType } from '../../types/alertTypes';
import { updatePreferences as updatePreferencesApi, updateNotificationDetails as updateNotificationDetailsApi } from '../../api/authService';

// Async thunk for updating user preferences
export const updatePreferencesThunk = createAsyncThunk(
  'user/updatePreferences',
  async (preferences: { [key: string]: any }, { rejectWithValue }) => {
    try {
      const updatedUser = await updatePreferencesApi(preferences);
      return updatedUser;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Failed to update preferences");
    }
  }
);

// Async thunk for updating notification details
export const updateNotificationDetailsThunk = createAsyncThunk(
  'user/updateNotificationDetails',
  async (notificationDetails: Partial<NotificationDetailsType>, { rejectWithValue }) => {
    try {
      const updatedDetails = await updateNotificationDetailsApi(notificationDetails);
      return updatedDetails;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Failed to update notification details");
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null as UserType | null,
    token: null as string | null,
    refresh: null as string | null,
    isLoading: false,
    error: null as string | null,
  },
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.refresh = action.payload.refresh;
      state.isLoading = false;
      state.error = null;
    },
    updateToken: (state, action) => {
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.refresh = null;
      state.isLoading = false;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePreferencesThunk.fulfilled, (state, action) => {
        if (state.user) {
          state.user = action.payload; // Assuming the API returns the full updated user object
        }
      })
      .addCase(updatePreferencesThunk.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(updateNotificationDetailsThunk.fulfilled, (state, action) => {
        if (state.user) {
          state.user.default_notification_details = action.payload; // Assuming the API returns the full updated notification details
        }
      })
      .addCase(updateNotificationDetailsThunk.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});

export const { loginStart, setUser, updateToken, logout, loginFailure } = userSlice.actions;

export default userSlice.reducer;

export const selectUser = (state: any) => state.user.user;
export const selectPreferences = (state: any) => state.user.user?.preferences;
export const selectNotificationDetails = (state: any) =>
  state.user.user?.default_notification_details;