// Importing Axios module
import axios from "./axios";
import {
    CryptoPriceChangeAlert,
    CreateCryptoPriceChangeAlertParams,
    UpdateCryptoPriceChangeAlertParams,
    NewsAlert,
    CreateNewsAlertParams,
    UpdateNewsAlertParams,
    NewListingAlert,
    CreateNewListingAlertParams,
    UpdateNewListingAlertParams,
    WeatherAlert,
    CreateWeatherAlertParams,
    UpdateWeatherAlertParams,
    GeneralAlertWithType,
    UpdateSportsNewsAlertParams,
    SportsNewsAlert,
    XNewsAlert,
    CreateXNewsAlertParams,
    UpdateXNewsAlertParams,
    CreateSportsNewsAlertParams,
    CreateEventAlertParams,
    EventAlert,
    UpdateEventAlertParams} from "../types/alertTypes";

// Create Crypto Price Change Alert
const createCryptoPriceChangeAlert = async (params: CreateCryptoPriceChangeAlertParams): Promise<CryptoPriceChangeAlert> => {
    try {
        const response = await axios.post('alerts/crypto_price_change/', params);
        return response.data;
    } catch (error: any) {
        console.error('Create crypto price change alert error:', error.response?.data);
        throw error;
    }
};

// Retrieve all Crypto Price Change Alerts
const getCryptoPriceChangeAlerts = async (): Promise<CryptoPriceChangeAlert[]> => {
    try {
        const response = await axios.get('alerts/crypto_price_change/');
        return response.data;
    } catch (error: any) {
        console.error('Get crypto price change alerts error:', error.response?.data);
        throw error;
    }
};

// Retrieve a specific Crypto Price Change Alert by ID
const getCryptoPriceChangeAlert = async (id: number): Promise<CryptoPriceChangeAlert> => {
    try {
        const response = await axios.get(`alerts/crypto_price_change/${id}/`);
        return response.data;
    } catch (error: any) {
        console.error('Get crypto price change alert error:', error.response?.data);
        throw error;
    }
};

// Update an existing Crypto Price Change Alert
const updateCryptoPriceChangeAlert = async (id: number, params: UpdateCryptoPriceChangeAlertParams): Promise<CryptoPriceChangeAlert> => {
    try {
        const response = await axios.put(`alerts/crypto_price_change/${id}/`, params);
        return response.data;
    } catch (error: any) {
        console.error('Update crypto price change alert error:', error.response?.data);
        throw error;
    }
};

// Partially update an existing Crypto Price Change Alert
const patchCryptoPriceChangeAlert = async (id: number, payload: Partial<UpdateCryptoPriceChangeAlertParams>): Promise<CryptoPriceChangeAlert> => {
    try {
        const response = await axios.patch(`alerts/crypto_price_change/${id}/`, payload);
        return response.data;
    } catch (error: any) {
        console.error('Patch crypto price change alert error:', error.response?.data);
        throw error;
    }
};

// Delete an existing Crypto Price Change Alert
const deleteCryptoPriceChangeAlert = async (id: number): Promise<void> => {
    try {
        await axios.delete(`alerts/crypto_price_change/${id}/`);
    } catch (error: any) {
        console.error('Delete crypto price change alert error:', error.response?.data);
        throw error;
    }
};

// Create News Alert
const createNewsAlert = async (params: CreateNewsAlertParams): Promise<NewsAlert> => {
    try {
        const response = await axios.post('alerts/news/', params);
        return response.data;
    } catch (error: any) {
        console.error('Create news alert error:', error.response?.data);
        throw error;
    }
};

// Retrieve all News Alerts
const getNewsAlerts = async (): Promise<NewsAlert[]> => {
    try {
        const response = await axios.get('alerts/news/');
        return response.data;
    } catch (error: any) {
        console.error('Get news alerts error:', error.response?.data);
        throw error;
    }
};

// Retrieve a specific News Alert by ID
const getNewsAlert = async (id: number): Promise<NewsAlert> => {
    try {
        const response = await axios.get(`alerts/news/${id}/`);
        return response.data;
    } catch (error: any) {
        console.error('Get news alert error:', error.response?.data);
        throw error;
    }
};

// Update an existing News Alert
const updateNewsAlert = async (id: number, params: UpdateNewsAlertParams): Promise<NewsAlert> => {
    try {
        const response = await axios.put(`alerts/news/${id}/`, params);
        return response.data;
    } catch (error: any) {
        console.error('Update news alert error:', error.response?.data);
        throw error;
    }
};

// Partially update an existing News Alert
const patchNewsAlert = async (id: number, payload: Partial<UpdateNewsAlertParams>): Promise<NewsAlert> => {
    try {
        const response = await axios.patch(`alerts/news/${id}/`, payload);
        return response.data;
    } catch (error: any) {
        console.error('Patch news alert error:', error.response?.data);
        throw error;
    }
};

// Delete an existing News Alert
const deleteNewsAlert = async (id: number): Promise<void> => {
    try {
        await axios.delete(`alerts/news/${id}/`);
    } catch (error: any) {
        console.error('Delete news alert error:', error.response?.data);
        throw error;
    }
};

// Create News Alert
const createSportsNewsAlert = async (params: CreateSportsNewsAlertParams): Promise<SportsNewsAlert> => {
    try {
        const response = await axios.post('alerts/sportsnews/', params);
        return response.data;
    } catch (error: any) {
        console.error('Create news alert error:', error.response?.data);
        throw error;
    }
};

// Retrieve all News Alerts
const getSportsNewsAlerts = async (): Promise<SportsNewsAlert[]> => {
    try {
        const response = await axios.get('alerts/sportsnews/');
        return response.data;
    } catch (error: any) {
        console.error('Get news alerts error:', error.response?.data);
        throw error;
    }
};

// Retrieve a specific News Alert by ID
const getSportsNewsAlert = async (id: number): Promise<SportsNewsAlert> => {
    try {
        const response = await axios.get(`alerts/sportsnews/${id}/`);
        return response.data;
    } catch (error: any) {
        console.error('Get news alert error:', error.response?.data);
        throw error;
    }
};

// Update an existing News Alert
const updateSportsNewsAlert = async (id: number, params: UpdateSportsNewsAlertParams): Promise<SportsNewsAlert> => {
    try {
        const response = await axios.put(`alerts/sportsnews/${id}/`, params);
        return response.data;
    } catch (error: any) {
        console.error('Update news alert error:', error.response?.data);
        throw error;
    }
};

// Partially update an existing News Alert
const patchSportsNewsAlert = async (id: number, payload: Partial<UpdateSportsNewsAlertParams>): Promise<SportsNewsAlert> => {
    try {
        const response = await axios.patch(`alerts/sportsnews/${id}/`, payload);
        return response.data;
    } catch (error: any) {
        console.error('Patch news alert error:', error.response?.data);
        throw error;
    }
};

// Delete an existing News Alert
const deleteSportsNewsAlert = async (id: number): Promise<void> => {
    try {
        await axios.delete(`alerts/sportsnews/${id}/`);
    } catch (error: any) {
        console.error('Delete news alert error:', error.response?.data);
        throw error;
    }
};

// Create New Listing Alert
const createNewListingAlert = async (params: CreateNewListingAlertParams): Promise<NewListingAlert> => {
    try {
        const response = await axios.post('alerts/new_listings/', params);
        return response.data;
    } catch (error: any) {
        console.error('Create new listing alert error:', error.response?.data);
        throw error;
    }
};

// Retrieve all New Listing Alerts
const getNewListingAlerts = async (): Promise<NewListingAlert[]> => {
    try {
        const response = await axios.get('alerts/new_listings/');
        return response.data;
    } catch (error: any) {
        console.error('Get new listing alerts error:', error.response?.data);
        throw error;
    }
};

// Retrieve a specific New Listing Alert by ID
const getNewListingAlert = async (id: number): Promise<NewListingAlert> => {
    try {
        const response = await axios.get(`alerts/new_listings/${id}/`);
        return response.data;
    } catch (error: any) {
        console.error('Get new listing alert error:', error.response?.data);
        throw error;
    }
};

// Update an existing New Listing Alert
const updateNewListingAlert = async (id: number, params: UpdateNewListingAlertParams): Promise<NewListingAlert> => {
    try {
        const response = await axios.put(`alerts/new_listings/${id}/`, params);
        return response.data;
    } catch (error: any) {
        console.error('Update new listing alert error:', error.response?.data);
        throw error;
    }
};

// Partially update an existing New Listing Alert
const patchNewListingAlert = async (id: number, payload: Partial<UpdateNewListingAlertParams>): Promise<NewListingAlert> => {
    try {
        const response = await axios.patch(`alerts/new_listings/${id}/`, payload);
        return response.data;
    } catch (error: any) {
        console.error('Patch new listing alert error:', error.response?.data);
        throw error;
    }
};

// Delete an existing New Listing Alert
const deleteNewListingAlert = async (id: number): Promise<void> => {
    try {
        await axios.delete(`alerts/new_listings/${id}/`);
    } catch (error: any) {
        console.error('Delete new listing alert error:', error.response?.data);
        throw error;
    }
};

// Create Weather Alert
const createWeatherAlert = async (params: CreateWeatherAlertParams): Promise<WeatherAlert> => {
    try {
        const response = await axios.post('alerts/weather/', params);
        return response.data;
    } catch (error: any) {
        console.error('Create weather alert error:', error.response?.data);
        throw error;
    }
};

// Retrieve all Weather Alerts
const getWeatherAlerts = async (): Promise<WeatherAlert[]> => {
    try {
        const response = await axios.get('alerts/weather/');
        return response.data;
    } catch (error: any) {
        console.error('Get weather alerts error:', error.response?.data);
        throw error;
    }
};

// Retrieve a specific Weather Alert by ID
const getWeatherAlert = async (id: number): Promise<WeatherAlert> => {
    try {
        const response = await axios.get(`alerts/weather/${id}/`);
        return response.data;
    } catch (error: any) {
        console.error('Get weather alert error:', error.response?.data);
        throw error;
    }
};

// Update an existing Weather Alert
const updateWeatherAlert = async (id: number, params: UpdateWeatherAlertParams): Promise<WeatherAlert> => {
    try {
        const response = await axios.put(`alerts/weather/${id}/`, params);
        return response.data;
    } catch (error: any) {
        console.error('Update weather alert error:', error.response?.data);
        throw error;
    }
};

// Partially update an existing Weather Alert
const patchWeatherAlert = async (id: number, payload: Partial<UpdateWeatherAlertParams>): Promise<WeatherAlert> => {
    try {
        const response = await axios.patch(`alerts/weather/${id}/`, payload);
        return response.data;
    } catch (error: any) {
        console.error('Patch weather alert error:', error.response?.data);
        throw error;
    }
};

// Delete an existing Weather Alert
const deleteWeatherAlert = async (id: number): Promise<void> => {
    try {
        await axios.delete(`alerts/weather/${id}/`);
    } catch (error: any) {
        console.error('Delete weather alert error:', error.response?.data);
        throw error;
    }
};

// Create XNews Alert
export const createXNewsAlert = async (params: CreateXNewsAlertParams): Promise<XNewsAlert> => {
    try {
      const response = await axios.post('alerts/xnews/', params);
      return response.data;
    } catch (error: any) {
      console.error('Create XNews alert error:', error.response?.data);
      throw error;
    }
  };
  
  // Retrieve all XNews Alerts
  export const getXNewsAlerts = async (): Promise<XNewsAlert[]> => {
    try {
      const response = await axios.get('alerts/xnews/');
      return response.data;
    } catch (error: any) {
      console.error('Get XNews alerts error:', error.response?.data);
      throw error;
    }
  };
  
  // Retrieve a specific XNews Alert by ID
  export const getXNewsAlert = async (id: number): Promise<XNewsAlert> => {
    try {
      const response = await axios.get(`alerts/xnews/${id}/`);
      return response.data;
    } catch (error: any) {
      console.error('Get XNews alert error:', error.response?.data);
      throw error;
    }
  };
  
  // Update an existing XNews Alert
  export const updateXNewsAlert = async (id: number, params: UpdateXNewsAlertParams): Promise<XNewsAlert> => {
    try {
      const response = await axios.put(`alerts/xnews/${id}/`, params);
      return response.data;
    } catch (error: any) {
      console.error('Update XNews alert error:', error.response?.data);
      throw error;
    }
  };
  
  // Partially update an existing XNews Alert
  export const patchXNewsAlert = async (id: number, payload: Partial<UpdateXNewsAlertParams>): Promise<XNewsAlert> => {
    try {
      const response = await axios.patch(`alerts/xnews/${id}/`, payload);
      return response.data;
    } catch (error: any) {
      console.error('Patch XNews alert error:', error.response?.data);
      throw error;
    }
  };

// Delete an existing XNews Alert
export const deleteXNewsAlert = async (id: number): Promise<void> => {
    try {
      await axios.delete(`alerts/xnews/${id}/`);
    } catch (error: any) {
      console.error('Delete XNews alert error:', error.response?.data);
      throw error;
    }
  };

  // Create Event Alert
  export const createEventAlert = async (params: CreateEventAlertParams): Promise<EventAlert> => {
    try {
      const response = await axios.post('alerts/event/', params);
      return response.data;
    } catch (error: any) {
      console.error('Create Event alert error:', error.response?.data);
      throw error;
    }
  };
  
  // Retrieve all Event Alerts
  export const getEventAlerts = async (): Promise<EventAlert[]> => {
    try {
      const response = await axios.get('alerts/event/');
      return response.data;
    } catch (error: any) {
      console.error('Get Event alerts error:', error.response?.data);
      throw error;
    }
  };
  
  // Retrieve a specific Event Alert by ID
  export const getEventAlert = async (id: number): Promise<EventAlert> => {
    try {
      const response = await axios.get(`alerts/event/${id}/`);
      return response.data;
    } catch (error: any) {
      console.error('Get Event alert error:', error.response?.data);
      throw error;
    }
  };
  
  // Update an existing Event Alert
  export const updateEventAlert = async (id: number, params: UpdateEventAlertParams): Promise<EventAlert> => {
    try {
      const response = await axios.put(`alerts/event/${id}/`, params);
      return response.data;
    } catch (error: any) {
      console.error('Update Event alert error:', error.response?.data);
      throw error;
    }
  };
  
  // Partially update an existing Event Alert
  export const patchEventAlert = async (id: number, payload: Partial<UpdateEventAlertParams>): Promise<EventAlert> => {
    try {
      const response = await axios.patch(`alerts/event/${id}/`, payload);
      return response.data;
    } catch (error: any) {
      console.error('Patch Event alert error:', error.response?.data);
      throw error;
    }
  };
  
  // Delete an existing Event Alert
  export const deleteEventAlert = async (id: number): Promise<void> => {
    try {
      await axios.delete(`alerts/event/${id}/`);
    } catch (error: any) {
      console.error('Delete Event alert error:', error.response?.data);
      throw error;
    }
  };
  
// Retrieve all alerts of all types
const getAllAlerts = async (): Promise<GeneralAlertWithType[]> => {
    try {
        const response = await axios.get('alerts/all/');
        // console.log('Alerts:');
        // console.log(response);
        return response.data;
    } catch (error: any) {
        console.error('Get all alerts error:', error.response?.data);
        throw error;
    }
};

// Subscribe to push notifications
const subPushNotifications = async (subscription: any): Promise<any> => {
    try {
        const response = await axios.post('notifier/api/subscribe/', subscription);
        console.log('Subscribe to push notifications response:');
        console.log(response);
        return response;
    } catch (error: any) {
        console.error('Failed to subscribe to push notifications:', error.response?.data);
        throw error;
    }
};

// Exporting all functions
export {
    createCryptoPriceChangeAlert,
    getCryptoPriceChangeAlerts,
    getCryptoPriceChangeAlert,
    updateCryptoPriceChangeAlert,
    patchCryptoPriceChangeAlert,
    deleteCryptoPriceChangeAlert,
    createNewsAlert,
    getNewsAlerts,
    getNewsAlert,
    updateNewsAlert,
    patchNewsAlert,
    deleteNewsAlert,
    createNewListingAlert,
    getNewListingAlerts,
    getNewListingAlert,
    updateNewListingAlert,
    patchNewListingAlert,
    deleteNewListingAlert,
    createWeatherAlert,
    getWeatherAlerts,
    getWeatherAlert,
    updateWeatherAlert,
    patchWeatherAlert,
    deleteWeatherAlert,
    getAllAlerts,
    createSportsNewsAlert,
    updateSportsNewsAlert,
    patchSportsNewsAlert,
    deleteSportsNewsAlert,
    getSportsNewsAlert,
    getSportsNewsAlerts,
    subPushNotifications,
};