import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  RiDatabase2Line,
  RiNotification3Line,
  RiRobotLine,
  RiContactsLine,
  RiNewspaperLine,
  RiCustomerService2Line,
} from "react-icons/ri";

const HomePage: React.FC = () => {
  useEffect(() => {
    const slides = document.querySelectorAll<HTMLElement>(".slick-slide");
    slides.forEach((slide) => {
      slide.style.transform = "scale(0.85)";
      slide.style.opacity = "0.7";
      slide.style.filter = "none"; // Usunięcie rozmycia
      if (slide.classList.contains("slick-center")) {
        slide.style.transform = "scale(1)";
        slide.style.opacity = "1";
      }
    });
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "15%",
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    afterChange: () => {
      const slides = document.querySelectorAll<HTMLElement>(".slick-slide");
      slides.forEach((slide) => {
        slide.style.transform = "scale(0.85)";
        slide.style.opacity = "0.7";
        slide.style.filter = "none"; // Usunięcie rozmycia
        if (slide.classList.contains("slick-center")) {
          slide.style.transform = "scale(1)";
          slide.style.opacity = "1";
        }
      });
    },
  };

  return (
    <div className="bg-[#15061a] text-white min-h-screen">
      <div className="relative">
        <video
          autoPlay
          loop
          muted
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src="SIgnal_flow.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <header className="relative text-center pt-20 pb-10">
          <h1
            className="text-5xl font-bold mb-4"
            style={{ color: "#faf4ff", fontFamily: "Poppins, sans-serif" }}
          >
            Welcome to <span className="text-[#d6336c]">Signal Flow</span>
          </h1>
          <p
            className="max-w-2xl mx-auto text-lg mb-8"
            style={{ color: "#a3a1a8", fontFamily: "Poppins, sans-serif" }}
          >
            Explore how our cutting-edge solutions can transform your digital
            interactions and streamline operations.
          </p>
        </header>

        <div className="relative mb-16 max-w-4xl mx-auto">
          <Slider {...sliderSettings}>
            <div>
              <img
                src="/ban.jpg"
                alt="Slider 1"
                className="rounded-lg"
                style={{ margin: "0 auto", borderRadius: "20px" }}
              />
            </div>
            <div>
              <img
                src="/ban2.jpg"
                alt="Slider 2"
                className="rounded-lg"
                style={{ margin: "0 auto", borderRadius: "20px" }}
              />
            </div>
            <div>
              <img
                src="/ban.jpg"
                alt="Slider 3"
                className="rounded-lg"
                style={{ margin: "0 auto", borderRadius: "20px" }}
              />
            </div>
          </Slider>
        </div>
      </div>

      <main className="max-w-7xl mx-auto p-4 sm:p-8">
        <section className="text-center mb-16">
          <h2
            className="text-3xl font-semibold text-white mb-8"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Why Choose Us?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Column 1 */}
            <div className="text-center mx-auto">
              <RiDatabase2Line className="text-6xl text-white mb-4 mx-auto" />
              <h3
                className="text-xl font-semibold text-white"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Reliable Data
              </h3>
              <p
                className="text-gray-300 mt-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                We ensure consistent and reliable data flow, filtering and
                processing information from trusted sources.
              </p>
            </div>

            {/* Column 2 */}
            <div className="text-center mx-auto">
              <RiNotification3Line className="text-6xl text-white mb-4 mx-auto" />
              <h3
                className="text-xl font-semibold text-white"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Timely Alerts
              </h3>
              <p
                className="text-gray-300 mt-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Stay ahead with real-time alerts on trading prices and newly
                listed opportunities for informed decisions.
              </p>
            </div>

            {/* Column 3 */}
            <div className="text-center mx-auto">
              <RiRobotLine className="text-6xl text-white mb-4 mx-auto" />
              <h3
                className="text-xl font-semibold text-white"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Smart Automation
              </h3>
              <p
                className="text-gray-300 mt-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                High-quality automation and utilization of artificial
                intelligence.
              </p>
            </div>
          </div>
        </section>

        <section className="mb-16">
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-[#1e1134] p-8 rounded-md shadow-md">
              <h3
                className="text-2xl font-semibold"
                style={{ fontFamily: "Poppins, sans-serif", color: "#ffffff" }}
              >
                Get summaries from your selected sources and keywords powered by
                AI
              </h3>
              <p
                className="text-gray-400 mt-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              ></p>
            </div>
            <div className="rounded-md shadow-md">
              <img
                src="/ai.jpeg"
                alt="Summary"
                className="w-full h-full object-cover rounded-md"
              />
            </div>
          </div>
        </section>

        <section className="text-center mb-16">
          <h2
            className="text-3xl font-semibold text-white mb-8"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Connect With Us
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Column 1 */}
            <div className="text-center mx-auto">
              <RiContactsLine className="text-6xl text-white mb-4 mx-auto" />
              <h3
                className="text-xl font-semibold text-white"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Contact Us
              </h3>
              <p
                className="text-gray-300 mt-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Get in touch with our team for any queries or assistance.
              </p>
            </div>

            {/* Column 2 */}
            <div className="text-center mx-auto">
              <RiNewspaperLine className="text-6xl text-white mb-4 mx-auto" />
              <h3
                className="text-xl font-semibold text-white"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Newsroom
              </h3>
              <p
                className="text-gray-300 mt-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Explore the latest news and updates about our products and
                services.
              </p>
            </div>

            {/* Column 3 */}
            <div className="text-center mx-auto">
              <RiCustomerService2Line className="text-6xl text-white mb-4 mx-auto" />
              <h3
                className="text-xl font-semibold text-white"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Support
              </h3>
              <p
                className="text-gray-300 mt-4"
                style={{ fontFamily: "Poppins, sans-serif" }}
              >
                Access our support center for FAQs, user guides, and
                troubleshooting.
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default HomePage;
