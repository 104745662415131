import React, {
  Dispatch,
  SetStateAction,
  useState,
  useRef,
  useEffect,
} from "react";

interface XHandlePickerProps {
  selectedHandles: string[];
  setSelectedHandles: Dispatch<SetStateAction<string[]>>;
}

const topXAccountsForNews:string[] = [
  "nytimes",          // The New York Times
  "BBCWorld",         // BBC News (World)
  "CNN",              // CNN Breaking News
  "Reuters",          // Reuters
  "AP",               // The Associated Press
  "WSJ",              // The Wall Street Journal
  "NPR",              // NPR News
  "guardian",         // The Guardian
  "financialtimes",   // Financial Times
  "washingtonpost",   // The Washington Post
  "ABC",              // ABC News
  "CBSNews",          // CBS News
  "TIME",             // TIME
  "business",         // Bloomberg
  "Forbes",           // Forbes
  "Newsweek",         // Newsweek
  "USATODAY",         // USA Today
  "latimes",          // Los Angeles Times
  "NBCNews",          // NBC News
  "politico",         // Politico
  "TheEconomist",     // The Economist
  "NewYorker",        // The New Yorker
  "VOANews",          // Voice of America
  "AJEnglish",        // Al Jazeera English
  "SkyNews",          // Sky News
  "FRANCE24",         // France 24
  "rtenews",          // RTÉ News (Ireland)
  "CBCNews",          // CBC News (Canada)
  "SBSNews",          // SBS News (Australia)
  "dwnews",           // Deutsche Welle (Germany)
  "RT_com",           // RT (Russia Today)
  "AJENews",          // Al Jazeera News
  "NHKWORLD_News",    // NHK WORLD News (Japan)
  "ABSCBNNews",       // ABS-CBN News (Philippines)
  "the_hindu",        // The Hindu (India)
  "ndtv",             // NDTV (India)
  "CTVNews",          // CTV News (Canada)
  "WIONews",          // WION (India)
  "BBCBreaking",      // BBC Breaking News
  "TRTWorld",         // TRT World (Turkey)
  "cnni",             // CNN International
  "FRANCE24_en",      // France 24 (English)
  "ElPais_English",   // El País (Spain)
  "lemondefr",        // Le Monde (France)
  "itvnews",          // ITV News (UK)
  "afp",              // Agence France-Presse
  "AFPafrica",        // AFP Africa
  "AlArabiya_Eng",    // Al Arabiya English
  "globaltimesnews",  // Global Times (China)
  "XHNews",           // Xinhua News (China)
  "thetimes",         // The Times (UK)
  "RFI_English"       // Radio France Internationale (English)
];

const XHandlePicker = ({
  selectedHandles,
  setSelectedHandles,
}: XHandlePickerProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [handles, setHandles] = useState<string[]>(topXAccountsForNews)
  const [filter, setFilter] = useState<string>("");
  const [customHandle, setCustomHandle] = useState<string>("");

  // Use ref for the dropdown to handle outside clicks
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Handle clicking outside the dropdown to close it
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Toggle the visibility of the dropdown
  const toggleDropdown = (event: any) => {
    event.preventDefault();
    setShowDropdown(!showDropdown);
  };

  // Filter handles based on the search text
  const filteredHandles = handles.filter((handle) =>
    handle.toLowerCase().includes(filter.toLowerCase())
  );

  // Handle selection changes
  const handleHandleChange = (value: string) => {
    setSelectedHandles((prev) =>
      prev.includes(value) ? prev.filter((h) => h !== value) : [...prev, value]
    );
  };

  // Remove a selected handle
  const removeHandle = (value: string) => {
    setSelectedHandles((prev) => prev.filter((h) => h !== value));
  };

  // Clear all selected handles
  const clearAllHandles = () => {
    setSelectedHandles([]);
  };

  // Add a custom handle
  const addCustomHandle = () => {
    if (customHandle.trim() && !selectedHandles.includes(customHandle.trim())) {
      setSelectedHandles((prev) => [...prev, customHandle.trim()]);
    }
    setCustomHandle("");
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex flex-col">
        <button
          type="button"
          onClick={toggleDropdown}
          className="bg-darker-gradient hover:bg-default-gradient text-white font-bold py-2 px-4 rounded flex items-center justify-center"
        >
          Select Twitter Handles
        </button>

        {showDropdown && (
          <div className="absolute z-10 w-full bg-gray-900 text-white border border-gray-700 mt-1 rounded-md shadow-lg max-h-60 overflow-y-auto">
            <input
              type="text"
              placeholder="Search handles..."
              className="p-2 w-full bg-gray-800 text-white border-b border-gray-700"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <div className="flex items-center p-2 bg-gray-800 border-t border-gray-700">
              <input
                type="text"
                placeholder="Enter new handle..."
                className="p-2 w-full bg-gray-700 text-white border rounded"
                value={customHandle}
                onChange={(e) => setCustomHandle(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && addCustomHandle()}
              />
              <button
                type="button"
                onClick={addCustomHandle}
                className="bg-darker-gradient hover:bg-default-gradient text-white p-2 rounded-md hover:bg-blue-600 ml-2"
                >
                Add
              </button>
            </div>

            {filteredHandles.map((handle) => (
              <label
                key={handle}
                className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-800"
              >
                <input
                  type="checkbox"
                  checked={selectedHandles.includes(handle)}
                  onChange={() => handleHandleChange(handle)}
                  className="form-checkbox rounded text-blue-500"
                />
                <span>@{handle}</span>
              </label>
            ))}
          </div>
        )}
      </div>

      <div className="flex flex-wrap gap-2 mt-2">
        {selectedHandles.map((handleValue) => {
          return (
            <div
              key={handleValue}
              className="flex items-center bg-lighter-gradient text-gray-800 text-sm font-bold px-2 py-1 rounded-full"
            >
              @{handleValue}
              <button
                type="button"
                onClick={() => removeHandle(handleValue)}
                className=" ml-1 rounded-full px-2 hover:bg-gray-800 hover:text-white"
              >
                <span className="text-xl font-bold">&times;</span>
              </button>
            </div>
          );
        })}
        {selectedHandles.length > 0 && (
          <button
            type="button"
            onClick={clearAllHandles}
            className="text-gray-400 text-xs underline"
          >
            Clear All
          </button>
        )}
      </div>
    </div>
  );
};

export default XHandlePicker;
