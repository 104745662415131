// Utility functions (include necessary functions from previous parts)
function luminance(rgb: any) {
    let [r, g, b] = rgb.map((v: any) => v / 255.0);
    r = r <= 0.03928 ? r / 12.92 : ((r + 0.055) / 1.055) ** 2.4;
    g = g <= 0.03928 ? g / 12.92 : ((g + 0.055) / 1.055) ** 2.4;
    b = b <= 0.03928 ? b / 12.92 : ((b + 0.055) / 1.055) ** 2.4;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

function contrastRatio(rgb1: any, rgb2 = [255, 255, 255]) {
    const lum1 = luminance(rgb1);
    const lum2 = luminance(rgb2);
    const ratio = lum1 > lum2 ? (lum1 + 0.05) / (lum2 + 0.05) : (lum2 + 0.05) / (lum1 + 0.05);
    return ratio;
}

function isDarkEnough(rgb: any, threshold = 4.5) {
    const white = [255, 255, 255];
    return contrastRatio(rgb, white) >= threshold;
}

function generateCandidateColors(n = 100) {
    const candidates = [];
    for (let i = 0; i < n; i++) {
        const h = Math.random();
        const s = Math.random() * 0.5 + 0.5;
        const v = Math.random() * 0.2 + 0.2;
        candidates.push(hsvToRgb(h, s, v));
    }
    return candidates;
}

function hsvToRgb(h: any, s: any, v: any) {
    let r, g, b;
    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);

    switch (i % 6) {
        case 0:
            r = v; g = t; b = p;
            break;
        case 1:
            r = q; g = v; b = p;
            break;
        case 2:
            r = p; g = v; b = t;
            break;
        case 3:
            r = p; g = q; b = v;
            break;
        case 4:
            r = t; g = p; b = v;
            break;
        case 5:
            r = v; g = p; b = q;
            break;
        default:
            r = g = b = 0; // Default case to avoid uninitialized variables
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export function findMostDistinctColor(existingColors: any, nCandidates = 1000) {
    const candidates = generateCandidateColors(nCandidates);
    let bestCandidate = null;
    let bestScore = -1;

    candidates.forEach(candidate => {
        if (!isDarkEnough(candidate)) {
            return;
        }

        const minDistance = Math.min(...existingColors.map((color: any)=> Math.sqrt(candidate.reduce((acc, val, i) => acc + (val - color[i]) ** 2, 0))));

        if (minDistance > bestScore) {
            bestScore = minDistance;
            bestCandidate = candidate;
        }
    });

    return bestCandidate;
}