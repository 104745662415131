import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { Line } from "react-chartjs-2";
import generateHourlyChartData, {
  NotificationData,
  AlertType,
} from "./DatasetGenerator";
import { getNotificationData } from "../../api/notificationService";
import animationData from "../../animations/loading_charts.json";

interface ChartComponentProps {
  filter: { alert_types?: AlertType[]; alert_ids?: number[] };
}

const ChartComponent: React.FC<ChartComponentProps> = ({ filter }) => {
  const [data, setData] = useState<NotificationData[] | null>(null);
  const [chartData, setChartData] = useState<any>(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    getNotificationData().then((data: any) => {
      console.log(
        "alert data: -----------------------------------------------------"
      );
      setData(data.alerts);
      const generatedData = generateHourlyChartData(
        data.alerts,
        filter.alert_ids
          ? filter
          : {
              alert_types: filter.alert_types,
              alert_ids: data.alerts.map((obj: any) => obj.alert_id),
            }
      );
      setChartData(generatedData);
    });
  }, []);

  useEffect(() => {
    if (data) {
      const generatedData = generateHourlyChartData(
        data,
        filter.alert_ids
          ? filter
          : {
              alert_types: filter.alert_types,
              alert_ids: data.map((obj: any) => obj.alert_id),
            }
      );
      setChartData(generatedData);
      console.log(
        "CHART data: -----------------------------------------------------"
      );
      console.log(generatedData);
    }
    console.log("Filters: ");
    console.log(filter);
  }, [filter]);

  return (
    <div className="p-4">
      {chartData ? (
        <Line data={chartData} options={{ responsive: true }} />
      ) : (
        <div className="my-auto">
          <Lottie options={defaultOptions} height={300} width={300} />
        </div>
      )}
    </div>
  );
};

export default ChartComponent;
