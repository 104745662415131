// LatestData.tsx
import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { fetchNewsAndListings, fetchEventsAndXPosts, fetchCryptoPriceTicks } from "../../api/scraperDataService";
import { News, NewListing, Event, XPost, CryptoPriceTick } from "../../types/scraperDataTypes";
import 'chart.js/auto';

const LatestData = ({
    sources,
    type,
    limitOrPair,
}: {
    sources: string[];
    type: "news" | "listings" | "events" | "x_posts" | "crypto_price";
    limitOrPair: number | string;
}) => {
    const [news, setNews] = useState<News[]>([]);
    const [newListings, setNewListings] = useState<NewListing[]>([]);
    const [events, setEvents] = useState<Event[]>([]);
    const [xPosts, setXPosts] = useState<XPost[]>([]);
    const [cryptoPrice, setCryptoPrice] = useState<CryptoPriceTick[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            try {
                switch (type) {
                    case "news":
                    case "listings": {
                        const data = await fetchNewsAndListings(sources, limitOrPair as number);
                        setNews(data.news);
                        setNewListings(data.new_listings);
                        break;
                    }
                    case "events":
                    case "x_posts": {
                        const data = await fetchEventsAndXPosts(sources[0], sources, limitOrPair as number, limitOrPair as number);
                        setEvents(data.events);
                        setXPosts(data.x_posts);
                        break;
                    }
                    case "crypto_price": {
                        const data = await fetchCryptoPriceTicks(limitOrPair as string);
                        setCryptoPrice(data);
                        break;
                    }
                    default:
                        break;
                }
                setError(null);
            } catch (error: any) {
                setError("Failed to fetch data.");
                console.error(error);
            }
            setIsLoading(false);
        };

        if (sources && limitOrPair) {
            loadData();
        }
    }, [sources, type, limitOrPair]);

    const renderNews = () => (
        <ul>
            {news.map((item) => (
                <li key={item.id} className="mt-4 p-4 bg-gray-700 rounded-lg shadow-md">
                    <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-lg font-medium bg-clip-text text-transparent bg-lighter-gradient hover:bg-default-gradient">
                        {item.title}
                    </a>
                    <p className="text-sm text-gray-200">
                        From {item.source} on {new Date(item.pub_date).toLocaleDateString()}
                    </p>
                </li>
            ))}
        </ul>
    );

    const renderListings = () => (
        <ul>
            {newListings.map((item) => (
                <li key={item.id} className="mt-4 p-4 bg-gray-700 rounded-lg shadow-md">
                    <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-lg font-medium bg-clip-text text-transparent bg-lighter-gradient hover:bg-default-gradient">
                        {item.title}
                    </a>
                    <p className="text-sm text-gray-200">
                        From {item.source} on {new Date(item.pub_date).toLocaleDateString()}
                    </p>
                </li>
            ))}
        </ul>
    );

    const renderEvents = () => (
        <ul>
            {events.map((event) => (
                <li key={event.id} className="mt-4 p-4 bg-gray-700 rounded-lg shadow-md">
                    <a href={event.url} target="_blank" rel="noopener noreferrer" className="text-lg font-medium bg-clip-text text-transparent bg-lighter-gradient hover:bg-default-gradient">
                        {event.name}
                    </a>
                    <p className="text-sm text-gray-200">
                        At {event.location}, starting on {new Date(event.start_time).toLocaleDateString()}
                    </p>
                    </li>
            ))}
        </ul>
    );

    const renderXPosts = () => (
        <ul>
            {xPosts.map((post) => (
                <li key={post.id} className="mt-4 p-4 bg-gray-700 rounded-lg shadow-md">
                    <p className="text-lg font-medium bg-clip-text text-transparent bg-lighter-gradient hover:bg-default-gradient">
                        {post.profile_name}: {post.content}
                    </p>
                    <p className="text-sm text-gray-200">
                        Posted on {new Date(post.timestamp).toLocaleDateString()} | Likes: {post.likes}, Reposts: {post.reposts}, Replies: {post.replies}
                    </p>
                </li>
            ))}
        </ul>
    );

    const renderCryptoPriceChart = () => {
        const prices = cryptoPrice.map((tick) => tick.price);
        const labels = cryptoPrice.map((tick) => new Date(tick.created).toLocaleTimeString());

        const data = {
            labels,
            datasets: [
                {
                    label: `Price over time (${limitOrPair})`,
                    data: prices,
                    borderColor: "#4caf50",
                    backgroundColor: "rgba(76, 175, 80, 0.1)",
                    fill: true,
                },
            ],
        };

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    position: "top" as const,
                },
                title: {
                    display: true,
                    text: `Crypto Price Chart: ${limitOrPair}`,
                },
            },
        };

        return <Line data={data} options={options} />;
    };

    const renderContent = () => {
        switch (type) {
            case "news":
                return renderNews();
            case "listings":
                return renderListings();
            case "events":
                return renderEvents();
            case "x_posts":
                return renderXPosts();
            case "crypto_price":
                return renderCryptoPriceChart();
            default:
                return null;
        }
    };

    return (
        <div className='mt-2 text-sm border-t pt-2'>
            {isLoading ? (
                <p className="text-gray-600">Loading...</p>
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <>
                    {(news.length > 0 || newListings.length > 0 || events.length > 0 || xPosts.length > 0 || cryptoPrice.length > 0) && (
                        <h2 className="text-xl font-semibold">Latest scraped data:</h2>
                    )}
                    {renderContent()}
                </>
            )}
        </div>
    );
};

export default LatestData;