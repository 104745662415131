import React, { useState, useEffect } from 'react';
import { NotificationDetails } from '../../../types/alertTypes';
import { useAppSelector } from '../../../app/store';
import { selectUser } from '../../../features/user/userSlice';

export const defaultNotificationDetails: NotificationDetails ={
    browser: true,
    push_notifications: false,
    email: "",
    sms: "",
    whatsapp: "",
    telegram: "",
    slack: "",
    facebook_messenger: "",
    signal: "",
    viber: "",
    wechat: "",
    skype: "",
    line: "",
    linkedin: "",
    twitter: "",
    instagram: "",
    discord_webhook_url: "",
    teams_webhook_url: "",
  } 
interface NotificationPreferencesProps {
    notificationDetails: NotificationDetails;
    setNotificationDetails: (details: NotificationDetails) => void;
}

const NotificationDetailsEditor: React.FC<NotificationPreferencesProps> = ({ notificationDetails, setNotificationDetails }) => {
    const user = useAppSelector(selectUser);
    const defaultNotificationDetails = user.default_notification_details || {};

    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    // Email Validation
    const validateEmail = (email: string) => {
        if (email && !/^\S+@\S+\.\S+$/.test(email)) {
            return 'Enter a valid email address.';
        }
        return '';
    };

    // Phone Number Validation for SMS, WhatsApp, Signal, Viber
    const validatePhone = (phone: string) => {
        if (phone && !/^\+?1?\d{9,15}$/.test(phone)) {
            return "Enter a valid phone number. Format: '+999999999'. Up to 15 digits allowed.";
        }
        return '';
    };

    // Telegram Username Validation
    const validateTelegram = (username: string) => {
        if (username && (username.length < 30 || username.length > 60)) {
            return "Telegram username must be 30-60 characters long.";
        }
        return '';
    };

    // General String Length Validation
    const validateLength = (text: string, max: number, fieldName: string) => {
        if (text.length > max) {
            return `${fieldName} can be up to ${max} characters long.`;
        }
        return '';
    };

    // Twitter Handle Validation
    const validateTwitter = (handle: string) => {
        if (handle && (!/^[A-Za-z0-9_]+$/.test(handle) || handle.length > 15)) {
            return "Twitter handle can be up to 15 characters long. Only letters, numbers, and underscores are allowed.";
        }
        return '';
    };

    // Instagram Username Validation
    const validateInstagram = (username: string) => {
        if (username && (!/^[A-Za-z0-9_.]+$/.test(username) || username.length > 30)) {
            return "Instagram username can be up to 30 characters long. Only letters, numbers, underscores, and periods are allowed.";
        }
        return '';
    };

    // URL Validation for Discord Webhook URLs
    const validateURL = (url: string) => {
        try {
            new URL(url);
        } catch (e) {
            return "Enter a valid URL.";
        }
        return '';
    };

    const validateField = (name: keyof NotificationDetails, value: string): string => {
        switch (name) {
            case 'email': return validateEmail(value);
            case 'sms':
            case 'whatsapp':
            case 'signal':
            case 'viber': return validatePhone(value);
            case 'telegram': return validateTelegram(value);
            case 'slack':
            case 'facebook_messenger':
            case 'wechat':
            case 'skype':
            case 'linkedin':
            case 'discord_webhook_url': return validateLength(value, 255, "Field");
            case 'line':
            case 'twitter': return validateTwitter(value);
            case 'instagram': return validateInstagram(value);
            default: return '';
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
        if (type === 'checkbox' && event.target.classList.contains('toggle-checkbox')) {
            handleToggleField(name as keyof NotificationDetails);
        } else if (type === 'checkbox') {
            setNotificationDetails({
                ...notificationDetails,
                [name]: checked,
            });
        } else {
            setNotificationDetails({
                ...notificationDetails,
                [name]: value,
            });
            // Validate the field and update errors state
            const error = validateField(name as keyof NotificationDetails, value);
            setErrors({ ...errors, [name]: error });
        }
    };

    const handleToggleField = (name: keyof NotificationDetails) => {
        const isActive = !!notificationDetails[name];
        const placeholder_true: any = (name === 'browser' || name === 'push_notifications') ? true : `<your ${name}>`;
        const placeholder_false: any = (name === 'browser' || name === 'push_notifications') ? false : ``;
        const newValue = isActive ? placeholder_false : (defaultNotificationDetails[name] || placeholder_true);
        setNotificationDetails({
            ...notificationDetails,
            [name]: newValue
        });
        // Clear errors when deactivating a field
        if (isActive) {
            const newErrors = { ...errors };
            delete newErrors[name];
            setErrors(newErrors);
        } else {
            // Validate the field when activating it and update errors state
            const error = validateField(name, newValue as string);
            setErrors({ ...errors, [name]: error });
        }
    };

    // useEffect(() => {
    //     console.log(notificationDetails);
    // }, [notificationDetails]);

    return (
        <div className="space-y-4 mt-2 overflow-hidden">
            <div className="max-h-60 overflow-y-auto bg-gray-900 border border-gray-700 rounded-md p-4 space-y-4">
                {[
                    { name: 'browser', label: 'Browser Notifications', placeholder: '', type: 'boolean' },
                    { name: 'push_notifications', label: 'Push Notifications', placeholder: '', type: 'boolean' },
                    { name: 'email', label: 'Email', placeholder: 'your-email@example.com', type: 'string' },
                    { name: 'sms', label: 'SMS', placeholder: 'Your phone number', type: 'string' },
                    { name: 'telegram', label: 'Telegram', placeholder: 'Your Telegram bot token', type: 'string' },
                    { name: 'discord_webhook_url', label: 'Discord', placeholder: 'Discord webhook URL', type: 'string' },
                    { name: 'slack', label: 'Slack', placeholder: 'Your Slack webhook URL', type: 'string' },
                    // { name: 'whatsapp', label: 'WhatsApp', placeholder: 'Your WhatsApp number', type: 'string' },
                    // { name: 'facebook_messenger', label: 'Facebook Messenger', placeholder: 'Messenger ID', type: 'string' },
                    // { name: 'signal', label: 'Signal', placeholder: 'Your Signal number', type: 'string' },
                    // { name: 'viber', label: 'Viber', placeholder: 'Your Viber number', type: 'string' },
                    // { name: 'wechat', label: 'WeChat', placeholder: 'Your WeChat ID', type: 'string' },
                    // { name: 'skype', label: 'Skype', placeholder: 'Your Skype ID', type: 'string' },
                    // { name: 'line', label: 'LINE', placeholder: 'Your LINE ID', type: 'string' },
                    // { name: 'linkedin', label: 'LinkedIn', placeholder: 'Your LinkedIn URL', type: 'string' },
                    // { name: 'twitter', label: 'Twitter', placeholder: 'Your Twitter handle', type: 'string' },
                    // { name: 'instagram', label: 'Instagram', placeholder: 'Your Instagram username', type: 'string' },
                    // { name: 'teams_webhook_url', label: 'Microsoft Teams', placeholder: 'Teams webhook URL', type: 'string' },
                ].map(({ name, label, placeholder, type }) => {
                    const fieldName = name as keyof NotificationDetails;
                    const isActive = !!notificationDetails[fieldName];
                    const error = errors[name];

                    return (
                        <div key={name} className="flex flex-col space-y-2">
                            <div className="flex items-center space-x-4">
                                <label className="flex items-center cursor-pointer">
                                    <div className="relative">
                                        <input
                                            type="checkbox"
                                            name={name}
                                            className="sr-only toggle-checkbox"
                                            checked={isActive}
                                            onChange={handleInputChange}
                                        />
                                        <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                                        <div
                                            className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
                                                isActive ? 'translate-x-full border-gray-300' : 'border-gray-400'
                                            }`}
                                            style={isActive ? { transform: 'translateX(100%)' } : {}}
                                        ></div>
                                    </div>
                                    <div className="ml-3 text-sm font-medium text-gray-300">
                                        {label}
                                    </div>
                                </label>
                                {/* Conditionally render input for string types only */}
                                {type === 'string' && (
                                    <input
                                        type="text"
                                        name={name}
                                        value={isActive ? notificationDetails[fieldName] as string : ''}
                                        onChange={handleInputChange}
                                        placeholder={placeholder}
                                        className={`form-input mt-1 block w-full ${
                                            isActive ? 'bg-gray-800 text-white' : 'bg-gray-700 cursor-not-allowed text-gray-500'
                                        } border ${
                                            isActive ? 'border-gray-600' : 'border-gray-700'
                                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                        disabled={!isActive}
                                    />
                                )}
                            </div>
                            {/* Display validation error if it exists */}
                            {error && (
                                <p className="text-sm text-red-500">{error}</p>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default NotificationDetailsEditor;
