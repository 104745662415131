import React from 'react';
import Eye from './RobotEye';

interface RobotFaceProps {
    imageUrl: string;
    width: number;
    height: number;
  }
  
  const RobotFace: React.FC<RobotFaceProps> = ({ imageUrl, width, height }) => {
    const faceWidth = width;
    const faceHeight = height;
    const eyeRadius = 18; // Adjust as needed
  
    // Adjust these based on where you want to place the eyes on your image
    const leftEyePosition = { cx: width * 0.37, cy: height * 0.57 };
    const rightEyePosition = { cx: width * 0.72, cy: height * 0.57 };
  
    return (
      <div className="relative flex justify-center items-center w-full h-full">
        <img src={imageUrl} alt="Robot Face" className=" w-full h-full" />
        <svg width={faceWidth} height={faceHeight} viewBox={`0 0 ${faceWidth} ${faceHeight}`} className="absolute top-0 left-0">
          {/* Eyes */}
          <Eye cx={leftEyePosition.cx} cy={leftEyePosition.cy} baseRadius={eyeRadius} />
          <Eye cx={rightEyePosition.cx} cy={rightEyePosition.cy} baseRadius={eyeRadius} />
        </svg>
      </div>
    );
  };

export default RobotFace;