import React, { useState, useRef, useEffect } from "react";

interface TooltipProps {
  message: string;
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ message, children }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div
          className="absolute bg-gray-800 text-white p-5 rounded-md shadow-lg mt-2 ml-2 max-w-xl z-10"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
