import React from 'react';
import { FaYoutube } from 'react-icons/fa';

interface ScheduledAction {
    id: string;
    date: string; // ISO date string
    time: string; // Time string (e.g., "14:30")
    prompt: string;
    workflow: string;
    audience: string;
    progress: number; // 0-100
    color: string; // CSS color string
  }
  
  // Type for the scheduledActions object
  interface ScheduledActionsMap {
    [date: string]: ScheduledAction[];
  }
  
  // Props for the TaskTile component
  interface ActionTileProps {
    task: ScheduledAction;
  }

const ActionTile: React.FC<ActionTileProps> = ({ task }) => {
  return (
    <div className="task-tile" style={{ backgroundColor: task.color }}>
      <div className="task-content">
        <div className="task-icon">
          <FaYoutube />
        </div>
        <div className="task-description">{task.prompt}...</div>
      </div>
      <div className="task-progress">
        <div className="progress-bar" style={{ width: `${task.progress}%` }}></div>
      </div>
    </div>
  );
};

export default ActionTile;