export type AlertType = 'newsalert' | 'newlistingalert' | 'pricealert';

export interface NotificationData {
    alert_type: AlertType;
    alert_id: number;
    data: {
        [channel: string]: number[];
    };
}

interface ChartDataset {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
    hidden: boolean;
}

interface ChartData {
    labels: string[];
    datasets: ChartDataset[];
}

interface AlertTypeColor {
    borderColor: string;
    backgroundColor: string;
}

const channelColors: { [key: string]: { borderColor: string, backgroundColor: string } } = {
    browser: { borderColor: "#8465B9", backgroundColor: "rgba(132, 101, 185, 0.2)" }, // Toned Down Medium Purple
    push_notifications: { borderColor: "#D4AF37", backgroundColor: "rgba(212, 175, 55, 0.2)" }, // Toned Down Gold
    email: { borderColor: "#C65C5C", backgroundColor: "rgba(198, 92, 92, 0.2)" }, // Toned Down Indian Red
    sms: { borderColor: "#D1A355", backgroundColor: "rgba(209, 163, 85, 0.2)" }, // Toned Down GoldenRod
    whatsapp: { borderColor: "#7FBF7F", backgroundColor: "rgba(127, 191, 127, 0.2)" }, // Toned Down Dark Sea Green
    telegram: { borderColor: "#1A9B97", backgroundColor: "rgba(26, 155, 151, 0.2)" }, // Toned Down Light Sea Green
    slack: { borderColor: "#5A8E8E", backgroundColor: "rgba(90, 142, 142, 0.2)" }, // Toned Down Cadet Blue
    facebook_messenger: { borderColor: "#1A76D1", backgroundColor: "rgba(26, 118, 209, 0.2)" }, // Toned Down Dodger Blue
    signal: { borderColor: "#DB7093", backgroundColor: "rgba(219, 112, 147, 0.2)" }, // Toned Down Hot Pink
    viber: { borderColor: "#D93C00", backgroundColor: "rgba(217, 60, 0, 0.2)" }, // Toned Down OrangeRed
    wechat: { borderColor: "#8465B9", backgroundColor: "rgba(132, 101, 185, 0.2)" }, // Toned Down Medium Purple
    skype: { borderColor: "#2E9E71", backgroundColor: "rgba(46, 158, 113, 0.2)" }, // Toned Down Medium Sea Green
    line: { borderColor: "#D4AF37", backgroundColor: "rgba(212, 175, 55, 0.2)" }, // Toned Down Gold
    linkedin: { borderColor: "#A75373", backgroundColor: "rgba(167, 83, 115, 0.2)" }, // Toned Down Medium Violet Red
    twitter: { borderColor: "#3E7CA6", backgroundColor: "rgba(62, 124, 166, 0.2)" }, // Toned Down Steel Blue
    instagram: { borderColor: "#B86123", backgroundColor: "rgba(184, 97, 35, 0.2)" }, // Toned Down Chocolate
    discord_webhook_url: { borderColor: "#763FB2", backgroundColor: "rgba(118, 63, 178, 0.2)" }, // Toned Down Blue Violet
    teams_webhook_url: { borderColor: "#355CA8", backgroundColor: "rgba(53, 92, 168, 0.2)" }, // Toned Down Royal Blue
    default: { borderColor: "#A11A1A", backgroundColor: "rgba(161, 26, 26, 0.2)" }, // Toned Down Firebrick
    unknown: { borderColor: "#8B1D1D", backgroundColor: "rgba(139, 29, 29, 0.2)" }, // Toned Down Brown
};

interface Preferences {
    timeFormat: '24hr' | '12hr';
}

const generateHourlyLabels = (preferences: Preferences): string[] => {
    const labels = [];
    const now = new Date();
    for (let i = 1; i <= 168; i++) {
        const date = new Date(now.getTime() - (168 - i) * 60 * 60 * 1000);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        
        let hour = date.getHours();
        let labelHour;
        let ampm = '';
        
        if (preferences.timeFormat === '12hr') {
            ampm = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12;
            hour = hour ? hour : 12; // the hour '0' should be '12'
            labelHour = `${String(hour).padStart(2, '0')} ${ampm}`;
        } else {
            labelHour = String(hour).padStart(2, '0') + ':00';
        }
        
        labels.push(`${month}-${day} ${labelHour}`);
    }
    return labels;
};


export const generateHourlyChartData = (
    notificationData: NotificationData[],
    filters: { alert_types?: AlertType[]; alert_ids?: number[] }
): ChartData => {
    const datasets: { [channel: string]: number[] } = {};

    notificationData.forEach(alert => {
        if (
            (!filters.alert_types || filters.alert_types.includes(alert.alert_type)) &&
            (!filters.alert_ids || filters.alert_ids.includes(alert.alert_id))
        ) {
            Object.keys(alert.data).forEach(channel => {
                if (!datasets[channel]) {
                    datasets[channel] = Array(168).fill(0);
                }
                datasets[channel] = datasets[channel].map((value, index) => value + alert.data[channel][index]);
            });
        }
    });

    const chartDatasets: ChartDataset[] = Object.keys(datasets).map(channel => {
        const data = datasets[channel];
        const sum = data.reduce((a, b) => a + b, 0);
        return sum > 0 ? {
            label: channel,
            data,
            borderColor: channelColors[channel]?.borderColor || channelColors.default.borderColor,
            backgroundColor: channelColors[channel]?.backgroundColor || channelColors.default.backgroundColor,
            hidden: false,
        } : null;
    }).filter(Boolean) as ChartDataset[];

    const hours = generateHourlyLabels({timeFormat: '12hr'});

    if (chartDatasets.length === 0) {
        return {
            labels: ["No Data Yet"],
            datasets: [],
        };
    }

    return {
        labels: hours,
        datasets: chartDatasets,
    };
};

export default generateHourlyChartData;
