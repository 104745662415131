import React, { useState, useRef } from "react";
import {
  RiNewspaperLine,
  RiFileWordLine,
  RiDatabaseFill,
  RiFileListLine,
  RiAlarmWarningLine,
  RiWhatsappFill,
  RiMailSendFill,
  RiTwitterXLine,
  RiMenuFill,
  RiCloseFill,
  RiNotificationLine,
  RiTaskLine,
  RiInstagramLine,
  RiPlaneLine,
  RiExchangeDollarLine,
  RiAlertLine,
  RiShareForwardLine,
  RiFlightTakeoffLine,
  RiStockLine,
  RiTwitterLine,
  RiSaveLine,
  RiFacebookLine,
} from "react-icons/ri";
import { useAppSelector } from "../app/store";
import { motion } from "framer-motion";
import ChartComponent from "../components/charts/NotificationChart";
import RobotFace from "../components/fun/RobotFace";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";

// Register all components you intend to use
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);
ChartJS.register(CategoryScale);

enum TabKey {
  WHATSAPP = "WhatsApp",
  X = "X",
  EMAIL = "Email",
  NEWS = "NEWS",
  TRADING = "TRADING",
}

const options = {
  scales: {
    x: {
      type: "category",
    },
  },
};

const socialPlatforms = ["Twitter", "Facebook", "Instagram"];
type SocialPlatform = (typeof socialPlatforms)[number];
type PublishSettings = Record<SocialPlatform, boolean>;

const DashboardPage: React.FC = () => {
  const user = useAppSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState<TabKey>(TabKey.WHATSAPP);
  const [chartView, setChartView] = useState("monthly");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showChart, setShowChart] = useState(true);
  const canvasRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [filters, setFilters] = useState<{
    alert_types?: string[];
    alert_ids?: number[];
  }>({
    alert_types: ["newsalert"],
  });
  const [publishSettings, setPublishSettings] = useState<PublishSettings>({
    Twitter: false,
    Facebook: false,
    Instagram: false,
  });

  const handleTypeFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    setFilters((prevFilters) => {
      const newAlertTypes = checked
        ? [...(prevFilters.alert_types || []), value]
        : (prevFilters.alert_types || []).filter((type) => type !== value);
      return { ...prevFilters, alert_types: newAlertTypes };
    });
  };

  const handlePublishSettingsChange = (platform: string) => {
    setPublishSettings((prev) => ({
      ...prev,
      [platform]: !prev[platform],
    }));
  };

  const handleSaveAutomation = () => {
    alert("Automation settings saved!");
  };

  return (
    <div className="relative flex h-screen  bg-gray-900 text-white">
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      >
        <source src="SIgnal_flow.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {/* Sidebar */}
      <motion.div
        className={`mt-16 fixed lg:relative z-20 lg:z-auto transition-all duration-500 ${
          sidebarOpen ? "left-0" : "-left-full"
        } lg:left-0 bg-gray-800 shadow-md h-screen lg:h-auto lg:w-64`}
      >
        <div className="flex flex-col space-y-2 p-4">
          <button
            className="lg:hidden self-end mb-2 p-2 bg-gray-700 rounded-md"
            onClick={() => setSidebarOpen(false)}
          >
            <RiCloseFill size={24} />
          </button>
          <div className="text-lg font-bold bg-darker-gradient p-2 rounded-md">
            Menu
          </div>
          <button
            className="flex items-center p-2 rounded-md hover:bg-gray-700"
            onClick={() => {
              setShowChart(true);
              setSidebarOpen(false);
            }}
          >
            <RiNotificationLine className="inline-block mr-2 text-xl" />
            Notifications
          </button>
          <button
            className="flex items-center p-2 rounded-md hover:bg-gray-700"
            onClick={() => {
              setShowChart(false);
              setSidebarOpen(false);
            }}
          >
            <RiTaskLine className="inline-block mr-2 text-xl" />
            Actions
          </button>
          <div className="text-lg font-bold bg-gradient-to-r from-purple-500 to-blue-600 p-2 rounded-md mt-4">
            Filter Alerts
          </div>
          <div className="p-2">
            <div className="text-sm font-semibold text-gray-400 mb-2">
              Alert Types
            </div>
            {["newsalert", "newlistingalert", "pricealert"].map((type) => (
              <div key={type} className="flex items-center mb-2">
                <label className="toggle">
                  <input
                    type="checkbox"
                    id={type}
                    name="alert_types"
                    value={type}
                    checked={filters.alert_types?.includes(type)}
                    onChange={handleTypeFilterChange}
                  />
                  <span className="toggle-slider"></span>
                </label>
                <label htmlFor={type} className="ml-2 text-sm text-gray-300">
                  {type}
                </label>
              </div>
            ))}
          </div>
        </div>
      </motion.div>

      {/* Main Content Area */}
      <div className="flex-grow p-4 overflow-y-auto mt-16 z-20">
        <div className="flex justify-between items-center mb-4">
          <button
            className="p-2 bg-gray-800 rounded-md lg:hidden"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            {sidebarOpen ? <RiCloseFill size={30} /> : <RiMenuFill size={30} />}
          </button>
          <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-default-gradient">
            Dashboard
          </h1>
          <div className="w-30"></div>
        </div>

        {showChart ? (
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            {/* Chart Area */}
            <div
              className="bg-gray-800 p-4 rounded-md shadow"
              style={{ height: "75vh" }}
            >
              <div className="text-center mb-4">
                <h2 className="text-lg text-gray-400">
                  After creating alerts, a chart with the number and type of
                  notifications will appear here.
                </h2>
                <ChartComponent filter={{ alert_types: ["newsalert"] }} />
              </div>
            </div>
          </motion.div>
        ) : (
          <div className="bg-gray-900 text-white min-h-screen">
            <main className="max-w-7xl mx-auto p-6">
              <motion.div
                className="text-center mb-12"
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
              >
                <h2 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-blue-500 to-purple-600">
                  Actions
                </h2>
                <p className="text-lg mt-4">
                  Welcome to the action and automation zone. Below, you'll find
                  tools and automations for social media, exchanges, and much
                  more.
                </p>
              </motion.div>

              <div className="flex justify-center mb-12">
                <div className="w-200 h-200 m-auto">
                  <RobotFace
                    imageUrl="./robot_face.png"
                    width={205}
                    height={205}
                  />
                </div>
              </div>
            </main>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
