import axios from './axios';
import { Notification, UpdateNotificationStatusParams } from '../types/notificationTypes';

// Retrieve all notifications
const getNotifications = async (): Promise<Notification[]> => {
    try {
        const response = await axios.get('notifier/notifications/');
        // console.log('Notifications:')
        // console.log(response);
        return response.data;
    } catch (error: any) {
        console.error('Get notifications error:', error.response?.data);
        throw error;
    }
};

// Retrieve all notifications
const getNotificationData = async (): Promise<Notification[]> => {
    try {
        const response = await axios.get('notifier/notification_data/');
        console.error('Notification_data:')
        console.log(response);
        return response.data;
    } catch (error: any) {
        console.error('Get notifications error:', error.response?.data);
        throw error;
    }
};

// Retrieve a specific notification by ID
const getNotification = async (id: number): Promise<Notification> => {
    try {
        const response = await axios.get(`notifier/notifications/${id}/`);
        return response.data;
    } catch (error: any) {
        console.error('Get notification error:', error.response?.data);
        throw error;
    }
};

// Mark a specific notification as viewed
const markAsViewed = async (id: number): Promise<void> => {
    try {
         await axios.post(`notifier/notifications/${id}/mark_as_viewedd/`);
    } catch (error: any) {
        console.error('Mark notification as viewed error:', error.response?.data);
        throw error;
    }
};

// Mark all notifications as viewed
const markAllAsViewed = async (): Promise<void> => {
    try {
        await axios.post('notifier/notifications/mark_all_viewed/');
    } catch (error: any) {
        console.error('Mark all notifications as viewed error:', error.response?.data);
        throw error;
    }
};

// Update the status of a specific notification
const updateNotificationStatus = async (id: number, params: UpdateNotificationStatusParams): Promise<Notification> => {
    try {
        const response = await axios.patch(`notifier/notifications/${id}/`, params);
        return response.data;
    } catch (error: any) {
        console.error('Update notification status error:', error.response?.data);
        throw error;
    }
};

// Exporting functions
export {
    getNotifications,
    getNotificationData,
    getNotification,
    markAsViewed,
    markAllAsViewed,
    updateNotificationStatus,
};