import React, { useState, useEffect, useMemo } from "react";
import { RiAddLine } from "react-icons/ri";
import { FaFilter } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "../app/store";
import {
  fetchAllAlerts,
  selectAllAlerts,
  selectLoading,
} from "../features/alerts/alertsSlice";
import CategoryModal from "../components/CategoryModal";
import NewsAlertModal from "../components/forms/NewsAlertModal";
import ListingsModal from "../components/forms/ListingsModal";
import PriceAlertsModal from "../components/forms/PriceAlertsModal";
import WeatherModal from "../components/forms/WeatherModal";
import SportModal from "../components/forms/SportModal";
import XModal from "../components/forms/XModal";
import EventModal from "../components/forms/EventModal";
import {
  CryptoPriceChangeAlert,
  EventAlert,
  GeneralAlert,
  NewListingAlert,
  Source,
  SportsNewsAlert,
  WeatherAlert,
  NewsAlert,
  XNewsAlert,
} from "../types/alertTypes";
import AlertItem from "../components/AlertItem";
import { motion, AnimatePresence } from "framer-motion";

const MonitorPage = () => {
  const dispatch = useAppDispatch();
  const alerts = useAppSelector(selectAllAlerts);
  const loading = useAppSelector(selectLoading);
  const [alertToEdit, setAlertToEdit] = useState<GeneralAlert | null>(null);
  const [modalState, setModalState] = useState({
    isMainModalOpen: false,
    isKeywordFormOpen: false,
    isSourceFormOpen: false,
    isPriceAlertFormOpen: false,
    isListingFormOpen: false,
    isWeatherFormOpen: false,
    isSportFormOpen: false,
    isXFormOpen: false,
    isEventFormOpen: false,
  });

  const [sortKey, setSortKey] = useState<"created_at" | "last_check">(
    "created_at"
  );
  const [sortAscending, setSortAscending] = useState(true);
  const [filters, setFilters] = useState({
    active: "",
    keyword: "",
    live_feed: "",
    sources: "",
  });

  useEffect(() => {
    dispatch(fetchAllAlerts());
  }, [dispatch]);

  const handleCloseAllModals = () => {
    setModalState({
      isMainModalOpen: false,
      isKeywordFormOpen: false,
      isSourceFormOpen: false,
      isPriceAlertFormOpen: false,
      isListingFormOpen: false,
      isWeatherFormOpen: false,
      isSportFormOpen: false,
      isXFormOpen: false,
      isEventFormOpen: false,
    });
  };

  const handleOpenModal = (
    option: string,
    alertToEdit: GeneralAlert | null = null
  ) => {
    setAlertToEdit(alertToEdit);
    handleCloseAllModals();
    switch (option) {
      case "News by Keyword":
        setModalState((prev) => ({ ...prev, isKeywordFormOpen: true }));
        break;
      case "News by Source":
        setModalState((prev) => ({ ...prev, isSourceFormOpen: true }));
        break;
      case "Price Alerts":
        setModalState((prev) => ({ ...prev, isPriceAlertFormOpen: true }));
        break;
      case "New Listings":
        setModalState((prev) => ({ ...prev, isListingFormOpen: true }));
        break;
      case "Weather":
        setModalState((prev) => ({ ...prev, isWeatherFormOpen: true }));
        break;
      case "Sport":
        setModalState((prev) => ({ ...prev, isSportFormOpen: true }));
        break;
      case "X News":
        setModalState((prev) => ({ ...prev, isXFormOpen: true }));
        break;
      case "Event":
        setModalState((prev) => ({ ...prev, isEventFormOpen: true }));
        break;
      default:
        console.error("Unknown category selected");
    }
  };

  const sortedAndFilteredAlerts = useMemo(() => {
    let filteredAlerts = alerts.filter((alert) => {
      if (filters.active && filters.active === "active" && !alert.active)
        return false;
      if (filters.active && filters.active === "inactive" && alert.active)
        return false;
      if (
        filters.keyword &&
        "keyword" in alert &&
        filters.keyword &&
        !alert.keyword?.toLowerCase().includes(filters.keyword.toLowerCase())
      )
        return false;
      if (
        filters.live_feed !== "" &&
        "live_feed" in alert &&
        `${alert.live_feed}` !== filters.live_feed
      )
        return false;
      if (
        filters.sources &&
        "sources" in alert &&
        !alert.sources.some((source: Source) =>
          source.name.toLowerCase().includes(filters.sources.toLowerCase())
        )
      )
        return false;
      return true;
    });

    return filteredAlerts.sort((a, b) => {
      let aValue: any = a[sortKey];
      let bValue: any = b[sortKey];
      if (sortKey === "created_at" || sortKey === "last_check") {
        aValue = new Date(aValue).getTime();
        bValue = new Date(bValue).getTime();
      }
      if (sortAscending) {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  }, [alerts, sortKey, sortAscending, filters]);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const renderSortingAndFiltering = () => (
    <div className="mt-4 w-full max-w-lg bg-[#1e1134] text-white rounded-md shadow-md">
      <div className="relative inline-block text-left w-full">
        <div>
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[#d6336c] text-sm font-medium text-white hover:bg-[#b8265e]"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <FaFilter className="mr-2" />
            Filters
          </button>
        </div>
        {dropdownOpen && (
          <div className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-[#2b1b32] ring-1 ring-black ring-opacity-5">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="px-4 py-2">
                <button
                  onClick={() => {
                    setSortKey("created_at");
                    setSortAscending(!sortAscending);
                  }}
                  className="bg-[#1e1134] text-white px-3 py-1 rounded-md flex items-center justify-center w-full mb-2 hover:bg-[#2b1b32]"
                >
                  Sort by Created{" "}
                  {sortAscending && sortKey === "created_at" ? "↓" : "↑"}
                </button>
                <button
                  onClick={() => {
                    setSortKey("last_check");
                    setSortAscending(!sortAscending);
                  }}
                  className="bg-[#1e1134] text-white px-3 py-1 rounded-md flex items-center justify-center w-full mb-2 hover:bg-[#2b1b32]"
                >
                  Sort by Last Check{" "}
                  {sortAscending && sortKey === "last_check" ? "↓" : "↑"}
                </button>
                <div className="flex flex-col md:flex-row items-start w-full mb-2">
                  <label htmlFor="active" className="mr-2 text-white">
                    Active:
                  </label>
                  <select
                    id="active"
                    className="bg-[#1e1134] text-gray-200 p-1 rounded w-full"
                    value={filters.active}
                    onChange={(e) =>
                      setFilters({ ...filters, active: e.target.value })
                    }
                  >
                    <option value="">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                <div className="flex flex-col md:flex-row items-start w-full mb-2">
                  <label
                    htmlFor="live_feed"
                    className="mr-2 text-white whitespace-nowrap"
                  >
                    Live Feed:
                  </label>
                  <select
                    id="live_feed"
                    className="bg-[#1e1134] text-gray-200 p-1 rounded w-full"
                    value={filters.live_feed}
                    onChange={(e) =>
                      setFilters({ ...filters, live_feed: e.target.value })
                    }
                  >
                    <option value="">All</option>
                    <option value="true">Enabled</option>
                    <option value="false">Disabled</option>
                  </select>
                </div>
                <div className="flex flex-col md:flex-row items-start w-full mb-2">
                  <label htmlFor="keyword" className="mr-2 text-white">
                    Keyword:
                  </label>
                  <input
                    id="keyword"
                    type="text"
                    className="bg-[#1e1134] text-gray-200 p-1 rounded w-full"
                    placeholder="Filter by keyword..."
                    value={filters.keyword}
                    onChange={(e) =>
                      setFilters({ ...filters, keyword: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col md:flex-row text-white items-start w-full">
                  <label htmlFor="sources" className="mr-2 text-white">
                    Sources:
                  </label>
                  <input
                    id="sources"
                    type="text"
                    className="bg-[#1e1134] p-1 text-gray-200 rounded w-full"
                    placeholder="Filter by sources..."
                    value={filters.sources}
                    onChange={(e) =>
                      setFilters({ ...filters, sources: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-center min-h-screen bg-[#15061a] text-white p-4">
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      >
        <source src="SIgnal_flow.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <header className="relative text-center pt-20 pb-10">
        <h1
          className="text-5xl font-bold mb-4"
          style={{ color: "#faf4ff", fontFamily: "Poppins, sans-serif" }}
        >
          Your <span className="text-[#d6336c]">Alerts</span>
        </h1>
        <p
          className="max-w-2xl mx-auto text-lg mb-8"
          style={{ color: "#a3a1a8", fontFamily: "Poppins, sans-serif" }}
        >
          Create and manage your alerts
        </p>
      </header>

      <div className="flex flex-col md:flex-row justify-center items-center w-full max-w-7xl space-y-4 md:space-y-0 md:space-x-4 z-20">
        <button
          className="bg-[#d6336c] text-white font-bold py-2 px-4 rounded-full flex items-center hover:bg-[#b8265e] "
          onClick={() =>
            setModalState({ ...modalState, isMainModalOpen: true })
          }
        >
          <RiAddLine className="mr-2" /> New Alert
        </button>
      </div>
      {renderSortingAndFiltering()}
      <div className="mt-6 space-y-4 w-full max-w-7xl">
        {loading && (
          <p className="text-center text-gray-300">Loading alerts...</p>
        )}
        {!loading && sortedAndFilteredAlerts.length === 0 && (
          <div className="text-center text-gray-300">No alerts to display</div>
        )}
        <AnimatePresence>
          {sortedAndFilteredAlerts.map((alert) => (
            <motion.div
              key={alert.type + alert.id}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.3 }}
            >
              <AlertItem
                key={alert.type + alert.id}
                alert={alert}
                onEdit={handleOpenModal}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
      <CategoryModal
        isOpen={modalState.isMainModalOpen}
        onClose={() => setModalState({ ...modalState, isMainModalOpen: false })}
        onSelect={handleOpenModal}
      />
      <NewsAlertModal
        isOpen={modalState.isKeywordFormOpen}
        onClose={handleCloseAllModals}
        mode="keyword"
        alert={alertToEdit as NewsAlert}
      />
      <NewsAlertModal
        isOpen={modalState.isSourceFormOpen}
        onClose={handleCloseAllModals}
        mode="source"
        alert={alertToEdit as NewsAlert}
      />
      <PriceAlertsModal
        isOpen={modalState.isPriceAlertFormOpen}
        onClose={handleCloseAllModals}
        alert={alertToEdit as CryptoPriceChangeAlert}
      />
      <ListingsModal
        isOpen={modalState.isListingFormOpen}
        onClose={handleCloseAllModals}
        alert={alertToEdit as NewListingAlert}
      />
      <WeatherModal
        isOpen={modalState.isWeatherFormOpen}
        onClose={handleCloseAllModals}
        alert={alertToEdit as WeatherAlert}
      />
      <SportModal
        isOpen={modalState.isSportFormOpen}
        onClose={handleCloseAllModals}
        mode="keyword"
        alert={alertToEdit as SportsNewsAlert}
      />
      <XModal
        isOpen={modalState.isXFormOpen}
        onClose={handleCloseAllModals}
        alert={alertToEdit as XNewsAlert}
      />
      <EventModal
        isOpen={modalState.isEventFormOpen}
        onClose={handleCloseAllModals}
        alert={alertToEdit as EventAlert}
      />
    </div>
  );
};

export default MonitorPage;
