import React, {
  Dispatch,
  SetStateAction,
  useState,
  useRef,
  useEffect,
} from "react";

// Define the type for a single source
interface Source {
  value: string;
  label: string;
}

interface SourcePickerProps {
  sources: Source[];
  selectedSources: string[];
  setSelectedSources: Dispatch<SetStateAction<string[]>>;
}
const SourcePicker = ({
  sources,
  selectedSources,
  setSelectedSources,
}: SourcePickerProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");

  // Use ref for the dropdown to handle outside clicks
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Handle clicking outside the dropdown to close it
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Toggle the visibility of the dropdown
  const toggleDropdown = (event: any) => {
    event.preventDefault();
    setShowDropdown(!showDropdown);
  };

  // Filter sources based on the search text
  const filteredSources = sources.filter((source) =>
    source.label.toLowerCase().includes(filter.toLowerCase())
  );

  // Handle selection changes
  const handleSourceChange = (value: string) => {
    setSelectedSources((prev: any) =>
      prev.includes(value)
        ? prev.filter((src: any) => src !== value)
        : [...prev, value]
    );
  };

  // Remove a selected source
  const removeSource = (value: string) => {
    setSelectedSources((prev: any) => prev.filter((src: any) => src !== value));
  };

  // Clear all selected sources
  const clearAllSources = () => {
    setSelectedSources([]);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex flex-col">
        <button
          type="button"
          onClick={toggleDropdown}
          className="bg-darker-gradient hover:bg-default-gradient text-white font-bold py-2 px-4 rounded flex items-center justify-center"
        >
          Select Sources
        </button>

        {showDropdown && (
          <div className="absolute z-10 w-full bg-gray-800 text-white border-2 border-gray-600 mt-1 p-3 rounded-md shadow-lg max-h-60 overflow-y-auto">
            <input
              type="text"
              placeholder="Search sources..."
              className="p-2 w-full bg-gray-800 text-white border-b border-gray-700"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            {filteredSources.map((source) => (
              <label
                key={source.value}
                className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-800"
              >
                <input
                  type="checkbox"
                  checked={selectedSources.includes(source.value)}
                  onChange={() => handleSourceChange(source.value)}
                  className="form-checkbox rounded text-blue-500"
                />
                <span>{source.label}</span>
              </label>
            ))}
          </div>
        )}
      </div>

      <div className="flex flex-wrap gap-2 mt-2">
        {selectedSources.map((sourceValue: any) => {
          const source = sources.find((s) => s.value === sourceValue);
          return (
            <div
              key={sourceValue}
              className="flex items-center bg-lighter-gradient text-gray-800 text-sm px-2 py-1 rounded-full"
            >
              {source?.label}
              <button
                type="button"
                onClick={() => removeSource(sourceValue)}
                className=" ml-1 rounded-full px-2 hover:bg-gray-800 hover:text-white"
              >
                <span className="text-xl font-bold">&times;</span>
              </button>
            </div>
          );
        })}
        {selectedSources.length > 0 && (
          <button
            type="button"
            onClick={clearAllSources}
            className="text-gray-400 text-xs underline"
          >
            Clear All
          </button>
        )}
      </div>
    </div>
  );
};

export default SourcePicker;
