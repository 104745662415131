import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Edge,
  Node,
  applyNodeChanges,
  applyEdgeChanges,
  ReactFlowInstance,
  NodeChange,
  EdgeChange,
  MiniMap,
} from "react-flow-renderer";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaRobot,
} from "react-icons/fa";
import { motion } from "framer-motion";
import Calendar, { CalendarProps, OnArgs } from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import "react-calendar/dist/Calendar.css";
import TimePicker from "react-time-picker";
import { Handle, Position } from "react-flow-renderer";

// import 'react-calendar/dist/Calendar.css';
import "react-time-picker/dist/TimePicker.css";
import "./ActionsPage.css";
import ActionTile from "../components/actions/ActionCalendarTile";
import Sidebar from "../components/actions/ActoinsSidebar";

const initialNodes: Node[] = [];

const initialEdges: Edge[] = [];

const draggableElements = [
  // {
  //   name: "Facebook",
  //   icon: <FaFacebook className="text-blue-600" />,
  //   options: ["Publish", "Share", "Description", "Link"],
  // },
  // {
  //   name: "Twitter",
  //   icon: <FaTwitter className="text-blue-400" />,
  //   options: ["Tweet", "Retweet", "Description", "Link"],
  // },
  // {
  //   name: "Instagram",
  //   icon: <FaInstagram className="text-pink-500" />,
  //   options: ["Post", "Share Story", "Description", "Link"],
  // },
  {
    name: "AI Video Generator",
    icon: <FaRobot className="text-green-600" />,
    options: ["Generate"],
  },
  {
    name: "YouTube",
    icon: <FaYoutube className="text-red-600" />,
    options: ["Upload Video"],
  },
];

interface CustomNodeProps {
  data: { label: string; onRemove: (id: string) => void };
  id: string;
}

const CustomNode: React.FC<CustomNodeProps> = ({ data, id }) => {
  return (
    <div className="custom-node">
      <Handle type="source" position={Position.Right} />
      <div>{data.label}</div>
      <button onClick={() => data.onRemove(id)}>Remove</button>
      <Handle type="target" position={Position.Left} />
    </div>
  );
};

interface ScheduledAction {
  id: string;
  date: string; // ISO date string
  time: string; // Time string (e.g., "14:30")
  prompt: string;
  workflow: string;
  audience: string;
  progress: number; // 0-100
  color: string; // CSS color string
}

const nodeTypes = {
  custom: CustomNode,
};

interface Prompt {
  time: string;
  prompt: string;
  workflow: string;
  audience: string;
}

type Tab = "calendar" | "new-action" | "action-list";

const ActionsPage = () => {
  const [nodes, setNodes] = useState<Node[]>(initialNodes);
  const [edges, setEdges] = useState<Edge[]>(initialEdges);
  const [tab, setTab] = useState<Tab>("calendar");
  const [projects, setProjects] = useState<
    { name: string; nodes: Node[]; edges: Edge[]; thumbnail: string }[]
  >([]);
  const [projectName, setProjectName] = useState<string>("");
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useRef<ReactFlowInstance>();

  const [prompt, setPrompt] = useState("");
  const [workflow, setWorkflow] = useState("");
  const [audience, setAudience] = useState("");
  const [date, setDate] = useState<Date | null>(new Date());
  const [view, setView] = useState<"month" | "year" | "decade" | "century">(
    "month"
  );
  const [time, setTime] = useState("10:00");
  const [promptCount, setPromptCount] = useState(1);
  const [scheduledActions, setScheduledActions] = useState<{
    [key: string]: Prompt[];
  }>({
    "9/1/2024": [
      {
        time: "10:00 AM",
        prompt: "Prompt 1",
        workflow: "Workflow 1",
        audience: "Audience 1",
      },
      {
        time: "2:00 PM",
        prompt: "Prompt 2",
        workflow: "Workflow 2",
        audience: "Audience 2",
      },
    ],
  });
  const [hour, setHour] = useState("10");
  const [minute, setMinute] = useState("00");

  const workflows = [
    "YouTube Explainer",
    "Script to Video",
    "YT Shorts",
    "News Video",
    "TikTok Video",
    "Instagram Reel",
  ];

  const audiences = ["History", "Political", "News followers"];

  const handleSubmit = () => {
    if (date) {
      const selectedDate = date ? date.toLocaleDateString() : null;
      const scheduledForDay = scheduledActions[date as unknown as string] || [];
      const newPrompt: Prompt = {
        prompt,
        workflow,
        audience,
        time,
      };
      setScheduledActions({
        ...scheduledActions,
        [date as unknown as string]: [...scheduledForDay, newPrompt],
      });
      setPrompt("");
      setWorkflow("");
      setAudience("");
      setTime("10:00");
      setPromptCount(1);
    }
  };

  const handleDateChange = (
    value: Value,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!Array.isArray(value) && value !== null) {
      console.log("Selected date:", value);
      setDate(value); // Set the single date
    } else {
      console.log("Expected a single date, but got:", value);
    }
  };

  // Typing the handleRemoveAction function
  const handleRemoveAction = (dateStr: string, index: number) => {
    console.log(`Remove action on ${dateStr} at index ${index}`);
    // Implement your remove logic here
  };

  const handleViewChange = ({
    action,
    activeStartDate,
    value,
    view,
  }: OnArgs) => {
    console.log("View changed to:", view);
    setView(view);
  };

  const nodeTypes = useMemo(
    () => ({
      custom: (nodeProps: any) => <CustomNode {...nodeProps} />,
    }),
    []
  );

  const tileClassName = ({
    date: tileDate,
    view,
  }: {
    date: Date;
    view: "month" | "year" | "decade" | "century";
  }) => {
    const currentDateString = new Date().toLocaleDateString();
    const tileDateString = tileDate.toLocaleDateString();
    const selectedDateString = date?.toLocaleDateString();

    if (view === "month") {
      if (currentDateString === tileDateString) {
        return "current-day";
      } else if (selectedDateString === tileDateString) {
        return "selected-day";
      }
    }
    return null;
  };

  const getTileContent = ({ date, view }: { date: Date; view: string }) => {
    if (view === "month" || view === "week") {
      const dateString = date.toLocaleDateString();
      const actions = scheduledActions[dateString] || [];

      if (actions.length > 0) {
        // For now, we'll just show the first action. You can modify this to show multiple or the most important one.
        const task: ScheduledAction = {
          id: "1",
          time: "1",
          date: dateString,
          color: "#282828", // Light blue background
          progress: 75, // 75% complete
          prompt:
            "Create YouTube video on React hooks and actions and something else",
          workflow: "o",
          audience: "o",
        };

        return (
          <div className="tasks-container">
            {actions.slice(0, 5).map((action, index) => (
              <ActionTile key={index} task={task} />
            ))}
          </div>
        );
      }
    }
    return null;
  };

  const onNodesChange = (changes: NodeChange[]) =>
    setNodes((nds) => applyNodeChanges(changes, nds));
  const onEdgesChange = (changes: EdgeChange[]) =>
    setEdges((eds) => applyEdgeChanges(changes, eds));
  const onConnect = (params: Edge | Connection) => {
    const newEdge = {
      ...params,
      type: "smoothstep",
      animated: true,
    };
    setEdges((eds) => addEdge(newEdge, eds));
  };

  const handleDrop = (
    event: React.DragEvent,
    platform: string,
    option: string
  ) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current!.getBoundingClientRect();
    const position = {
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    };
    const newNode = {
      id: `${nodes.length + 1}`,
      data: {
        label: `${platform} - ${option}`,
        onRemove: (id: string) => removeNode(id),
      },
      position,
      type: "custom",
    };
    setNodes((nds) => nds.concat(newNode));
  };

  const onDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const removeNode = (id: string) => {
    setNodes((nds) => nds.filter((node) => node.id !== id));
    setEdges((eds) =>
      eds.filter((edge) => edge.source !== id && edge.target !== id)
    );
  };

  const captureThumbnail = (): Promise<string> => {
    return new Promise((resolve) => {
      const reactFlowNode = reactFlowWrapper.current!;
      const svg = reactFlowNode.querySelector("svg")!;
      const { width, height } = svg.getBBox();
      const clonedSvgElement = svg.cloneNode(true) as SVGElement;
      const outerHTML = clonedSvgElement.outerHTML;
      const blob = new Blob([outerHTML], {
        type: "image/svg+xml;charset=utf-8",
      });
      const URL = window.URL || window.webkitURL || window;
      const blobURL = URL.createObjectURL(blob);
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const context = canvas.getContext("2d");
        context!.drawImage(image, 0, 0, width, height);
        URL.revokeObjectURL(blobURL);
        resolve(canvas.toDataURL());
      };
      image.src = blobURL;
    });
  };

  const createNewProject = async () => {
    const newProjectName = projectName || `Project ${projects.length + 1}`;
    if (reactFlowInstance.current) {
      const thumbnail = await captureThumbnail();
      const newProject = {
        name: newProjectName,
        nodes,
        edges,
        thumbnail,
      };
      setProjects([...projects, newProject]);
      setProjectName("");
      setNodes([]);
      setEdges([]);
    }
  };

  const loadProject = (project: {
    name: string;
    nodes: Node[];
    edges: Edge[];
  }) => {
    setNodes(
      project.nodes.map((node) => ({
        ...node,
        data: {
          ...node.data,
          onRemove: (id: string) => removeNode(id),
        },
      }))
    );
    setEdges(project.edges);
  };

  const deleteProject = (index: number) => {
    const updatedProjects = projects.filter((_, i) => i !== index);
    setProjects(updatedProjects);
  };

  useEffect(() => {
    const savedProjects = JSON.parse(localStorage.getItem("projects") || "[]");
    setProjects(savedProjects);
  }, []);

  useEffect(() => {
    localStorage.setItem("projects", JSON.stringify(projects));
  }, [projects]);

  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col lg:flex-row">
      <section className="lg:mt-16 mt-8 mx-auto w-full md:w-[25%]">
        <Sidebar onTabSelect={setTab} />
      </section>

      {/* Time Picker section*/}
      {/* <div className="flex mb-4 space-x-2">
        <select
          value={hour}
          onChange={(e) => setHour(e.target.value)}
          className="mt-72 p-2 rounded bg-gray-800 border border-gray-700 max-h-20 overflow-auto"
        >
          {Array.from(Array(24).keys()).map((h) => (
            <option key={h} value={h.toString().padStart(2, '0')}>
              {h.toString().padStart(2, '0')}
            </option>
          ))}
        </select>
        <span>:</span>
        <select
          value={minute}
          onChange={(e) => setMinute(e.target.value)}
          className=" mt-72 p-2 rounded bg-gray-800 border border-gray-700 max-h-20 overflow-auto"
        >
          {['00', '15', '30', '45'].map((m) => (
            <option key={m} value={m}>
              {m}
            </option>
          ))}
        </select>
      </div> */}

      {/* Calendar Section */}
      {tab === "calendar" && (
        <section className="calendar-section">
          <h2 className="text-3xl font-bold">Your Planned Actions</h2>
          <Calendar
            value={date}
            onChange={handleDateChange}
            view={view}
            onViewChange={handleViewChange}
            tileClassName={tileClassName}
            tileContent={getTileContent}
            className="calendar"
          />
          <div className="calendar-popup">
            <h3 className="text-2xl font-semibold mb-4">
              Actions Scheduled for {date ? date.toLocaleDateString() : "None"}
            </h3>
            {date &&
              (scheduledActions[date.toLocaleDateString()] || []).map(
                (prompt, index) => (
                  <div
                    key={index}
                    className="mb-2 flex items-center text-white"
                  >
                    <strong>{prompt.time}:</strong> {prompt.prompt} (
                    {prompt.workflow} - {prompt.audience})
                    <button
                      className="delete-button"
                      onClick={() =>
                        handleRemoveAction(date.toLocaleDateString(), index)
                      }
                    >
                      x
                    </button>
                  </div>
                )
              )}
          </div>
        </section>
      )}

      {/* Action assembler section */}
      {tab === "new-action" && (
        <main className="w-full mx-[2%] flex-grow">
          <motion.div
            className="text-center mb-12"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-3xl font-bold bg-clip-text mt-20 ">
              Create Your Automation
            </h2>
            <p className="text-lg mt-1">
              Drag and drop elements to build your custom automation flow.
            </p>
          </motion.div>

          <section className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-2">
            {draggableElements.map((element, index) => (
              <motion.div
                key={index}
                className="p-6 bg-gray-800 rounded-lg shadow-lg flex flex-col items-center platform-card"
                initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <div className="text-4xl mb-4">{element.icon}</div>
                <h3 className="text-xl font-semibold mb-4">{element.name}</h3>
                <div className="flex flex-col items-start space-y-2">
                  {element.options.map((option, i) => (
                    <div
                      key={i}
                      className="draggable-item"
                      draggable
                      onDragStart={(event) => {
                        event.dataTransfer.setData("platform", element.name);
                        event.dataTransfer.setData("option", option);
                      }}
                    >
                      {element.icon} {option}
                    </div>
                  ))}
                </div>
              </motion.div>
            ))}
          </section>

          <div
            className="drop-zone"
            onDragOver={onDragOver}
            onDrop={(event) => {
              const platform = event.dataTransfer.getData("platform");
              const option = event.dataTransfer.getData("option");
              handleDrop(event, platform, option);
            }}
            ref={reactFlowWrapper}
          >
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              fitView
              onInit={(instance) => (reactFlowInstance.current = instance)}
              nodeTypes={{
                custom: (nodeProps) => <CustomNode {...nodeProps} />,
              }}
            >
              <Background />
            </ReactFlow>
          </div>
        </main>
      )}

      {/* Action manager section */}
      {tab === "action-list" && (
        <aside className="project-sidebar lg:max-w-sm lg:mt-16 mt-8">
          <h2 className="text-2xl font-bold mt-16">Projects</h2>
          <div className="mb-4">
            <input
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder="Project Name"
              className="w-full p-2 mb-2 text-black"
            />
            <button onClick={createNewProject} className="new-project-btn">
              New Project
            </button>
          </div>
          {projects.map((project, index) => (
            <div key={index} className="project-item">
              <img
                src={project.thumbnail}
                alt={project.name}
                className="thumbnail"
              />
              <span>{project.name}</span>
              <div>
                <button
                  onClick={() => loadProject(project)}
                  className="bg-blue-500 text-white"
                >
                  Load
                </button>
                <button
                  onClick={() => deleteProject(index)}
                  className="bg-red-500 text-white"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </aside>
      )}
    </div>
  );
};

export default ActionsPage;
