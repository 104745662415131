
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const CookieConsent: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
  };

  return (
    isVisible && (
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        className="fixed bottom-0 left-0 right-0 p-4 bg-gray-800 text-white flex flex-col md:flex-row items-center justify-between z-50"
      >
        <div className="text-center md:text-left">
          <p>
            We use cookies to ensure the best experience on our website. By
            continuing to use our site, you accept our{" "}
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-800 underline"
            >
              cookie policy
            </a>
            .
          </p>
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={acceptCookies}
          className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-lg mt-4 md:mt-0 md:ml-4"
        >
          Accept
        </motion.button>
      </motion.div>
    )
  );
};

export default CookieConsent;