import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { register } from "../api/authService";
import { setUser } from "../features/user/userSlice";
import { useDispatch } from "react-redux";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";

const RegisterForm = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    password1: "",
    password2: "",
    passwordClue: "",
    avatar: null,
  });
  const [errors, setErrors] = useState({
    username: "",
    email: "",
    password1: "",
    password2: "",
  });
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [termsError, setTermsError] = useState("");
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const navigate = useNavigate();

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (password: string) => {
    return password.length >= 8;
  };

  const calculatePasswordStrength = (password: string) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[@$!%*?&#]/.test(password)) strength += 1;
    setPasswordStrength(strength);
  };

  const handleChange = (event: any) => {
    const { name, value, type, files } = event.target;
    setUserData({
      ...userData,
      [name]: type === "file" ? files[0] : value,
    });

    switch (name) {
      case "username":
        setErrors({
          ...errors,
          username: value ? "" : "Username is required",
        });
        break;
      case "email":
        setErrors({
          ...errors,
          email: validateEmail(value) ? "" : "Invalid email address",
        });
        break;
      case "password1":
        calculatePasswordStrength(value);
        setErrors({
          ...errors,
          password1: validatePassword(value)
            ? ""
            : "Password must be at least 8 characters",
        });
        break;
      case "password2":
        setErrors({
          ...errors,
          password2:
            value === userData.password1 ? "" : "Passwords do not match",
        });
        break;
      default:
        break;
    }
  };

  const handleTermsChange = () => {
    setAcceptTerms(!acceptTerms);
    setTermsError("");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // Check for validation errors
    if (
      !userData.username ||
      !userData.email ||
      !userData.password1 ||
      !userData.password2
    ) {
      setErrors({
        username: !userData.username ? "Username is required" : "",
        email: !userData.email ? "Email is required" : "",
        password1: !userData.password1 ? "Password is required" : "",
        password2: !userData.password2 ? "Confirm Password is required" : "",
      });
      return;
    }

    if (
      errors.username ||
      errors.email ||
      errors.password1 ||
      errors.password2
    ) {
      return;
    }

    if (!acceptTerms) {
      setTermsError("You must accept the terms and conditions");
      return;
    }

    try {
      const response = await register(
        userData.username,
        userData.email,
        userData.password1,
        userData.password2,
        "*",
        userData.avatar as unknown as string
      );
      dispatch(
        setUser({
          user: response.user,
          token: response.access,
          refresh: response.refresh,
        })
      );
      console.log("Registration successful", response);
      setIsRegistrationSuccessful(true); // Show video after successful registration
      navigate("/");
    } catch (error) {
      console.error("Registration failed:", error);
      // Optionally handle registration failure (e.g., showing an error message)
    }
  };

  // const handleVideoEnded = () => {
  //   navigate("/"); // Redirect to the home page after the video ends
  // };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
      {/* {isRegistrationSuccessful && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black">
          <video
            id="introVideo"
            src="/RENDER.mp4"
            className="w-full h-full object-cover"
            autoPlay
            muted
            onEnded={handleVideoEnded}
          />
        </div>
      )} */}
      {!isRegistrationSuccessful && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
          className="w-full max-w-lg p-10 bg-white shadow-2xl rounded-3xl relative overflow-hidden"
        >
          <motion.div
            initial={{ opacity: 0, scale: 2 }}
            animate={{ opacity: 0.3, scale: 1 }}
            transition={{ duration: 1, ease: "easeOut" }}
            className="absolute -top-32 -right-32 w-96 h-96 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-full filter blur-2xl opacity-50"
          />
          <motion.div
            initial={{ opacity: 0, scale: 2 }}
            animate={{ opacity: 0.3, scale: 1 }}
            transition={{ duration: 1, ease: "easeOut", delay: 0.2 }}
            className="absolute -bottom-32 -left-32 w-96 h-96 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-full filter blur-2xl opacity-50"
          />
          <h2 className="text-4xl font-bold text-center text-gray-800 mb-8 z-10 relative">
            Create Your Account
          </h2>
          <form onSubmit={handleSubmit} className="relative z-10">
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="username"
              >
                Username
              </label>
              <motion.input
                whileFocus={{ scale: 1.05 }}
                className="shadow appearance-none border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition-transform duration-200"
                id="username"
                name="username"
                type="text"
                placeholder="Enter your username"
                value={userData.username}
                onChange={handleChange}
                required
              />
              {errors.username && (
                <p className="text-red-500 text-xs mt-2">{errors.username}</p>
              )}
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <motion.input
                whileFocus={{ scale: 1.05 }}
                className="shadow appearance-none border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition-transform duration-200"
                id="email"
                name="email"
                type="email"
                placeholder="Enter your email"
                value={userData.email}
                onChange={handleChange}
                required
              />
              {errors.email && (
                <p className="text-red-500 text-xs mt-2">{errors.email}</p>
              )}
            </div>

            <div className="mb-6 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password1"
              >
                Password
              </label>
              <motion.input
                whileFocus={{ scale: 1.05 }}
                className="shadow appearance-none border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition-transform duration-200 pr-10"
                id="password1"
                name="password1"
                type={showPassword1 ? "text" : "password"}
                placeholder="Create a password"
                value={userData.password1}
                onChange={handleChange}
                required
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <button
                  type="button"
                  onClick={() => setShowPassword1(!showPassword1)}
                  className="text-gray-600 hover:text-gray-900"
                >
                  {showPassword1 ? <RiEyeOffLine size={24} /> : <RiEyeLine size={24} />}
                </button>
              </div>
              {errors.password1 && (
                <p className="text-red-500 text-xs mt-2">{errors.password1}</p>
              )}

              {/* Pasek siły hasła */}
              <div className="mt-2">
                <div className="h-2 bg-gray-300 rounded">
                  <div
                    className={`h-full rounded ${passwordStrength === 1 ? 'bg-red-500' : 
                      passwordStrength === 2 ? 'bg-yellow-500' : 
                      passwordStrength === 3 ? 'bg-blue-500' : 
                      passwordStrength === 4 ? 'bg-green-500' : 'bg-gray-300'}`}
                    style={{ width: `${(passwordStrength / 4) * 100}%` }}
                  ></div>
                </div>
                <p className="text-xs mt-1">
                  Password Strength: {passwordStrength === 1 ? 'Weak' : 
                    passwordStrength === 2 ? 'Moderate' : 
                    passwordStrength === 3 ? 'Strong' : 
                    passwordStrength === 4 ? 'Very Strong' : 'Too Short'}
                </p>
              </div>
            </div>

            <div className="mb-6 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password2"
              >
                Confirm Password
              </label>
              <motion.input
                whileFocus={{ scale: 1.05 }}
                className="shadow appearance-none border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition-transform duration-200 pr-10"
                id="password2"
                name="password2"
                type={showPassword2 ? "text" : "password"}
                placeholder="Confirm your password"
                value={userData.password2}
                onChange={handleChange}
                required
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <button
                  type="button"
                  onClick={() => setShowPassword2(!showPassword2)}
                  className="text-gray-600 hover:text-gray-900"
                >
                  {showPassword2 ? <RiEyeOffLine size={24} /> : <RiEyeLine size={24} />}
                </button>
              </div>
              {errors.password2 && (
                <p className="text-red-500 text-xs mt-2">{errors.password2}</p>
              )}
            </div>

            <div className="mb-6 flex items-center">
              <motion.input
                type="checkbox"
                id="acceptTerms"
                name="acceptTerms"
                whileFocus={{ scale: 1.05 }}
                className="mr-2 form-checkbox text-blue-600 h-5 w-5"
                checked={acceptTerms}
                onChange={handleTermsChange}
              />
              <label
                htmlFor="acceptTerms"
                className="text-gray-700 text-sm font-bold"
              >
                I accept the{" "}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-800 underline"
                >
                  Terms and Conditions
                </a>
              </label>
            </div>
            {termsError && (
              <p className="text-red-500 text-xs mt-2">{termsError}</p>
            )}
            <div className="flex items-center justify-between mb-6">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Register
              </motion.button>
              <a
                href="/login"
                className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
              >
                Already have an account? Sign In
              </a>
            </div>
          </form>
        </motion.div>
      )}
    </div>
  );
};

export default RegisterForm;
