  // Converts a UTC time string to a local time string
  export function convertUTCTimeToLocal(utcTimeStr: string) {
    if (!utcTimeStr) return "";

    const now = new Date();
    const today = now.toISOString().split("T")[0]; // Get current date as YYYY-MM-DD
    const fullUTCDateTimeStr = `${today}T${utcTimeStr}Z`; // Create a full UTC datetime string

    const utcDate = new Date(fullUTCDateTimeStr);
    const localHours = utcDate.getHours();
    const localMinutes = utcDate.getMinutes();

    const localHoursPadded = localHours.toString().padStart(2, "0");
    const localMinutesPadded = localMinutes.toString().padStart(2, "0");

    return `${localHoursPadded}:${localMinutesPadded}`;
  }