import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { login } from '../api/authService';
import { setUser } from '../features/user/userSlice';
import { useDispatch } from 'react-redux';

const LoginForm = () => {
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });
  const navigate = useNavigate();

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (event: any) => {
    const { id, value } = event.target;
    setCredentials({
      ...credentials,
      [id]: value
    });

    switch (id) {
      case 'email':
        setErrors({
          ...errors,
          email: validateEmail(value) ? '' : 'Invalid email address'
        });
        break;
      case 'password':
        setErrors({
          ...errors,
          password: value ? '' : 'Password is required'
        });
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!credentials.email || !credentials.password || errors.email || errors.password) {
      setErrors({
        email: !credentials.email ? 'Email is required' : errors.email,
        password: !credentials.password ? 'Password is required' : errors.password
      });
      return;
    }

    try {
      const response = await login(credentials.email, credentials.password);
      dispatch(setUser({ user: response.user, token: response.access, refresh: response.refresh }));
      console.log('Login successful:', response);
      navigate('/'); // Redirect to the dashboard after successful login
    } catch (error) {
      console.error('Login failed:', error);
      // Optionally handle login failure (e.g., showing an error message)
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: 'easeOut' }}
        className="w-full max-w-lg p-10 bg-white shadow-2xl rounded-3xl relative overflow-hidden"
      >
        <motion.div
          initial={{ opacity: 0, scale: 2 }}
          animate={{ opacity: 0.3, scale: 1 }}
          transition={{ duration: 1, ease: 'easeOut' }}
          className="absolute -top-32 -right-32 w-96 h-96 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-full filter blur-2xl opacity-50"
        />
        <motion.div
          initial={{ opacity: 0, scale: 2 }}
          animate={{ opacity: 0.3, scale: 1 }}
          transition={{ duration: 1, ease: 'easeOut', delay: 0.2 }}
          className="absolute -bottom-32 -left-32 w-96 h-96 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-full filter blur-2xl opacity-50"
        />
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-8 z-10 relative">
          Welcome Back!
        </h2>
        <form onSubmit={handleSubmit} className="relative z-10">
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <motion.input
              whileFocus={{ scale: 1.05 }}
              className="shadow appearance-none border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition-transform duration-200"
              id="email"
              type="email"
              placeholder="Enter your email"
              value={credentials.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email}</p>}
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <motion.input
              whileFocus={{ scale: 1.05 }}
              className="shadow appearance-none border rounded-lg w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition-transform duration-200"
              id="password"
              type="password"
              placeholder="Enter your password"
              value={credentials.password}
              onChange={handleChange}
              required
            />
            {errors.password && <p className="text-red-500 text-xs mt-2">{errors.password}</p>}
          </div>
          <div className="flex items-center justify-between mb-6">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-3 px-6 rounded-lg focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Sign In
            </motion.button>
            <a
              href="#"
              className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
            >
              Forgot Password?
            </a>
          </div>
          <div className="text-center">
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
              className="text-sm text-gray-600"
            >
              Don't have an account?{" "}
              <a
                href="/register"
                className="font-bold text-blue-600 hover:text-blue-800"
              >
                Sign up here
              </a>
            </motion.p>
          </div>
        </form>
      </motion.div>
    </div>
  );
};

export default LoginForm;
