import Tooltip from "./FormElements";
import React, {
  Dispatch,
  SetStateAction,
  useState,
  useRef,
  useEffect,
} from "react";
import { FaInfoCircle } from "react-icons/fa"; // Make sure to install react-icons if not already: npm install react-icons

interface KeywordSetPickerProps {
  selectedKeywordSets: string[][];
  setSelectedKeywordSets: Dispatch<SetStateAction<string[][]>>;
}

const KeywordSetPicker = ({
  selectedKeywordSets,
  setSelectedKeywordSets,
}: KeywordSetPickerProps) => {
  const [customKeywordSet, setCustomKeywordSet] = useState<string>("");

  // Use ref for the dropdown to handle outside clicks
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Split the keyword set using regex to detect phrases and keywords
  const splitKeywordSet = (keywordSet: string): string[] => {
    const pattern = /"([^"]+)"|'([^']+)'|[^,; ]+/g;
    const matches = [];
    let match;
    while ((match = pattern.exec(keywordSet)) !== null) {
      matches.push(match[1] || match[2] || match[0]);
    }
    return matches;
  };

  // Add a custom keyword set
  const addCustomKeywordSet = () => {
    const keywords = splitKeywordSet(customKeywordSet);

    if (keywords.length > 0) {
      setSelectedKeywordSets((prev) => [...prev, keywords]);
    }

    setCustomKeywordSet("");
  };

  // Remove a specific keyword set
  const removeKeywordSet = (index: number) => {
    setSelectedKeywordSets((prev) => prev.filter((_, i) => i !== index));
  };

  // Clear all keyword sets
  const clearAllKeywordSets = () => {
    setSelectedKeywordSets([]);
  };

  return (
    <div className="relative flex flex-col gap-4" ref={dropdownRef}>
      <div className="flex items-center gap-2">
        <span className="text-gray-400 mb-1">Keyword and Phrase Sets</span>
        <Tooltip
          message={`Enter individual keywords separated by spaces or commas, or phrases encapsulated in double (" ") or single (' ') quotes.\n\nExample:\n"new product"\n'market analysis'\nelection, results, 2024\nprice, stock `}
        >
          <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
        </Tooltip>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center border border-gray-700 rounded-md p-1 bg-gray-700">
          <input
            type="text"
            placeholder="Enter keywords separated by space, comma, or encapsulated in quotes..."
            className="flex-grow p-2 text-gray-200 bg-gray-800 rounded outline-none"
            value={customKeywordSet}
            onChange={(e) => setCustomKeywordSet(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && addCustomKeywordSet()}
          />
          <button
            type="button"
            onClick={addCustomKeywordSet}
            className="bg-darker-gradient hover:bg-default-gradient text-white p-2 rounded-md hover:bg-blue-600 ml-2"
          >
            Add
          </button>
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mt-2">
        {selectedKeywordSets.map((keywordSet, index) => (
          <div
            key={index}
            className="flex items-center bg-lighter-gradient text-gray-800 text-sm px-2 py-1 rounded-full"
          >
            <span>{keywordSet.join(", ")}</span>
            <button
              type="button"
              onClick={() => removeKeywordSet(index)}
              className=" ml-1 rounded-full px-2 hover:bg-gray-800 hover:text-white"
            >
              <span className="text-xl font-bold">&times;</span>
            </button>
          </div>
        ))}
        {selectedKeywordSets.length > 0 && (
          <button
            type="button"
            onClick={clearAllKeywordSets}
            className="text-gray-400 text-xs underline"
          >
            Clear All
          </button>
        )}
      </div>
    </div>
  );
};

export default KeywordSetPicker;
