import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Notification } from '../../types/notificationTypes';

interface NotificationState {
    notifications: Notification[];
    loading: boolean;
    error: string | null;
}

const initialState: NotificationState = {
    notifications: [],
    loading: false,
    error: null,
};

// Async thunk action to fetch all notifications using the getNotifications API
export const fetchNotifications = createAsyncThunk(
    'notifications/fetchNotifications',
    async (_, { rejectWithValue }) => {
        try {
            // import api asynchronously, so it does't throw  'Uncaught ReferenceError: Cannot access 'WEBPACK_DEFAULT_EXPORT' before initialization' error
            const api = await import('../../api/notificationService');
            return await api.getNotifications();
        } catch (error: any) {
            return rejectWithValue(error.message || 'Unable to fetch notifications');
        }
    }
);

// Async thunk action to mark a specific notification as viewed
export const markAsViewed = createAsyncThunk(
    'notifications/markNotificationAsViewed',
    async (id: number, { rejectWithValue }) => {
        try {
            // import api asynchronously, so it does't throw  'Uncaught ReferenceError: Cannot access 'WEBPACK_DEFAULT_EXPORT' before initialization' error
            const api = await import('../../api/notificationService');
            await api.markAsViewed(id);
            return id;
        } catch (error: any) {
            return rejectWithValue(error.message || 'Unable to mark notification as viewed');
        }
    }
);

// Async thunk action to mark all notifications as viewed
export const markAllAsViewed = createAsyncThunk(
    'notifications/markAllNotificationsAsViewed',
    async (_, { rejectWithValue }) => {
        // import api asynchronously, so it does't throw  'Uncaught ReferenceError: Cannot access 'WEBPACK_DEFAULT_EXPORT' before initialization' error
        const api = await import('../../api/notificationService');
        try {
            await api.markAllAsViewed();
            return;
        } catch (error: any) {
            return rejectWithValue(error.message || 'Unable to mark all notifications as viewed');
        }
    }
);

const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotification: (state, action: PayloadAction<Notification>) => {
            state.notifications.push(action.payload);
        },
        clearNotifications: (state) => {
            state.notifications = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                state.notifications = action.payload;
                state.loading = false;
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            .addCase(markAsViewed.fulfilled, (state, action: PayloadAction<number>) => {
                const notification = state.notifications.find(n => n.id === action.payload);
                if (notification) {
                    notification.status = 'viewed';
                    notification.viewed_at = new Date().toISOString();
                }
            })
            .addCase(markAsViewed.rejected, (state, action) => {
                state.error = action.payload as string || 'Unable to mark notification as viewed';
            })
            .addCase(markAllAsViewed.fulfilled, (state) => {
                state.notifications.forEach(notification => {
                    notification.status = 'viewed';
                    notification.viewed_at = new Date().toISOString();
                });
            })
            .addCase(markAllAsViewed.rejected, (state, action ) => {
                state.error = action.payload as string || 'Unable to mark all notifications as viewed';
            });
    },
});

export const { addNotification, clearNotifications } = notificationSlice.actions;

export default notificationSlice.reducer;