import React, { useContext, useEffect, useState, useRef } from "react";
import { NotificationContext } from "../websocket/WebSocketClient";
import { useAppDispatch, useAppSelector } from "../app/store";
import {
  fetchNotifications,
  markAsViewed,
} from "../features/notifications/notificationSlice";
import { markAsViewed as markAsViewedService } from "../api/notificationService";
import { selectUser } from "../features/user/userSlice";
import { Notification } from "../types/notificationTypes";
import { Link } from "react-router-dom";
import { RiNotification3Line } from "react-icons/ri";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const NotificationDropdown = () => {
  const user = useAppSelector(selectUser);
  const { notifications: websocketNotifications } =
    useContext(NotificationContext);
  const { notifications: _notifications } = useAppSelector(
    (state) => state.notifications
  );
  const [notifications, setNotifications] =
    useState<Notification[]>(_notifications);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(fetchNotifications());
    }
  }, [dispatch]);

  useEffect(() => {
    setNotifications(_notifications);
  }, [_notifications]);

  useEffect(() => {
    setNotifications((prev) => [
      ...websocketNotifications.filter(
        (wsNotification) => !prev.some((n) => n.id === wsNotification.id)
      ),
      ...prev,
    ]);
  }, [websocketNotifications]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, () => setShowNotifications(false)]);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleMarkAsViewed = async (id: number) => {
    try {
      dispatch(markAsViewed(id));
      await markAsViewedService(id);
    } catch (error) {
      console.error("Error marking notification as viewed:", error);
    }
  };

  const shadeColor = (
    color: string | undefined,
    percent: number,
    status: any,
    default_color = "white"
  ): string => {
    if (color) {
      if (status === "sent" || status === "pending") {
        const [r, g, b] = (color.match(/\w\w/g) || ["0", "0", "0"]).map((x) => {
          const num = parseInt(x, 16);
          return Math.max(
            0,
            Math.min(255, Math.round(num * (1 - percent / 100)))
          );
        });
        return `#${((1 << 24) + (r << 16) + (g << 8) + b)
          .toString(16)
          .slice(1)
          .toUpperCase()}`;
      } else {
        return default_color;
      }
    }
    return default_color; // Default color if input color is undefined
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggleNotifications}
        className={`flex items-center p-1 transition duration-300 ease-in-out font-bold rounded-md text-xl px-3 ${
          location.pathname === "/notifications"
            ? "text-gray-800 bg-darker-gradient"
            : "bg-clip-text text-transparent bg-lighter-gradient"
        }`}
      >
        <svg
          width="24"
          height="24"
          fill={location.pathname === "/notifications" ? "gray-700" : "white"} // Make the bell grey when active
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <defs>
            <linearGradient
              id="bell-gradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="100%"
            >
              <stop offset="0%" stopColor="#8542A4" />
              <stop offset="50%" stopColor="#B95C7F" />
              <stop offset="100%" stopColor="#CC2A21" />
            </linearGradient>
          </defs>
          <path d="M18 16v-5c0-3.1-1.6-5.6-4.5-6.3-.1-.5-.3-1.6-1.5-1.6-1.2 0-1.4 1.1-1.5 1.6-2.9.7-4.5 3.2-4.5 6.3v5l-1.7 1.7c-.3.3-.3.9 0 1.2s.9.3 1.2 0l1.5-1.5h10.5l1.5 1.5c.3.3.9.3 1.2 0s.3-.9 0-1.2L18 16zM12 24c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2z" />
        </svg>
        {notifications.filter(
          (notification) =>
            notification.status === "sent" || notification.status === "pending"
        ).length > 0 && (
          <span
            className={`text-xs font-bold px-1 py-0.5 text-gray-800 rounded-md ${
              location.pathname === "/notifications"
                ? "text-gray-800"
                : "bg-clip-text text-transparent"
            }`}
          >
            {
              notifications.filter(
                (notification) =>
                  notification.status === "sent" ||
                  notification.status === "pending"
              ).length
            }
          </span>
        )}
      </button>
      {showNotifications && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="absolute left-7 mt-2  w-64 bg-gray-900 text-white rounded-md shadow-lg z-50 p-4"
        >
          <ul className="max-h-48 overflow-auto">
            {!user ? (
              <p className="text-sm text-gray-400">
                Want to receive notifications, alerts, and make your daily
                internet usage easier?{" "}
                <Link
                  to="/register"
                  className="text-blue-500 hover:text-blue-700"
                >
                  Register now!
                </Link>
              </p>
            ) : notifications.length === 0 ? (
              <li className="p-4 text-center text-gray-500">
                No new notifications
              </li>
            ) : (
              notifications.map((notification) => (
                <li
                  key={notification.id}
                  className={`px-4 py-2 hover:bg-gray-700 cursor-pointer flex justify-between items-center rounded-lg my-1`}
                  style={{
                    backgroundColor: shadeColor(
                      notification.alert_object?.color,
                      30,
                      notification.status,
                      notification.alert_object?.color
                    ),
                  }}
                  onClick={() => handleMarkAsViewed(notification.id)}
                >
                  <span className="text-sm font-medium text-white">
                    {notification.title}
                  </span>
                  <span className="text-xs text-white">
                    {new Date(notification.created_at).toLocaleDateString()}
                  </span>
                </li>
              ))
            )}
          </ul>
          {user && (
            <div className="px-4 py-2">
              <Link
                to="/notifications"
                className="w-full text-blue-500 hover:text-blue-700 text-sm font-semibold"
                onClick={() => setShowNotifications(false)}
              >
                View All
              </Link>
            </div>
          )}
        </motion.div>
      )}
    </div>
  );
};

export default NotificationDropdown;
