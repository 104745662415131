import { useState, useEffect } from "react";
import { CirclePicker } from "react-color";
import { useAppDispatch } from "../../app/store";
import {
  NotificationDetails,
  CreateWeatherAlertParams,
  WeatherAlert,
  UpdateWeatherAlertParams,
} from "../../types/alertTypes";
import NotificationDetailsEditor, {
  defaultNotificationDetails,
} from "./components/NotificationDetails";
import {
  addWeatherAlert,
  patchExistingWeatherAlert,
} from "../../features/alerts/alertsSlice";
import Tooltip from "./components/FormElements";
import { FaInfoCircle } from "react-icons/fa";
import { convertUTCTimeToLocal } from "../../utils/timeUtils";
import AnimatedModalContainer from "./components/AnimatedModalContainer";
import ModalTitle from "./components/ModalTitle";

// const weatherConditions = [
//   { value: 'Sunny', label: 'Sunny', icon: <RiSunLine /> },
//   { value: 'Rainy', label: 'Rainy', icon: <RiRainyLine /> },
//   { value: 'Snowy', label: 'Snowy', icon: <RiSnowyLine /> },
// ];

const sources = [
  { value: "OWM", label: "OpenWeatherMap" },
  // { value: "AW", label: "AccuWeather" },
];

// Convert a RGB color array to a hex string
function rgbToHex(rgb: any) {
  return "#" + rgb.map((x: any) => x.toString(16).padStart(2, "0")).join("");
}

const WeatherModal = ({
  isOpen,
  onClose,
  alert,
}: {
  isOpen: any;
  onClose: any;
  alert?: WeatherAlert | null;
}) => {
  const dispatch = useAppDispatch();
  const [location, setLocation] = useState("");
  const [source, setSource] = useState("OWM");
  // const [selectedConditions, setSelectedConditions] = useState<string[]>([]);
  // const [minTemperature, setMinTemperature] = useState<number | null>(null);
  // const [maxTemperature, setMaxTemperature] = useState<number | null>(null);
  const [liveFeed, setLiveFeed] = useState(false);
  const [sendingTime, setSendingTime] = useState("");
  const [notificationDetails, setNotificationDetails] =
    useState<NotificationDetails>(defaultNotificationDetails);
  const [color, setColor] = useState("#FFFFFF");

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  // Converts a local time string to a UTC time string
  function convertLocalTimeToUTC(localTimeStr: string) {
    if (!localTimeStr) return "";

    const now = new Date();
    const today = now.toISOString().split("T")[0]; // Get current date as YYYY-MM-DD
    const fullLocalTimeStr = `${today}T${localTimeStr}`; // Create a full local datetime string

    const localDate = new Date(fullLocalTimeStr);
    const utcHours = localDate.getUTCHours();
    const utcMinutes = localDate.getUTCMinutes();

    const utcHoursPadded = utcHours.toString().padStart(2, "0");
    const utcMinutesPadded = utcMinutes.toString().padStart(2, "0");

    return `${utcHoursPadded}:${utcMinutesPadded}`;
  }

  // Set defaults when editing
  useEffect(() => {
    if (alert) {
      console.error(alert);
      // Populate the form fields with the existing listing alert data
      setSource(alert.source);
      setLocation(alert.location);
      setLiveFeed(alert.live_feed);
      console.warn(alert.sending_time);
      if ("sending_time" in alert && alert.sending_time)
        setSendingTime(convertUTCTimeToLocal(alert.sending_time));
      setNotificationDetails(alert.notification_details);
      setColor(alert.color);
    } else {
      // Clear all fields
      setSource("OWM");
      setLocation("");
      setLiveFeed(false);
      setSendingTime("");
      setNotificationDetails(defaultNotificationDetails);
      setColor("");
    }
  }, [alert]);

  const handleCreateAlert = () => {
    const alertData: CreateWeatherAlertParams = {
      source: source as "OWM" | "AW",
      location: location,
      notification_details: notificationDetails,
      live_feed: liveFeed,
      sending_time: liveFeed ? null : convertLocalTimeToUTC(sendingTime),
      active: true,
      color: color,
    };

    if (alert)
      dispatch(
        patchExistingWeatherAlert({
          id: alert.id,
          payload: alertData as UpdateWeatherAlertParams,
        })
      );
    else dispatch(addWeatherAlert(alertData as CreateWeatherAlertParams));
    onClose();
  };

  const colors = [
    "#C65C5C", // Toned Down Indian Red
    "#D1A355", // Toned Down GoldenRod
    "#7FBF7F", // Toned Down Dark Sea Green
    "#1A9B97", // Toned Down Light Sea Green
    "#5A8E8E", // Toned Down Cadet Blue
    "#1A76D1", // Toned Down Dodger Blue
    "#DB7093", // Toned Down Hot Pink
    "#D93C00", // Toned Down OrangeRed
    "#8465B9", // Toned Down Medium Purple
    "#2E9E71", // Toned Down Medium Sea Green
    "#D4AF37", // Toned Down Gold
    "#A75373", // Toned Down Medium Violet Red
    "#3E7CA6", // Toned Down Steel Blue
    "#B86123", // Toned Down Chocolate
    "#763FB2", // Toned Down Blue Violet
    "#355CA8", // Toned Down Royal Blue
    "#A11A1A", // Toned Down Firebrick
    "#8B1D1D", // Toned Down Brown
  ];

  if (!isOpen) return null;

  return (
    <AnimatedModalContainer>
      <ModalTitle alert={alert} title="Weather Alert" />
      <form className="space-y-4">
        {/* <label className="block">
              <span className="text-gray-400">Sources</span>
              <select
                value={source}
                onChange={(e) => setSource(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
              >
                <option value="">Choose a Source</option>
                {sources.map((s) => (
                  <option key={s.value} value={s.value}>
                    {s.label}
                  </option>
                ))}
              </select>
            </label> */}
        <label className="block">
          <span className="text-gray-400">Location</span>
          <input
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </label>
        {/* <div>
              <span className="text-gray-400">Weather Conditions</span>
              <div className="flex flex-wrap mt-2">
                {weatherConditions.map(condition => (
                  <button
                    key={condition.value}
                    type="button"
                    className={`m-1 px-2 py-1 rounded-md shadow-sm ${
                      selectedConditions.includes(condition.value) ? 'bg-blue-500' : 'bg-gray-700'
                    }`}
                  >
                    {condition.icon}
                    <span className="ml-1">{condition.label}</span>
                  </button>
                ))}
              </div>
            </div> */}
        <div className="flex items-center py-2">
          <span
            className={`mr-3 ${liveFeed ? "text-gray-600" : "text-gray-200"}`}
          >
            Send once a day
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <div className="relative">
              <input
                type="checkbox"
                id="anotherNotification"
                className="sr-only"
                checked={liveFeed}
                onChange={(e) => {
                  setLiveFeed(e.target.checked);
                  if (e.target.checked) setSendingTime("07:00");
                }}
              />
              <div
                className={`w-14 h-8 rounded-full flex items-center justify-center transition-transform duration-300 ease-in-out ${
                  liveFeed
                    ? "bg-gradient-to-r from-blue-600 via-pink-500 to-purple-400"
                    : "bg-gradient-to-r from-purple-400 via-pink-500 to-blue-600"
                }`}
              >
                <div
                  className={`bg-white w-6 h-6 mr-5 rounded-full transition-transform duration-300 ease-in-out ${
                    liveFeed ? "translate-x-6" : "translate-x-0"
                  }`}
                ></div>
              </div>
            </div>
            <span
              className={`ml-3 ${liveFeed ? "text-gray-200" : "text-gray-600"}`}
            >
              Notify me when forcast changes
            </span>
          </label>
        </div>
        {!liveFeed && (
          <label className="block">
            <span className="text-gray-400">Set Summary Time</span>
            <input
              type="time"
              value={sendingTime}
              onChange={(e) => setSendingTime(e.target.value)}
              className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </label>
        )}
        <label className="block">
          <div className="relative flex items-center gap-2">
            <span className="text-gray-400 mb-1">Alert Color</span>
            <Tooltip
              message={`Choose a fitting color to easier recognise your listing alert`}
            >
              <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
            </Tooltip>
          </div>
          <CirclePicker
            color={color}
            onChangeComplete={(color: any) => setColor(color.hex)}
            colors={colors}
          />
        </label>
        <label className="block">
          <div className="relative flex items-center gap-2">
            <span className="text-gray-400">Notification Details</span>{" "}
            <Tooltip
              message={`Channels where you want to recieve notifications.`}
            >
              <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
            </Tooltip>
          </div>
          <p></p>
          <a href="/settings" className="text-gray-500 text-sm underline">
            (you can set default values in settings)
          </a>
          <NotificationDetailsEditor
            notificationDetails={notificationDetails}
            setNotificationDetails={setNotificationDetails}
          />
        </label>
        <div className="flex items-center justify-between mt-4">
          <button
            type="button"
            onClick={onClose}
            className="inline-flex items-center px-4 py-2 bg-gray-700 border border-transparent rounded-md font-semibold text-xs text-gray-300 uppercase tracking-widest hover:bg-gray-600 active:bg-gray-800 focus:outline-none focus:border-gray-400 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleCreateAlert}
            className="inline-flex items-center px-4 py-2 bg-blue-500 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-blue-600 active:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring ring-blue-300 disabled:opacity-25 transition ease-in-out duration-150"
          >
            {alert ? "Update Alert" : "Create Alert"}
          </button>
        </div>
      </form>
    </AnimatedModalContainer>
  );
};

export default WeatherModal;
