import React from "react";
import {
  RiInstagramFill,
  RiTwitterXLine,
  RiMailSendFill,
} from "react-icons/ri";

const Footer: React.FC = () => {
  const iconStyle = {
    fontSize: "2.5em",
    color: "white",
  };

  return (
    <footer className="bg-[#0c021f] py-6">
      <div className="container mx-auto flex flex-col items-center text-center space-y-4">
        {/* Social Icons */}
        <div className="flex justify-center space-x-8">
          <a
            href="https://www.instagram.com/terrasignalflow/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80 transition-opacity duration-300"
          >
            <RiInstagramFill style={iconStyle} />
          </a>
          <a
            href="https://twitter.com/SignalFlowTerra"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80 transition-opacity duration-300"
          >
            <RiTwitterXLine style={iconStyle} />
          </a>
          <a
            href="mailto:terrasignalflow.protonmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:opacity-80 transition-opacity duration-300"
          >
            <RiMailSendFill style={iconStyle} />
          </a>
        </div>

        {/* Logo */}
        <div className="my-4">
          <img
            src="/favicon.ico"
            alt="Signal Flow Logo"
            className="h-10 w-10"
          />
        </div>

        {/* Copyright and Version */}
        <p className="text-white text-sm">
          © 2024 Signal Flow. All rights reserved.
        </p>
        <p className="text-purple-500 text-sm">v0.0.14beta 12.07.2024</p>
      </div>
    </footer>
  );
};

export default Footer;
