import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  RiNewspaperLine,
  RiPriceTag3Line,
  RiListUnordered,
  RiInformationLine,
  RiCloudFill,
  RiFootballFill,
  RiTwitterXFill,
  RiCalendarEventFill,
} from "react-icons/ri";
import AnimatedModalContainer from "./forms/components/AnimatedModalContainer";

type OptionType =
  | "News by Keyword"
  | "News by Source"
  | "Price Alerts"
  | "New Listings"
  | "Weather"
  | "Sport"
  | "X News"
  | "Event";

interface CategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (option: OptionType) => void;
}

const options = [
  { label: "News by Keyword", icon: <RiNewspaperLine size={30} /> },
  { label: "News by Source", icon: <RiListUnordered size={30} /> },
  { label: "Price Alerts", icon: <RiPriceTag3Line size={30} /> },
  { label: "New Listings", icon: <RiInformationLine size={30} /> },
  { label: "Weather", icon: <RiCloudFill size={30} /> },
  { label: "Sport", icon: <RiFootballFill size={30} /> },
  { label: "X News", icon: <RiTwitterXFill size={30} /> },
  { label: "Event", icon: <RiCalendarEventFill size={30} /> },
];

const CategoryModal: React.FC<CategoryModalProps> = ({
  isOpen,
  onClose,
  onSelect,
}) => {
  const handleSelect = (option: OptionType) => {
    onSelect(option);
  };

  const handleCloseModal = () => {
    onClose();
  };

  if (!isOpen) return null;

  return (
    <AnimatedModalContainer>
      <h2 className="text-3xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-red-600 text-center">
        Select Category
      </h2>
      <div className="grid grid-cols-2 gap-4">
        {options.map(({ label, icon }) => (
          <motion.div
            key={label}
            className={`cursor-pointer bg-gray-800 rounded-lg p-4 flex flex-col items-center justify-center shadow-md transition-all duration-100`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleSelect(label as OptionType)}
          >
            <div className={`text-2xl`}>{icon}</div>
            <div className="mt-2 text-center text-sm font-semibold">
              {label}
            </div>
          </motion.div>
        ))}
      </div>
      <div className="flex items-center justify-between mt-6">
        <button
          type="button"
          onClick={handleCloseModal}
          className="inline-flex items-center px-4 py-2 bg-gray-700 border border-transparent rounded-md font-semibold text-xs text-gray-300 uppercase tracking-widest hover:bg-gray-600 active:bg-gray-800 focus:outline-none focus:border-gray-400 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150"
        >
          Cancel
        </button>
      </div>
    </AnimatedModalContainer>
  );
};

export default CategoryModal;
