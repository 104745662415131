import React, { useState, useEffect, useRef } from "react";
import { CirclePicker } from "react-color";
import { useAppDispatch } from "../../app/store";
import { findMostDistinctColor } from "./components/ColorGenerator";
import {
  CreateEventAlertParams,
  EventAlert,
  NotificationDetails,
  UpdateEventAlertParams,
} from "../../types/alertTypes";
import NotificationDetailsEditor, {
  defaultNotificationDetails,
} from "./components/NotificationDetails";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import {
  addEventAlert,
  patchExistingEventAlert,
} from "../../features/alerts/alertsSlice";
import KeywordSetPicker from "./components/KeywordPicker";
import Tooltip from "./components/FormElements";
import { FaInfoCircle } from "react-icons/fa";
import AnimatedModalContainer from "./components/AnimatedModalContainer";
import ModalTitle from "./components/ModalTitle";

interface EventModalProps {
  isOpen: boolean;
  onClose: () => void;
  alert?: EventAlert | null;
}

const containerStyle = {
  width: "100%",
  height: "300px",
};

// Define the type
interface Coordinates {
  lat: number;
  lng: number;
}
const center: Coordinates = {
  lat: 51.505,
  lng: -0.09,
};

// Convert a RGB color array to a hex string
function rgbToHex(rgb: any) {
  return "#" + rgb.map((x: any) => x.toString(16).padStart(2, "0")).join("");
}

const EventModal: React.FC<EventModalProps> = ({ isOpen, onClose, alert }) => {
  const dispatch = useAppDispatch();
  const [location, setLocation] = useState<string>("");
  const [coordinates, setCoordinates] = useState<Coordinates>(center);
  const [selectedKeywordSets, setSelectedKeywordSets] = useState<string[][]>(
    []
  );
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA5w8Ne4XFaY8yxDSmNOMtOR1ZN3RGsAhE",
  });
  //   const [isOnline, setIsOnline] = useState(false);
  //   const [startDate, setStartDate] = useState('');
  //   const [endDate, setEndDate] = useState('');

  const [notificationDetails, setNotificationDetails] =
    useState<NotificationDetails>(defaultNotificationDetails);
  const [color, setColor] = useState("");

  const existingColors = [
    [34, 139, 34], // ForestGreen
    [70, 130, 180], // SteelBlue
    [220, 20, 60], // Crimson
  ];

  useEffect(() => {
    if (alert) {
      console.error(alert);
      // Populate the form fields with the existing event alert data
      if ("location" in alert) {
        setLocation(alert.location);
        geocodeLocation(alert.location);
      }
      if ("keyword" in alert && alert.keyword)
        setSelectedKeywordSets(
          alert.keyword
            .split("; ")
            .map((set) =>
              set.split(", ").map((keyword) => keyword.replace(/^"|"$/g, ""))
            )
        );
      setNotificationDetails(alert.notification_details);
      setColor(alert.color);
    } else {
      //Clear all fields
      setLocation("");
      setSelectedKeywordSets([]);
      setNotificationDetails(defaultNotificationDetails);
      setColor("");
    }
  }, [alert]);

  useEffect(() => {
    const newColor = findMostDistinctColor(existingColors);
    setColor(rgbToHex(newColor));
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleSubmit = (event: any) => {
    const alertData: CreateEventAlertParams = {
      keyword: selectedKeywordSets
        .map((set) =>
          set
            .map((keyword) => (/\s/.test(keyword) ? `"${keyword}"` : keyword))
            .join(", ")
        )
        .join("; "),
      location: location,
      notification_details: notificationDetails,
      active: true,
      color: color,
    };

    if (alert)
      dispatch(
        patchExistingEventAlert({
          id: alert.id,
          payload: alertData as UpdateEventAlertParams,
        })
      );
    else dispatch(addEventAlert(alertData as CreateEventAlertParams));

    onClose(); // Close the modal after submission
  };

  const colors = [
    "#C65C5C",
    "#D1A355",
    "#7FBF7F",
    "#1A9B97",
    "#5A8E8E",
    "#1A76D1",
    "#DB7093",
    "#D93C00",
    "#8465B9",
    "#2E9E71",
    "#D4AF37",
    "#A75373",
    "#3E7CA6",
    "#B86123",
    "#763FB2",
    "#355CA8",
    "#A11A1A",
    "#8B1D1D",
  ];

  const handleSetCoordinates = (event: any) => {
    setLocation(event.target?.value);
    geocodeLocation(event.target?.value);
  };
  const geocodeLocation = async (location: string) => {
    if (!isLoaded) return;
    try {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: location }, (results: any, status: any) => {
        if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
          //console.warn(results);
          setCoordinates({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
        }
      });
    } catch (error) {
      console.log("Error geocoding location:", error);
    }
  };
  if (!isOpen) return null;

  return (
    <AnimatedModalContainer>
      <ModalTitle alert={alert} title="Event Alert" />
      <form className="space-y-4">
        <KeywordSetPicker
          selectedKeywordSets={selectedKeywordSets}
          setSelectedKeywordSets={setSelectedKeywordSets}
        />
        <div>
          <div className="relative flex items-center gap-2">
            <span className="text-gray-400">Event Location</span>
            <Tooltip
              message={`The automation system will use this location to search for events on eventbrite.\n\n First, you will recieve notifications about fist 20 upcoming events in search results, after that bot will periodically check for new events and send notifications when something appears.`}
            >
              <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
            </Tooltip>
          </div>
          <label className="block">
            <input
              type="text"
              name="Event Location"
              value={location}
              onChange={handleSetCoordinates}
              className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </label>
        </div>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={coordinates}
            zoom={13}
            // onClick={handleMapClick}
          >
            <Marker position={coordinates} />
          </GoogleMap>
        )}
        {/* <label className="block">
              <span className="text-gray-400">Start Date</span>
              <input
                type="date"
                name="startDate"
                value={startDate}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </label>
            <label className="block">
              <span className="text-gray-400">End Date</span>
              <input
                type="date"
                name="endDate"
                value={endDate}
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </label> */}
        <label className="block">
          <div className="relative flex items-center gap-2">
            <span className="text-gray-400 mb-1">Alert Color</span>
            <Tooltip
              message={`Choose a fitting color to easier recognise your event alert`}
            >
              <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
            </Tooltip>
          </div>
          <CirclePicker
            color={color}
            onChangeComplete={(color: any) => setColor(color.hex)}
            colors={colors}
          />
        </label>
        <label className="block">
          <div className="relative flex items-center gap-2">
            <span className="text-gray-400">Notification Details</span>{" "}
            <Tooltip
              message={`Channels where you want to recieve notifications.`}
            >
              <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
            </Tooltip>
          </div>
          <p></p>
          <a href="/settings" className="text-gray-500 text-sm underline">
            (you can set default values in settings)
          </a>
          <NotificationDetailsEditor
            notificationDetails={notificationDetails}
            setNotificationDetails={setNotificationDetails}
          />
        </label>
        <div className="flex items-center justify-between mt-4">
          <button
            type="button"
            onClick={onClose}
            className="inline-flex items-center px-4 py-2 bg-gray-700 border border-transparent rounded-md font-semibold text-xs text-gray-300 uppercase tracking-widest hover:bg-gray-600 active:bg-gray-800 focus:outline-none focus:border-gray-400 focus:ring ring-gray-300 disabled:opacity-25 transition ease-in-out duration-150"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className="inline-flex items-center px-4 py-2 bg-blue-500 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-blue-600 active:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring ring-blue-300 disabled:opacity-25 transition ease-in-out duration-150"
          >
            {alert ? "Update Alert" : "Create Alert"}
          </button>
        </div>
      </form>
    </AnimatedModalContainer>
  );
};

export default EventModal;
