import React from 'react';
import { motion } from 'framer-motion';

interface Props {
    children: React.ReactNode;
  }

const AnimatedModalContainer: React.FC<Props> = ({ children }) => {
  return (
    <div className="bg-black bg-opacity-60 fixed inset-0 z-50">
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: "spring", stiffness: 300 }}
        className="fixed inset-0 flex justify-center items-center px-4"
      >
        <div className="bg-gray-900 text-white rounded-lg p-6 w-full max-w-lg shadow-[0_0_100px_rgba(255,255,255,0.07)] overflow-auto max-h-screen">
          {children}
        </div>
      </motion.div>
    </div>
  );
};

export default AnimatedModalContainer;