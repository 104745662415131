import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout, selectUser } from "../features/user/userSlice";
import NotificationDropdown from "./NotificationDoprown";
import { RiSettings3Line, RiLogoutBoxRLine, RiMenu3Line } from "react-icons/ri";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const Header: React.FC = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(!user ? 1 : 0);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const dropdownRefDesktop = useRef<HTMLDivElement | null>(null);
  const dropdownRefPhone = useRef<HTMLDivElement | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  const toggleMobileMenu = () => setShowMobileMenu(!showMobileMenu);

  const handleLogout = () => {
    toggleDropdown();
    dispatch(logout());
    navigate("/login");
  };

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
    if (!showNotifications && !user) {
      setUnreadNotifications(0);
    }
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        dropdownRefDesktop.current &&
        dropdownRefPhone.current &&
        !dropdownRefDesktop.current.contains(event.target) &&
        !dropdownRefPhone.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRefDesktop, dropdownRefPhone]);

  useEffect(() => {
    const updateIsMobile = () => setIsMobile(window.innerWidth < 768);
    updateIsMobile(); // Initial check
    window.addEventListener("resize", updateIsMobile);
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  const sidebarVariants = {
    hidden: {
      x: "-100%",
      opacity: 0,
      transition: {
        opacity: { duration: 0.3 },
        x: { duration: 0.3 },
      },
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        opacity: { duration: 0.3 },
        x: { duration: 0.3 },
      },
    },
  };

  return (
    <header className="fixed top-0 left-0 w-full z-50">
      <div className="flex justify-between items-center px-6 py-4 backdrop-blur-md bg-transparent">
        <Link to="/" className="flex items-center">
          <img
            src="/favicon.ico"
            alt="Signal Flow Logo"
            className="h-10 w-10"
          />
        </Link>

        <nav className="hidden md:flex items-center gap-8">
          <ul className="flex items-center gap-8">
            <li>
              <Link
                to="/"
                className={`transition duration-300 ease-in-out text-lg text-white ${
                  location.pathname === "/" ? "font-semibold" : "opacity-75"
                }`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard"
                className={`transition duration-300 ease-in-out text-lg text-white ${
                  location.pathname === "/dashboard"
                    ? "font-semibold"
                    : "opacity-75"
                }`}
              >
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="/alerts"
                className={`transition duration-300 ease-in-out text-lg text-white ${
                  location.pathname === "/alerts"
                    ? "font-semibold"
                    : "opacity-75"
                }`}
              >
                Alerts
              </Link>
            </li>
            <li>
              <Link
                to="/latestnews"
                className={`transition duration-300 ease-in-out text-lg text-white ${
                  location.pathname === "/latestnews"
                    ? "font-semibold"
                    : "opacity-75"
                }`}
              >
                Latest News
              </Link>
            </li>
            <li className="px-3 relative">
              <NotificationDropdown />
            </li>
          </ul>
          {user ? (
            <div className="relative border-solid border-white border-2 rounded-md mx-4 px-2 py-1">
              <li
                onClick={toggleDropdown}
                className="cursor-pointer flex items-center"
              >
                {user.avatar ? (
                  <img
                    src={user.avatar}
                    alt="User Avatar"
                    className="h-8 w-8 rounded-full"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 rounded-full text-white"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5.121 17.804A13.937 13.937 0 0112 15c2.5 0 4.847.655 6.879 1.804M12 12a3 3 0 100-6 3 3 0 000 6zm0 9a9 9 0 100-18 9 9 0 000 18z"
                      stroke="currentColor"
                    />
                  </svg>
                )}
                <span className="ml-2 text-white font-medium">
                  {user.username}
                </span>
              </li>
              {showDropdown && (
                <div
                  ref={dropdownRefDesktop}
                  className="absolute right-0 mt-2 w-48 bg-gray-700 text-white rounded-md shadow-xl z-50"
                >
                  <Link
                    to="/settings"
                    onClick={() => {
                      toggleDropdown();
                    }}
                    className="flex items-center px-4 py-2 text-sm hover:bg-gray-800"
                  >
                    <RiSettings3Line className="mr-2 text-white" />
                    <span className="text-white">Settings</span>
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-gray-800"
                  >
                    <RiLogoutBoxRLine className="mr-2 text-white" />
                    <span className="text-white">Logout</span>
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              <li>
                <Link
                  to="/login"
                  className={`transition duration-300 ease-in-out font-bold rounded-md text-lg px-3 text-white ${
                    location.pathname === "/login"
                      ? "font-semibold"
                      : "opacity-75"
                  }`}
                >
                  Login
                </Link>
              </li>
              <li>
                <Link
                  to="/register"
                  className={`transition duration-300 ease-in-out font-bold rounded-md text-lg px-3 text-white ${
                    location.pathname === "/register"
                      ? "font-semibold"
                      : "opacity-75"
                  }`}
                >
                  Register
                </Link>
              </li>
            </>
          )}
        </nav>

        <button className="md:hidden text-white" onClick={toggleMobileMenu}>
          <RiMenu3Line className="h-8 w-8" />
        </button>
      </div>

      {/* Mobile Menu */}
      <motion.nav
        initial="hidden"
        animate={showMobileMenu ? "visible" : "hidden"}
        variants={sidebarVariants}
        className="flex flex-col md:hidden bg-[#1a053c] z-50 absolute top-0 left-0 h-screen w-64 p-4"
      >
        <button
          className="absolute top-4 right-4 text-white"
          onClick={toggleMobileMenu}
        >
          <RiMenu3Line className="h-8 w-8" />
        </button>
        <ul className="flex flex-col items-start gap-4 mt-20">
          <li>
            <Link
              to="/"
              onClick={() => setShowMobileMenu(false)}
              className={`transition duration-300 ease-in-out text-lg text-white ${
                location.pathname === "/" ? "font-semibold" : "opacity-75"
              }`}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard"
              onClick={() => setShowMobileMenu(false)}
              className={`transition duration-300 ease-in-out text-lg text-white ${
                location.pathname === "/dashboard"
                  ? "font-semibold"
                  : "opacity-75"
              }`}
            >
              Dashboard
            </Link>
          </li>
          <li>
            <Link
              to="/alerts"
              onClick={() => setShowMobileMenu(false)}
              className={`transition duration-300 ease-in-out text-lg text-white ${
                location.pathname === "/alerts" ? "font-semibold" : "opacity-75"
              }`}
            >
              Alerts
            </Link>
          </li>
          <li>
            <Link
              to="/latestnews"
              onClick={() => setShowMobileMenu(false)}
              className={`transition duration-300 ease-in-out text-lg text-white ${
                location.pathname === "/latestnews"
                  ? "font-semibold"
                  : "opacity-75"
              }`}
            >
              Latest News
            </Link>
          </li>
          <li className=" relative">
            <NotificationDropdown />
          </li>
          {user ? (
            <div className="relative border-solid border-white border-2 mb-4 rounded-md mx-4 px-2 py-1">
              <li
                onClick={() => {
                  toggleDropdown();
                }}
                className="cursor-pointer flex items-center "
              >
                <span className="ml-2 text-white font-medium">
                  {user.username}
                </span>
              </li>
              {showDropdown && (
                <div
                  ref={dropdownRefPhone}
                  className="absolute right-0 mt-2 w-48 bg-gray-700 text-white rounded-md shadow-xl z-50"
                >
                  <Link
                    to="/settings"
                    onClick={() => {
                      toggleDropdown();
                      setShowMobileMenu(false);
                    }}
                    className="flex items-center px-4 py-2 text-sm hover:bg-gray-800"
                  >
                    <RiSettings3Line className="mr-2 text-white" />
                    <span className="text-white">Settings</span>
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-gray-800"
                  >
                    <RiLogoutBoxRLine className="mr-2 text-white" />
                    <span className="text-white">Logout</span>
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              <li>
                <Link
                  to="/login"
                  onClick={() => setShowMobileMenu(false)}
                  className={`transition duration-300 ease-in-out font-bold rounded-md text-lg px-3 py-2 text-white ${
                    location.pathname === "/login"
                      ? "font-semibold"
                      : "opacity-75"
                  }`}
                >
                  Login
                </Link>
              </li>
              <li>
                <Link
                  to="/register"
                  onClick={() => setShowMobileMenu(false)}
                  className={`transition duration-300 ease-in-out font-bold rounded-md text-lg px-3 py-2 text-white ${
                    location.pathname === "/register"
                      ? "font-semibold"
                      : "opacity-75"
                  }`}
                >
                  Register
                </Link>
              </li>
            </>
          )}
        </ul>
      </motion.nav>
    </header>
  );
};

export default Header;
