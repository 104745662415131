import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { RiArrowRightSLine, RiLoader4Line, RiDatabase2Line, RiNewspaperLine, RiRefreshLine } from 'react-icons/ri';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {fetchNewsAndListings} from '../api/scraperDataService';
import { News } from '../types/scraperDataTypes';



// NewsPage Component
const NewsPage: React.FC = () => {
  const [selectedSource, setSelectedSource] = useState<string>('NYT');
  const [articles, setArticles] = useState<News[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  type Sources = {
    [key: string]: string;
  };
  
  const sources: Sources = {
    "NYT": "New York Times",
    "WP": "The Washington Post",
    "WSJ": "The Wall Street Journal",
    "BBC": "BBC News",
    "CNN": "Cable News Network",
    "NPR": "National Public Radio",
    "GUA": "The Guardian",
    "AP": "Associated Press",
    "FOX": "Fox News",
   };
  


  // Fetch News Articles
  const fetchNews = async (source: string) => {
    setLoading(true);
    setError('');
    try {
      const response = await fetchNewsAndListings([source], 30);
      setArticles(response.news);
    } catch (error) {
      console.error('Error fetching news:', error);
      setError('Failed to fetch news.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews(selectedSource); 
  }, [selectedSource]);

  const sliderSettings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } }
    ],
    dots: true,
    appendDots: (dots: React.ReactNode) => (
      <div style={{ backgroundColor: "transparent" }}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i: number) => (
      <div className="custom-dot"></div>
    )
  };
  return (
    <div className="bg-gray-900 text-white min-h-screen">
            <style>{`
        .custom-dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: inline-block;
          background: linear-gradient(to right, #a855f7, #ec4899, #f87171);
        }
        .slick-dots li.slick-active div {
          background: white;
        }
      `}</style>
      <main className="max-w-7xl mx-auto p-6">
        <motion.div
          className="text-center mb-12"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-red-500">
            Latest News
          </h2>
          <p className="text-lg mt-4 text-gray-300">Explore the latest news from your favorite sources.</p>
        </motion.div>

        {/* Source Selector */}
        <div className="flex gap-2 mb-6 overflow-x-auto">
          {Object.values(sources).map((source, index) => (
            <motion.button
              key={source}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className={`px-4 py-2 rounded-lg cursor-pointer ${selectedSource === source ? 'bg-blue-500' : 'bg-gray-800 hover:bg-gray-700'} transition-colors duration:300`}
              onClick={() => setSelectedSource(source)}
            >
              <motion.div
                initial={{ x: -10, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: index * 0.1 }}
              >
                {source}
              </motion.div>
            </motion.button>
          ))}
        </div>

        {/* News Articles Slider */}
        {error && (
          <div className="text-center my-4 ">
            <p className="text-red-400">{error}</p>
            <button
              className="mt-2 px-4 py-2 rounded bg-blue-600 hover:bg-blue-700 transition-colors"
              onClick={() => fetchNews(selectedSource)}
            >
              Retry <RiRefreshLine className="inline ml-1" />
            </button>
          </div>
        )}

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <RiLoader4Line className="animate-spin text-4xl text-gray-600" />
          </div>
        ) : articles.length === 0 ? (
          <p className="text-center text-gray-400">No articles found for this source.</p>
        ) : (
<Slider {...sliderSettings}>
  {articles.map((article, index) => (
    <motion.div
      key={article.link}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className="p-4 bg-gray-800 rounded-lg shadow-lg m-2"
      style={{ width: '300px', height: '400px' }} // Fixed size for the tile
    >
      {/* <motion.img
        src={article.urlToImage || "/default-news.jpg"}
        alt={article.title}
        className="w-full h-48 rounded-md mb-4 object-cover" // Fixed height for the image
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
      /> */}
      <div className="h-72 overflow-y-auto"> {/* Scrollable content area */}
        <h4 className="text-lg font-semibold mb-2">{article.title}</h4>
        <p className="text-gray-400 text-sm mb-4">
          {article.content || "No description available."}
        </p>
        <a
          href={article.link}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center px-4 py-2 bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
        >
          Read More <RiArrowRightSLine className="ml-2" />
        </a>
      </div>
    </motion.div>
  ))}
</Slider>

        )}

        {/* Animated Section for News Insights */}
        <motion.div
          className="mt-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          <h3 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 mb-6">
            News Insights
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {articles.slice(0, 6).map((article, index) => (
              <motion.div
                key={index}
                className="p-6 bg-gray-800 rounded-lg shadow-lg hover:bg-gray-700 transition ease-in-out duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{ height: '250px' }}
              >
                <div className="h-full overflow-y-auto">
                  <RiNewspaperLine className="text-3xl text-indigo-500 mx-auto mb-4" />
                  <h4 className="text-xl font-semibold mb-2">{article.title}</h4>
                  <p className="text-gray-400 text-sm">
                    {article.content.length > 100
                      ? article.content.substring(0, 100) + "..."
                      : article.content}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Additional News Section */}
        <div className="mt-12">
          <h3 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 via-red-500 to-pink-600 mb-6">
            More News
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {articles.slice(6, 12).map((article, index) => (
              <motion.div
                key={index}
                className="p-6 bg-gray-800 rounded-lg shadow-lg hover:bg-gray-700 transition ease-in-out duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{ height: '250px' }}
              >
                <div className="h-full overflow-y-auto">
                  <div className="flex items-center space-x-4 mb-4">
                    <RiDatabase2Line className="text-3xl text-green-500" />
                    <h4 className="text-lg font-semibold">{article.title}</h4>
                  </div>
                  <p className="text-gray-400 text-sm">
                    {article.content.length > 120
                      ? article.content.substring(0, 120) + "..."
                      : article.content}
                  </p>
                  <a
                    href={article.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-3 inline-flex items-center px-4 py-2 bg-blue-500 rounded-md hover:bg-blue-600 transition-colors"
                  >
                    Read Full Article <RiArrowRightSLine className="ml-2" />
                  </a>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default NewsPage;
