import { useState, useEffect } from "react";
import axios from "axios";
import { CirclePicker } from "react-color";
import {
  addCryptoPriceChangeAlert,
  patchExistingCryptoPriceChangeAlert,
  selectLoading,
} from "../../features/alerts/alertsSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  CreateCryptoPriceChangeAlertParams,
  CryptoPriceChangeAlert,
  NotificationDetails,
  UpdateCryptoPriceChangeAlertParams,
} from "../../types/alertTypes";
import { findMostDistinctColor } from "./components/ColorGenerator";
import NotificationDetailsEditor, {
  defaultNotificationDetails,
} from "./components/NotificationDetails";
import Tooltip from "./components/FormElements";
import { FaInfoCircle } from "react-icons/fa";
import AnimatedModalContainer from "./components/AnimatedModalContainer";
import ModalTitle from "./components/ModalTitle";

// Helper function to calculate target price from percentage change
const calculateTargetPrice = (
  currentPrice: any,
  percentageChange: any,
  isAbove: any
) => {
  return isAbove
    ? currentPrice * (1 + percentageChange / 100)
    : currentPrice * (1 - percentageChange / 100);
};

// Convert a RGB color array to a hex string
function rgbToHex(rgb: any) {
  return "#" + rgb.map((x: any) => x.toString(16).padStart(2, "0")).join("");
}

const ByKeywordModal = ({
  isOpen,
  onClose,
  alert,
}: {
  isOpen: any;
  onClose: any;
  alert?: CryptoPriceChangeAlert | null;
}) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [pairs, setPairs] = useState([]);
  const [isPairsLoading, setIsPairsLoading] = useState<boolean>(true);
  const [isPriceLoading, setIsPriceLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    pair: "",
    source: "",
    threshold_percentage: "",
    target_price: "",
    above: true,
  });
  const [notificationDetails, setNotificationDetails] =
    useState<NotificationDetails>(defaultNotificationDetails);
  const [color, setColor] = useState("");

  // Prepare initial existing colors (these could be fetched or calculated)
  const existingColors = [
    [34, 139, 34], // ForestGreen
    [70, 130, 180], // SteelBlue
    [220, 20, 60], // Crimson
  ];

  // Compute a distinct color on initial render
  useEffect(() => {
    const newColor = findMostDistinctColor(existingColors);
    setColor(rgbToHex(newColor));
  }, []);

  // Fetch all trading pairs from Binance
  useEffect(() => {
    const fetchPairs = async () => {
      setIsPairsLoading(true);
      try {
        const { data } = await axios.get(
          "https://api.binance.com/api/v3/exchangeInfo"
        );
        setPairs(
          data.symbols
            .filter((s: any) => s.status === "TRADING")
            .map((s: any) => s.symbol)
        );
      } catch (error) {
        console.error("Error fetching pairs from Binance:", error);
      } finally {
        setIsPairsLoading(false);
      }
    };
    fetchPairs();
  }, []);

  // Fetch current price for the selected pair
  useEffect(() => {
    if (formData.pair) {
      const fetchCurrentPrice = async () => {
        setIsPriceLoading(true);
        try {
          const { data } = await axios.get(
            `https://api.binance.com/api/v3/ticker/price?symbol=${formData.pair}`
          );
          setCurrentPrice(parseFloat(data.price));
          // Reset threshold and target price when the pair changes
          setFormData((f) => ({
            ...f,
            threshold_percentage: "",
            target_price: "",
          }));
        } catch (error) {
          console.error("Error fetching current price:", error);
        } finally {
          setIsPriceLoading(false);
        }
      };
      fetchCurrentPrice();
    }
  }, [formData.pair]);

  // Set defaults when editing
  useEffect(() => {
    if (alert) {
      console.error(alert);
      // Populate the form fields with the existing listing alert data
      // setSource(alert.source)
      setNotificationDetails(alert.notification_details);
      setColor(alert.color);
    } else {
      // Clear all fields
      // setSource(''); // Uncomment if you use this field and need to reset it
      setNotificationDetails(defaultNotificationDetails);
      setColor("");
    }
  }, [alert]);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    let target_price_up = undefined;
    let target_price_down = undefined;

    if (formData.above) {
      target_price_up = parseFloat(formData.target_price);
    } else {
      target_price_down = parseFloat(formData.target_price);
    }

    const alertData: CreateCryptoPriceChangeAlertParams = {
      active: true,
      pair: formData.pair,
      ...(target_price_up !== undefined && { target_price_up }),
      ...(target_price_down !== undefined && { target_price_down }),
      source: "BN", // Set to Binance, can be dynamic if needed
      notification_details: notificationDetails,
      color: color,
    };

    if (alert)
      dispatch(
        patchExistingCryptoPriceChangeAlert({
          id: alert.id,
          payload: alertData as UpdateCryptoPriceChangeAlertParams,
        })
      );
    else
      dispatch(
        addCryptoPriceChangeAlert(
          alertData as CreateCryptoPriceChangeAlertParams
        )
      );
    onClose(); // Close the modal after submission
  };

  const handleInputChange = (event: any) => {
    const { name, value, type } = event.target;
    let newFormData = {
      ...formData,
      [name]: type === "checkbox" ? event.target.checked : value,
    };

    // Dynamically update target price or threshold percentage based on user input
    if (name === "threshold_percentage" && currentPrice) {
      const percentage = parseFloat(value);
      if (!isNaN(percentage)) {
        const targetPrice = calculateTargetPrice(
          currentPrice,
          percentage,
          newFormData.above
        );
        newFormData = {
          ...newFormData,
          target_price: targetPrice as unknown as string,
        };
      }
    } else if (name === "target_price" && currentPrice) {
      const targetPrice = parseFloat(value);
      if (!isNaN(targetPrice) && currentPrice > 0) {
        const percentage = (Math.abs(targetPrice / currentPrice) - 1) * 100;
        newFormData = {
          ...newFormData,
          threshold_percentage: percentage as unknown as string,
        };
      }
    } else if (
      name === "above" &&
      currentPrice &&
      formData.threshold_percentage
    ) {
      const percentage = parseFloat(formData.threshold_percentage);
      if (!isNaN(percentage)) {
        const targetPrice = calculateTargetPrice(
          currentPrice,
          percentage,
          value === "true"
        );
        newFormData = {
          ...newFormData,
          target_price: targetPrice as unknown as string,
        };
      }
    }

    setFormData(newFormData);
  };

  const colors = [
    "#C65C5C", // Toned Down Indian Red
    "#D1A355", // Toned Down GoldenRod
    "#7FBF7F", // Toned Down Dark Sea Green
    "#1A9B97", // Toned Down Light Sea Green
    "#5A8E8E", // Toned Down Cadet Blue
    "#1A76D1", // Toned Down Dodger Blue
    "#DB7093", // Toned Down Hot Pink
    "#D93C00", // Toned Down OrangeRed
    "#8465B9", // Toned Down Medium Purple
    "#2E9E71", // Toned Down Medium Sea Green
    "#D4AF37", // Toned Down Gold
    "#A75373", // Toned Down Medium Violet Red
    "#3E7CA6", // Toned Down Steel Blue
    "#B86123", // Toned Down Chocolate
    "#763FB2", // Toned Down Blue Violet
    "#355CA8", // Toned Down Royal Blue
    "#A11A1A", // Toned Down Firebrick
    "#8B1D1D", // Toned Down Brown
  ];

  if (!isOpen) return null;

  return (
    <AnimatedModalContainer>
      <ModalTitle alert={alert} title="Price Alert" />
      <form onSubmit={handleSubmit} className="space-y-4">
        <label className="block">
          <span className="text-gray-300">Exchange</span>
          <select
            name="source"
            value={formData.source}
            onChange={handleInputChange}
            className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
          >
            <option value="BN">Binance</option>
          </select>
        </label>

        <label className="block">
          <span className="text-gray-300">Crypto Pair</span>
          {isPairsLoading ? (
            <div className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm text-sm">
              Loading pairs...
            </div>
          ) : (
            <select
              name="pair"
              value={formData.pair}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
            >
              <option value="">Select a Pair</option>
              {pairs.map((pair) => (
                <option key={pair} value={pair}>
                  {pair}
                </option>
              ))}
            </select>
          )}
        </label>

        {isPriceLoading ? (
          <div className="block">
            <span className="text-gray-300">Current Price: </span>
            <span className="font-semibold">Loading...</span>
          </div>
        ) : (
          formData.pair &&
          currentPrice > 0 && (
            <div className="block">
              <span className="text-gray-300">Current Price: </span>
              <span className="font-semibold">{currentPrice}</span>
            </div>
          )
        )}

        <label className="block">
          <span className="text-gray-300">Threshold Percentage (%)</span>
          <input
            type="text"
            name="threshold_percentage"
            value={formData.threshold_percentage}
            onChange={handleInputChange}
            className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
            placeholder="e.g., 5%"
          />
        </label>

        <label className="block">
          <span className="text-gray-300">Price Direction</span>
          <div className="mt-1 block">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="above"
                value="true"
                checked={formData.above}
                onChange={handleInputChange}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">Above</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                name="above"
                value="false"
                checked={!formData.above}
                onChange={handleInputChange}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">Below</span>
            </label>
          </div>
        </label>

        <label className="block">
          <span className="text-gray-300">Target Price</span>
          <input
            type="text"
            name="target_price"
            value={formData.target_price}
            onChange={handleInputChange}
            className="mt-1 block w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
            placeholder="Target price for the alert"
          />
        </label>

        <label className="block">
          <div className="relative flex items-center gap-2">
            <span className="text-gray-400 mb-1">Alert Color</span>
            <Tooltip
              message={`Choose a fitting color to easier recognise your listing alert`}
            >
              <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
            </Tooltip>
          </div>
          <CirclePicker
            color={color}
            onChangeComplete={(color: any) => setColor(color.hex)}
            colors={colors}
          />
        </label>

        <label className="block">
          <div className="relative flex items-center gap-2">
            <span className="text-gray-400">Notification Details</span>{" "}
            <Tooltip
              message={`Channels where you want to recieve notifications.`}
            >
              <FaInfoCircle className="text-blue-500 cursor-pointer text-lg mb-1" />
            </Tooltip>
          </div>
          <p></p>
          <a href="/settings" className="text-gray-500 text-sm underline">
            (you can set default values in settings)
          </a>
          <NotificationDetailsEditor
            notificationDetails={notificationDetails}
            setNotificationDetails={setNotificationDetails}
          />
        </label>

        <div className="flex items-center justify-between mt-4">
          <button
            type="button"
            onClick={onClose}
            className="inline-flex items-center px-4 py-2 bg-gray-700 border border-transparent rounded-md font-semibold text-xs text-gray-300 uppercase tracking-widest hover:bg-gray-600 active:bg-gray-800 focus:outline-none focus:border-gray-800 focus:ring ring-gray-700 disabled:opacity-25 transition ease-in-out duration-150"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 bg-purple-500 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-purple-600 active:bg-purple-700 focus:outline-none focus:border-purple-700 focus:ring ring-purple-300 disabled:opacity-25 transition ease-in-out duration-150"
          >
            {alert ? "Update Alert" : "Create Alert"}
          </button>
        </div>
      </form>
    </AnimatedModalContainer>
  );
};

export default ByKeywordModal;
