import React, { createContext, useEffect, useState, ReactNode, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification as NotificationType } from '../types/notificationTypes';
import { addNotification } from '../features/notifications/notificationSlice';
import { RootState, store } from '../app/store';

const backendWsUrl = process.env.REACT_APP_BACKEND_WS_URL || 'ws://localhost:8000';

// Directly access the token from the Redux state
function getJwtToken() {
    return store.getState().user.token;
}

// Define the shape of the notification context
interface NotificationContextType {
    notifications: NotificationType[];
}

// Create a default notification context
const defaultNotificationContext: NotificationContextType = {
    notifications: [],
};

// Create the notification context
export const NotificationContext = createContext<NotificationContextType>(defaultNotificationContext);

interface WebSocketProviderProps {
    children: ReactNode;
}

export const WebSocketProvider: FC<WebSocketProviderProps> = ({ children }) => {
    const dispatch = useDispatch();
    const notifications = useSelector((state: RootState) => state.notifications.notifications);

    // Function to show a browser notification
    const showBrowserNotification = (notification: NotificationType) => {
        if (document.hidden && Notification.permission === 'granted') {
            const { title, message } = notification;
            new Notification(title, {
                body: message,
                icon: 'logo192.png', // Optional: icon for the notification
            });
        }
    };

    // Function to ask for notification permission
    const askNotificationPermission = () => {
        if (!('Notification' in window)) {
            console.log('This browser does not support desktop notifications');
        } else if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                }
            });
        }
    };

    useEffect(() => {
        // Ask for permission as soon as this component is mounted
        // askNotificationPermission();
        // Notification.requestPermission()

        const ws = new WebSocket(`${backendWsUrl}/ws/notifications/?token=${getJwtToken()}`);

        ws.onopen = () => {
            console.log('WebSocket connection opened');
        };

        ws.onmessage = (event) => {
            console.log("Message received: ", event.data);
            const data = JSON.parse(event.data);
            const newNotification: NotificationType = data.notification;

            // Dispatch Redux action to add the notification
            dispatch(addNotification(newNotification));

            // Also show a browser notification if the document is hidden
            showBrowserNotification(newNotification);
        };

        ws.onclose = () => {
            console.log('WebSocket connection closed');
        };

        ws.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        return () => {
            ws.close();
        };
    }, [dispatch]);

    return (
        <NotificationContext.Provider value={{ notifications }}>
            {children}
        </NotificationContext.Provider>
    );
};