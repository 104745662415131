// Add imports as necessary
import axios from 'axios';
import { store } from '../app/store';  // Ensure you import the store correctly
import { updateToken, logout } from '../features/user/userSlice';

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';
console.log("CURRENT BACKEND URL:")
console.log(backendUrl);

// Directly access the token from the Redux state
function getJwtToken() {
    return store.getState().user.token;
}

// Directly access the refresh token from the Redux state
function getRefreshToken() {
    return store.getState().user.refresh;
}

// Directly dispatch actions to save/update the JWT token
function saveJwtToken(token) {
    store.dispatch(updateToken({ token }));
}

// Directly dispatch actions to remove the JWT token
function removeJwtToken() {
    store.dispatch(logout());
}

// Function to refresh the token
async function refreshToken() {
    try {
        // Ensure you adjust the endpoint as per your Django setup
        const response = await axios.post(`${backendUrl}/api/token/refresh/`, {
            refresh: getRefreshToken()
        });
        const { access } = response.data;
        saveJwtToken(access);
        return access;
    } catch (error) {
        console.error('Error refreshing token:', error);
        removeJwtToken();
        if (window.location.pathname !== '/login') { // Redirect to login if refresh fails
            window.location.href = '/login';
        }
        return null;
    }
}

// Function to get the CSRF token from cookies
// function getCsrfToken() {
//     const name = 'csrftoken';
//     const cookies = document.cookie.split('; ');
//     for (let cookie of cookies) {
//         if (cookie.startsWith(name + '=')) {
//             return cookie.split('=')[1];
//         }
//     }
//     return '';
// }

const axiosInstance = axios.create({
    baseURL: backendUrl,
    headers: {
        'Content-Type': 'application/json',
        // 'X-CSRFToken': getCsrfToken(),
        'accept': 'application/json',
    },
    timeout: 10000, // Example timeout
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const token = getJwtToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;
        
        // Check for a network error
        if (!error.response) {
            console.error("Network Error:", error);
            alert("Unable to connect to the server. Please check your network connection.");
            return Promise.reject(error);
        }

        // Handle 401 Unauthorized
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            console.log(`Trying to refresh token: ${getJwtToken}`)
            const newToken = await refreshToken();
            console.log(`Got new token: ${newToken}`)
            
            if (newToken) {
                console.warn(`new token it this: ${newToken}`);
                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                return axiosInstance(originalRequest);
            } else {

                console.warn(`redirecting to login...`);
                console.warn(`window pathname: ${window.location.pathname} is equal?: ${window.location.pathname !== '/login'}`)
                if (window.location.pathname !== '/login') {
                    console.log('redirection go login now...')
                    window.location.href = '/login';
                }
                console.log('returning Promise.reject(error):')
                return Promise.reject(error);
            }
        }

        // Handle other errors as before...
        if (error.response.status === 403) {
            console.error("Access Denied:", error);
            const message = error.response.data.error || "You do not have permission to perform this action.";
            alert(message);
            return Promise.reject(error);
        }

        if (error.response.status === 404) {
            console.error("Resource Not Found:", error);
            const message = error.response.data.error || "The requested resource was not found.";
            alert(message);
            return Promise.reject(error);
        }

        if (error.response.status === 400) {
            console.error("Bad Request:", error);
            const message = error.response.data.error || "The request could not be understood by the server.";
            alert(message);
            return Promise.reject(error);
        }

        if (error.response.status >= 500) {
            console.error("Server Error:", error);
            const message = error.response.data || "We are experiencing some problems on our servers. Please try again later.";
            alert(message);
            return Promise.reject(error);
        }

        if (error.response.status === 429) {
            console.error("Rate Limit Exceeded:", error);
            const message = error.response.data.error || "Too many requests. Please try again later.";
            alert(message);
            return Promise.reject(error);
        }

        if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
            console.error("Timeout Error:", error);
            alert("The request timed out, please try again.");
            return Promise.reject(error);
        }

        if (axios.isCancel(error)) {
            console.warn("Request Cancelled:", error);
            return Promise.reject(error);
        }

        if (process.env.NODE_ENV === 'development') {
            console.log("Error Response:", error.response);
        }

        alert("An unexpected error occurred. Please try again.");
        return Promise.reject(error);
    }
);

export default axiosInstance;