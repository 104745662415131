import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { WebSocketProvider } from './websocket/WebSocketClient';
import Header from './components/Header';
import Footer from './components/Footer';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import DashboardPage from './pages/DashboardPage';
import MonitorPage from './pages/MonitorPage';
import NotificationPage from './pages/NotificationPage';
import HomePage from './pages/HomePage';
import SettingsPage from './pages/SettingsPage';
import NewsPage from './pages/NewsPage';
import ActionsPage from './pages/ActionsPage';
import CookieConsent from './components/CookieConsent';

const App: React.FC = () => {
  return (
    <Router>
      <WebSocketProvider>
        <Header />
        <div className="min-h-screen font-sans">
          <Routes>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/alerts" element={<MonitorPage />} />
            <Route path="/notifications" element={<NotificationPage />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/latestnews" element={<NewsPage />} />
            <Route path="/actions" element={<ActionsPage />} />
          </Routes>
        </div>
        <CookieConsent />
      </WebSocketProvider>
      <Footer />
    </Router>
  );
}

export default App;
