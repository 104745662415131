import { NotificationDetails as NotificationDetailsType } from '../types/alertTypes';
import { UserType } from '../types/userTypes';
import axios from './axios';

// Registration function
const register = async (
    username: string,
    email: string,
    password1: string,
    password2: string,
    passwordClue?: string,
    avatar?: string,
  ) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('email', email);
    formData.append('password1', password1);
    formData.append('password2', password2);
    if (passwordClue) {
      formData.append('password_clue', passwordClue);
    }
    if (avatar) {
      formData.append('avatar', avatar);
    }
  
    try {
      const response = await axios.post('api/register/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data as {
        user: UserType;
        refresh: string;
        access: string;
        default_notification_details: NotificationDetailsType;
      };
    } catch (error: any) {
      console.error('Registration error:', error.response?.data);
      throw error;
    }
  };
  
  // Login function
  const login = async (username: string, password: string) => {
    try {
      const response = await axios.post('api/login/', {
        username,
        password,
      });
      return response.data as {
        refresh: string;
        access: string;
        user: UserType;
        default_notification_details: NotificationDetailsType;
      };
    } catch (error: any) {
      console.error('Login error:', error.response?.data);
      throw error;
    }
  };

// Function to update preferences
const updatePreferences = async (preferences: { [key: string]: any }): Promise<UserType> => {
    try {
      const response = await axios.patch('api/update-preferences/', { preferences });
      return response.data as UserType;
    } catch (error: any) {
      console.error('Update preferences error:', error.response?.data);
      throw error;
    }
  };
  
  // Function to update notification details
  const updateNotificationDetails = async (
    notificationDetails: Partial<NotificationDetailsType>
  ): Promise<NotificationDetailsType> => {
    try {
      const response = await axios.patch('api/update-notification-details/', notificationDetails);
      return response.data as NotificationDetailsType;
    } catch (error: any) {
      console.error(
        'Update notification details error:',
        error.response?.data
      );
      throw error;
    }
  };
  
  export { register, login, updatePreferences, updateNotificationDetails };